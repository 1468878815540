import React, { useEffect, useState } from 'react';

import { Box, Paper, Typography } from '@material-ui/core';
import DataTable from 'components/DataTable';
import useTable from 'hooks/useTable';
import { getFormattedDate } from 'utils/transform';
import SearchAssociadosSorteio from './SearchAssociadosSorteio';
import api from 'services/api';
import {
  PERMISSION_BAIXAR_ASSOCIADO_RESULTADOS,
  STATUS_RESULTADO,
} from 'utils/constants';
import { isUserAllowed } from 'utils/permissions';
import { useAuth } from 'contexts/auth';

function ResultadoAssociadosSorteio({
  sorteio = {},
  resultado_sorteio = {},
  atResultadosPage = false,
}) {
  const { user } = useAuth();
  const [filters, setFilters] = useState([]);
  const [sorteioGet, setSorteioGet] = useState({});
  const { data, onRefresh, ...dataTableProps } = useTable(
    !atResultadosPage &&
      `sorteios/${sorteio.id}/resultados/${resultado_sorteio.id}`,
    {
      viewColumns: false,
      print: false,
      download: isUserAllowed(user, PERMISSION_BAIXAR_ASSOCIADO_RESULTADOS),
      search: false,
      onDownload: () => {
        downloadReport();
        return false;
      },
    },
  );

  const downloadReport = async () => {
    const params = {
      ...filters,
      xlsx: true,
    };
    const url = `sorteios/${sorteioGet.id}/resultados`;
    const { data: dataXls } = await api.get(url, {
      params,
      responseType: 'blob',
      headers: {
        'Content-Type':
          'application/application/vnd.openxmlformats-officedocument.spreadsheetml.sheet; charset=UTF-8',
      },
    });

    const file = new Blob([dataXls], {
      type:
        'application/application/vnd.openxmlformats-officedocument.spreadsheetml.sheet; charset=UTF-8',
    });
    const fileURL = URL.createObjectURL(file);
    const link = document.createElement('a');
    link.href = fileURL;
    link.download = `relatorio_resultados_${+new Date()}.xlsx`;
    link.click();
    return false;
  };

  const downloadPdf = async (value = undefined) => {
    const params = {
      ...filters,
      suplentes: value,
      pdf: true,
    };

    const url = `sorteios/${sorteioGet.id}/resultados`;

    const { data } = await api.get(url, {
      params,
      responseType: 'blob',
    });

    const file = new Blob([data], {
      type: 'application/pdf',
    });

    const fileURL = URL.createObjectURL(file);
    const link = document.createElement('a');
    link.href = fileURL;
    link.download = `relatorio_resultados_${+new Date()}.pdf`;
    link.click();

    return false;
  };

  const columnsAssociadoSorteio = [
    {
      name: 'numero_sorte',
      label: '#',
      options: {
        customBodyRender(date) {
          return date;
        },
      },
    },
    {
      name: 'associado',
      label: 'Associado',
      options: {
        customBodyRender(associado) {
          return `${associado?.nome} - ${associado?.unidade}`;
        },
      },
    },

    {
      name: 'associado',
      label: 'Posto',
      options: {
        customBodyRender(associadoPostoGraduacao) {
          return associadoPostoGraduacao?.postoGraduacao?.nome;
        },
      },
    },
    {
      name: 'associado',
      label: 'Categoria',
      options: {
        customBodyRender(associadoCategoria) {
          return associadoCategoria?.categoria?.nome;
        },
      },
    },
    {
      name: 'sorteio',
      label: 'Campanha',
      options: {
        customBodyRender(sorteio) {
          return sorteio?.campanha?.titulo;
        },
      },
    },
    {
      name: 'sorteio',
      label: 'Sorteio',
      options: {
        customBodyRender(sorteio) {
          return sorteio?.nome;
        },
      },
    },
    {
      name: 'periodo',
      label: 'Período',
      options: {
        customBodyRender(periodo) {
          return periodo?.nome;
        },
      },
    },
    {
      name: 'subPeriodos',
      label: 'Data(s) de ocupação',
      options: {
        customBodyRender(periodo) {
          return (
            <Typography>
              {getFormattedDate(periodo?.data_inicio, 'dd/MM')} -{' '}
              {getFormattedDate(periodo?.data_fim, 'dd/MM')}
            </Typography>
          );
        },
      },
    },
    {
      name: 'unidade',
      label: 'Unidade',
      options: {
        customBodyRender(unidade) {
          return unidade?.nome;
        },
      },
    },
    {
      name: 'status_sorteio',
      label: 'Ganhou',
      options: {
        customBodyRenderLite: dataIndex => {
          const { status_sorteio } = data[dataIndex];
          const ordemSuplente = data[dataIndex]?.motivo_sorteio?.ordemSuplente;
          const ordemSuplenteText = ordemSuplente ? `#${ordemSuplente}` : '';
          return `${STATUS_RESULTADO[status_sorteio]} ${ordemSuplenteText}`;
        },
      },
    },
    {
      name: 'motivo_sorteio',
      label: 'Peso',
      options: {
        customBodyRender(motivo_sorteio) {
          return <pre>{JSON.stringify(motivo_sorteio?.peso)}</pre>;
        },
      },
    },
    {
      name: 'motivo_sorteio',
      label: 'Desempate',
      options: {
        customBodyRender(motivo_sorteio) {
          return <pre>{JSON.stringify(motivo_sorteio?.desempate)}</pre>;
        },
      },
    },
    {
      name: 'motivo_sorteio',
      label: 'Reserva',
      options: {
        customBodyRender(motivo_sorteio) {
          return motivo_sorteio?.reserva ? 'Sim' : 'Não';
        },
      },
    },
    {
      name: 'motivo_sorteio',
      label: 'Cadeirante',
      options: {
        customBodyRender(motivo_sorteio) {
          return motivo_sorteio?.solicitou_cadeirante ? 'Sim' : 'Não';
        },
      },
    },
    {
      name: 'motivo_sorteio',
      label: 'Unidade Cadeirante',
      options: {
        customBodyRender(motivo_sorteio) {
          return motivo_sorteio?.unidade_cadeirante ? 'Sim' : 'Não';
        },
      },
    },
    {
      name: 'motivo_sorteio',
      label: 'Reserva Atendida',
      options: {
        customBodyRender(motivo_sorteio) {
          return motivo_sorteio?.objReserva?.numeroReserva
            ? `Posto: ${motivo_sorteio?.objReserva?.posto_nome} # ${motivo_sorteio?.objReserva?.numeroReserva}`
            : '-';
        },
      },
    },
  ];

  const onSubmitSearchAssociado = async filter => {
    if (!filter) {
      return onRefresh({}, true, false);
    }

    const { data: sorteio } = await api.get(`sorteios/${filter.sorteio}`, {
      params: { include: 'campanha' },
    });

    setSorteioGet(sorteio);

    const {
      data: {
        data: [resultadoSorteio],
      },
    } = await api.get(`sorteios/${filter.sorteio}/resultados`);

    if (!resultadoSorteio) {
      return onRefresh({}, true, false);
    }

    const updateState = {
      categoria_id: filter.categoria,
      sorteios_periodo_id: filter.periodo_sorteio,
      posto_graduacao_id: filter.posto,
      status_sorteio: filter.status_sorteio,
      campanha_id: filter.campanha,
      associado_id: filter.associado,
      unidade_id: filter.unidade,
      sub_periodo_id: filter.sub_periodo_sorteio,
    };

    setFilters(updateState);

    onRefresh(
      updateState,
      true,
      `sorteios/${sorteio.id}/resultados/${resultadoSorteio.id}`,
    );
  };

  useEffect(() => {
    if (sorteio.id) {
      onSubmitSearchAssociado({
        sorteio: sorteio.id,
        campanha: sorteio.campanha.id,
      });
    }
  }, [sorteio]);

  return (
    <>
      <Paper>
        <Box p={1} mb={1}>
          <Typography variant="h6">Filtros</Typography>
          <SearchAssociadosSorteio
            sorteio_id={sorteio?.id}
            atResultadosPage={atResultadosPage}
            initialValues={{
              sorteio: sorteio?.id,
              campanha: sorteio?.campanha?.id,
            }}
            onSubmit={onSubmitSearchAssociado}
          />
        </Box>
      </Paper>

      {sorteio && (
        <DataTable
          columns={columnsAssociadoSorteio}
          data={data}
          title={`Associados Sorteados ${sorteio.nome || ''}`}
          downloadPdf
          onDownloadContemplados={downloadPdf}
          onDownloadSuplentes={() => downloadPdf(true)}
          {...dataTableProps}
        />
      )}
    </>
  );
}

export default ResultadoAssociadosSorteio;
