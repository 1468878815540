import React from 'react';
import { useParams, useHistory } from 'react-router-dom';
import ForgotPasswordTokenForm from 'forms/ForgotPasswordTokenForm';
import { Typography, Paper } from '@material-ui/core';
import { FormContainer } from 'styles/global';
import Centered from 'components/Centered';
import api from 'services/api';
import { toast } from 'react-toastify';
import AVMLogo from 'images/avm-logo-200.png';

const ForgotPasswordToken = () => {
  const { token } = useParams();
  const history = useHistory();

  const onSubmit = async values => {
    const body = { ...values };
    delete body.newPassword;
    await api.post('/auth/update-password', body);
    toast.success('Senha alterada com sucesso!');
    history.push('/login');
  };

  return (
    <Paper style={{ maxWidth: 440, width: '100%', margin: 'auto' }}>
      <Centered column>
        <img
          draggable={false}
          style={{ width: 200, marginBottom: 20 }}
          src={AVMLogo}
          alt="AVM Logo"
        />
        <Typography variant="h6" mb={2} align="center">
          Crie uma nova senha
        </Typography>
        <FormContainer>
          <ForgotPasswordTokenForm
            initialValues={{ token }}
            onSubmit={onSubmit}
          />
        </FormContainer>
      </Centered>
    </Paper>
  );
};

export default ForgotPasswordToken;
