import React, { useEffect, useState } from 'react';
import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import MenuIcon from '@material-ui/icons/Menu';
import AddIcon from '@material-ui/icons/Add';
import FeaturedPlayListIcon from '@material-ui/icons/FeaturedPlayList';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { useTheme } from '@material-ui/core/styles';
import LogoAVM from 'images/avm-logo-200.png';
import ListItemLink from 'components/ListItemLink';
import { useAuth } from 'contexts/auth';
import { useLocation, matchPath, Redirect, useHistory } from 'react-router-dom';
import { Avatar, Box, ListItemAvatar } from '@material-ui/core';
import useStyles from './useStyles';
import {
  CADASTRO_PROFILE,
  DEFAULT_PROFILE,
  PERMISSION_MENU_INSCREVER_ASSOCIADO,
  PERMISSION_MENU_RESULTADO_ASSOCIADO,
  REPRESENTANTE_PROFILE,
} from 'utils/constants';
import AtalhoInscricaoDialog from 'dialogs/AtalhoInscricaoDialog';
import api from 'services/api';
import ParticiparDialog from 'dialogs/ParticiparDialog';
import { toast } from 'react-toastify';
import ResultadosDialog from 'dialogs/ResultadosDialog';
import PermissionedComponent from 'components/PermissionedComponent';

function LoggedLayout(props) {
  const { profiles, user, signOut, signed } = useAuth();
  const { window, children, routes, allRoutes } = props;
  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();
  const [mobileOpen, setMobileOpen] = React.useState(false);
  let location = useLocation();
  var filteredRoutes = routes;
  const isAssociado = profiles.includes(DEFAULT_PROFILE);

  const [showInscricaoModal, setShowInscricaoModal] = useState(false);
  const [showResultadosModal, setShowResultadosModal] = useState(false);
  const [openParticipar, setOpenParticipar] = useState(false);

  const [sorteioSelected, setSorteioSelected] = useState({});
  const [associadoSelected, setAssociadoSelected] = useState({});
  const [periodosInscritos, setPeriodosInscritos] = useState([]);

  const activeRoute = allRoutes?.find(route =>
    matchPath(location.pathname, {
      path: route.path,
      strict: false,
      exact: true,
    }),
  );

  useEffect(() => {
    history.listen(() => {
      setMobileOpen(false);
    });
  }, [history]);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  async function onSubmitAtalhoInscricao(values) {
    setSorteioSelected(values.sorteio);

    const params = { cpf: values.associado_cpf, include: 'telefones' };
    const { data } = await api.get('associados', { params });

    if (!data.data[0]?.id) {
      toast.warn('Adicione um CPF de associado válido para continuar.');

      return;
    }

    setAssociadoSelected(data.data[0]);
    setShowInscricaoModal(false);
    setOpenParticipar(true);
  }

  useEffect(() => {
    if (profiles.includes(REPRESENTANTE_PROFILE)) {
      history.push('/representante');
    }
    if (profiles.includes(CADASTRO_PROFILE)) {
      // history.push('/manage-subscription');
    }
  }, []);

  if (!signed) {
    return <Redirect to="/login-por-email" />;
  }

  if (!activeRoute && !!filteredRoutes?.length) {
    return <Redirect to={filteredRoutes[0].path} />;
  }

  const drawer = (
    <Box className={classes.menu}>
      <div className={classes.toolbar}>
        <img
          draggable={false}
          style={{ height: 64 }}
          src={LogoAVM}
          alt="AVM Logo"
        />
      </div>
      <Divider />
      <List>
        <PermissionedComponent permission={PERMISSION_MENU_INSCREVER_ASSOCIADO}>
          <ListItem button onClick={() => setShowInscricaoModal(true)}>
            <ListItemIcon>
              <AddIcon />
            </ListItemIcon>
            <ListItemText data-cy="inscrever-associado">
              Inscrever Associado
            </ListItemText>
          </ListItem>
        </PermissionedComponent>
        <PermissionedComponent permission={PERMISSION_MENU_RESULTADO_ASSOCIADO}>
          <ListItem button onClick={() => setShowResultadosModal(true)}>
            <ListItemIcon>
              <FeaturedPlayListIcon />
            </ListItemIcon>
            <ListItemText>Resultados do Associado</ListItemText>
          </ListItem>
        </PermissionedComponent>
        <Box width="100%" my={1}>
          <Divider />
        </Box>

        {filteredRoutes?.map(props => (
          <ListItemLink
            selected={activeRoute?.path === props.path}
            key={props.path}
            {...props}
          />
        ))}
      </List>
      <Box mt="auto">
        <Divider />
        <ListItem className={classes.loggedUser}>
          <ListItemAvatar>
            <Avatar alt="Usuário logado" src={user?.photoUrl} />
          </ListItemAvatar>
          <ListItemText
            disableTypography
            primary={user?.nome || 'Sem nome'}
            secondary={
              <button
                className={classes.logoutButton}
                component="button"
                onClick={signOut}
              >
                Sair
              </button>
            }
          />
        </ListItem>
      </Box>
    </Box>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <>
      {!isAssociado ? (
        <div className={classes.root}>
          <CssBaseline />
          <AppBar position="fixed" className={classes.appBar}>
            <Toolbar>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={handleDrawerToggle}
                className={classes.menuButton}
              >
                <MenuIcon />
              </IconButton>
              <Typography variant="h6" noWrap>
                {activeRoute?.title}
              </Typography>
            </Toolbar>
          </AppBar>
          <nav className={classes.drawer} aria-label="mailbox folders">
            <Hidden smUp implementation="css">
              <Drawer
                container={container}
                variant="temporary"
                anchor={theme.direction === 'rtl' ? 'right' : 'left'}
                open={mobileOpen}
                onClose={handleDrawerToggle}
                classes={{
                  paper: classes.drawerPaper,
                }}
                ModalProps={{
                  keepMounted: true,
                }}
              >
                {drawer}
              </Drawer>
            </Hidden>
            <Hidden xsDown implementation="css">
              <Drawer
                classes={{
                  paper: classes.drawerPaper,
                }}
                variant="permanent"
                open
              >
                {drawer}
              </Drawer>
            </Hidden>
          </nav>
          <main className={classes.content}>{children}</main>
        </div>
      ) : (
        <main>{children}</main>
      )}

      <AtalhoInscricaoDialog
        open={showInscricaoModal}
        onClose={() => setShowInscricaoModal(false)}
        onSubmit={onSubmitAtalhoInscricao}
        setPeriodosInscritos={setPeriodosInscritos}
      />

      <ParticiparDialog
        open={openParticipar}
        periodosInscritos={periodosInscritos}
        sorteio={sorteioSelected}
        initialValues={{
          sorteio: sorteioSelected,
          cadeirante_1: false,
          cadeirante_2: false,
        }}
        title="Participar"
        onClose={() => {
          setOpenParticipar(false);
        }}
        associado={associadoSelected}
        onInscreverAssociado={() => {}}
      />

      <ResultadosDialog
        open={showResultadosModal}
        onClose={() => setShowResultadosModal(false)}
      />
    </>
  );
}

export default LoggedLayout;
