import React, { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import ForgotPasswordForm from 'forms/ForgotPasswordForm';
import { Typography, Button, Box, Paper } from '@material-ui/core';
import { FormContainer } from 'styles/global';
import Centered from 'components/Centered';
import api from 'services/api';
import AVMLogo from 'images/avm-logo-200.png';

const ForgotPassword = () => {
  const [success, setSuccess] = useState(false);
  const [email, setEmail] = useState('');

  const onSubmit = async values => {
    await api.post('auth/reset-password', values);
    setEmail(values.email);
    setSuccess(true);
  };

  if (success) {
    return (
      <Centered column>
        <img
          draggable={false}
          style={{ width: 200, marginBottom: 20 }}
          src={AVMLogo}
          alt="AVM Logo"
        />
        <FormContainer>
          <Typography align="center">{email}</Typography>
          <Typography align="center">
            E-mail enviado para alteração de senha!
          </Typography>
          <Box mt={3}>
            <Button
              fullWidth
              variant="outlined"
              color="primary"
              component={RouterLink}
              to="/"
            >
              Voltar para login
            </Button>
          </Box>
        </FormContainer>
      </Centered>
    );
  }

  return (
    <Paper style={{ maxWidth: 440, width: '100%', margin: 'auto' }}>
      <Centered column>
        <img
          draggable={false}
          style={{ width: 200, marginBottom: 20 }}
          src={AVMLogo}
          alt="Inovando's Logo"
        />
        <Typography variant="h6" mb={2} align="center">
          Esqueci minha senha
        </Typography>
        <FormContainer>
          <ForgotPasswordForm onSubmit={onSubmit} />
        </FormContainer>
      </Centered>
    </Paper>
  );
};

export default ForgotPassword;
