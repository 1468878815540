import React from 'react';
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  CircularProgress,
} from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

function SelectField({
  meta,
  input: { name, onChange, ...inputProps },
  label,
  options = [],
  loading = false,
  disabled = false,
  handleChange = () => {},
  ...rest
}) {
  return (
    <FormControl error={meta.touched && !!meta.error} {...rest}>
      <InputLabel htmlFor={name}>{label}</InputLabel>
      <Select
        label={label}
        inputProps={{
          name,
          id: name,
        }}
        disabled={disabled}
        IconComponent={props =>
          loading ? (
            <div>
              <CircularProgress color="inherit" size={20} {...props} />
            </div>
          ) : (
            <ArrowDropDownIcon {...props} />
          )
        }
        onChange={(...args) => {
          onChange(...args);
          handleChange(...args);
        }}
        {...inputProps}
      >
        {options.map(item => (
          <MenuItem key={item.value} value={item.value}>
            {item.label}
          </MenuItem>
        ))}
      </Select>
      {meta.touched && meta.error && (
        <FormHelperText>{meta.error}</FormHelperText>
      )}
    </FormControl>
  );
}

export default SelectField;
