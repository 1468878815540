import React, { createContext, useState, useEffect, useContext } from 'react';
import { toast } from 'react-toastify';
import api from 'services/api';
import { CUSTOMER_STATUS_CADASTRO_BLOQUEADO } from 'utils/constants';

const AuthContext = createContext({});

export function login(values) {
  return api.post('auth/login', values);
}

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isIrregularOrDuplicado, setIsIrregularOrDuplicado] = useState(false);
  const [isBlockedAccess, setIsBlockedAccess] = useState(false);

  useEffect(() => {
    function setInterceptors() {
      api.interceptors.response.use(
        function(response) {
          return response;
        },
        function(error) {
          if (error?.response?.status === 401) {
            const signed = !!localStorage.getItem('@token');

            if (signed) {
              toast.error('Sua sessão expirou, logue novamente.');
              signOut();
            } else {
              if (error.response.config.url.includes('login')) {
                toast.error('Credenciais inválidas');
              }
            }
          }

          return Promise.reject(error);
        },
      );
    }

    function loadStoragedData() {
      const storagedUser = localStorage.getItem('@user');
      const storagedToken = localStorage.getItem('@token');

      if (storagedUser && storagedToken) {
        api.defaults.headers.Authorization = `Bearer ${storagedToken}`;
        setUser(JSON.parse(storagedUser));
      }
      setLoading(false);
    }

    setInterceptors();
    loadStoragedData();
    updateUserContext();
  }, []);

  async function signIn(values) {
    const { data } = await login(values);

    saveUser(data);
    setTokenHeaderRequest(data);
    updateUserContext();
  }

  async function updateUserContext() {
    const { data } = await api.get('auth/me');
    setUser({ ...user, ...data });
  }

  async function signInToken(token) {
    const { data } = await api.get('auth/login/magic', {
      params: { token },
    });

    saveUser(data);
    setTokenHeaderRequest(data);
    updateUserContext();
  }

  function saveUser({ user, profiles, access_token }) {
    setUser({ ...user, profiles });

    if (user.email_duplicado || user.importacao_cadastro_irregular) {
      signOut();
      setIsIrregularOrDuplicado(true);
    }

    if (user.customer_status === String(CUSTOMER_STATUS_CADASTRO_BLOQUEADO)) {
      setIsBlockedAccess(true);
      signOut();

      return;
    }

    localStorage.setItem('@user', JSON.stringify({ ...user, profiles }));
    localStorage.setItem('@token', access_token.token);
  }

  function setTokenHeaderRequest({ access_token }) {
    api.defaults.headers.Authorization = `Bearer ${access_token.token}`;
  }

  async function signOut() {
    localStorage.clear();
    setUser(null);
  }

  return (
    <AuthContext.Provider
      value={{
        signed: !!user,
        user,
        signIn,
        signInToken,
        signOut,
        updateUserContext,
        loading,
        isIrregularOrDuplicado,
        isBlockedAccess,
        profiles: user?.profiles?.map(item => item.name) || [],
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export function useAuth() {
  const context = useContext(AuthContext);

  return context;
}
