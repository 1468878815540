import { setupYup } from 'config/yup';

const Yup = setupYup();

export const schema = Yup.object().shape({
  associado_cpf: Yup.string().required(),
  sorteio: Yup.object()
    .nullable()
    .required(),
});
