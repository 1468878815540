import React from 'react';
import Redeem from 'images/redeem.png';
import Block from 'images/block.png';
import Groups from 'images/groups.png';

import { Box, Paper, Typography, makeStyles } from '@material-ui/core';

import { getFormattedDate } from 'utils/transform';
import { TIPO_CAMPANHAS } from 'utils/constants';

const useStyles = makeStyles(theme => ({
  input: {
    marginTop: 50,
  },
  subtitle: {
    fontWeight: 'bold',
    fontSize: '16px',
    color: '#000000',
    textAlign: 'center',
    marginBottom: 10,
  },
  errorMessage: {
    fontSize: '14px',
    marginBottom: '20px',
    textAlign: 'center',
    marginTop: '20px',
    color: '#6F6F6F',
    fontWeight: 400,
  },
  actionButton: {
    backgroundColor: theme.palette.success.light,
    color: 'white',
    '&:hover': {
      backgroundColor: theme.palette.success.main,
    },
  },
  inscriptionNumber: {
    fontSize: 14,
    fontWeight: 400,
    marginLeft: 20,
  },
  inscriptionNumberBolder: {
    fontWeight: 700,
    marginLeft: 5,
  },
  codeIcon: {
    color: '#E53E3E',
    fontWeight: 700,
    fontSize: 12,
  },
  label: {
    fontWeight: 600,
    fontSize: 12,
    color: '#6F6F6F',
    marginRight: 5,
  },
}));

function DetalheSorteio({ sorteioProps }) {
  const status_sorteio = sorteioProps.status_resultado_sorteio_friendly;
  const classes = useStyles();

  const codeIcon = (
    <Box
      style={{
        padding: 2,
        borderRadius: 4,
        backgroundColor: '#F2f2f2',
      }}
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      <Typography className={classes.codeIcon}>123</Typography>
    </Box>
  );
  return (
    <>
      <Paper
        style={{
          maxWidth: 400,
          width: '100%',
          margin: 'auto',
          background: '#FFFFFF',
        }}
      >
        <Box padding={2}>
          <Box display="flex" alignItems="center">
            <Box flexDirection="row" display="flex" alignItems="center">
              <img
                src={
                  status_sorteio
                    ? status_sorteio === 'Ganhou'
                      ? Redeem
                      : Groups
                    : Block
                }
                alt="reedem"
              />
            </Box>

            <Box display="flex" flexDirection="column" marginLeft={2}>
              <Typography
                style={{ marginLeft: 0 }}
                className={classes.inscriptionNumberBolder}
              >
                {status_sorteio
                  ? status_sorteio === 'Ganhou'
                    ? 'Parabéns!'
                    : 'Foi quase!'
                  : 'Não foi dessa vez.'}
              </Typography>
              <Typography
                className={classes.inscriptionNumber}
                style={{ marginLeft: 0 }}
              >
                {status_sorteio
                  ? status_sorteio === 'Ganhou'
                    ? 'Você foi sorteado'
                    : 'Você está na lista de suplentes'
                  : 'Você não foi um dos escolhidos do sorteio'}
              </Typography>
            </Box>
          </Box>

          <Box flexDirection="row" display="flex" marginTop={5}>
            <Typography
              style={{ marginLeft: 0 }}
              className={classes.inscriptionNumber}
            >
              Sorteio:
            </Typography>
            <Typography className={classes.inscriptionNumberBolder}>
              {sorteioProps.nome_sorteio}
            </Typography>
          </Box>
          <Box flexDirection="row" display="flex">
            <Typography
              style={{ marginLeft: 0 }}
              className={classes.inscriptionNumber}
            >
              Colônia:
            </Typography>
            <Typography className={classes.inscriptionNumberBolder}>
              {sorteioProps.nome_colonia}
            </Typography>
          </Box>
          <Box
            style={{
              width: 80,
              display: 'flex',
              borderRadius: 8,
              justifyContent: 'center',
              alignItems: 'center',
              marginTop: 10,
              marginBottom: 10,
              backgroundColor: 'black',
            }}
          >
            <Typography
              style={{ marginLeft: 0, color: 'white' }}
              className={classes.inscriptionNumber}
            >
              {TIPO_CAMPANHAS[sorteioProps.tipo_da_campanha]}
            </Typography>
          </Box>
          {status_sorteio === 'Ganhou' ? (
            <Paper
              style={{
                border: '1px solid ##D7D7D7',
                padding: 10,
                marginBottom: 20,
                marginTop: 20,
                backgroundColor: '#F8F8F8',
              }}
            >
              <Typography
                style={{ fontSize: 12, color: '#000', fontWeight: 400 }}
              >
                Unidade contemplada: {sorteioProps.nome_unidade}
              </Typography>
              <Typography
                style={{ fontSize: 12, color: '#000', fontWeight: 400 }}
              >
                Dia do pagamento:{' '}
                {getFormattedDate(sorteioProps.data_pagamento_inicio)} -{' '}
                {getFormattedDate(sorteioProps.data_pagamento_fim)}
              </Typography>

              {status_sorteio === 'Ganhou' ? (
                <Typography
                  className={classes.label}
                  style={{ color: '#000', fontWeight: 700 }}
                >
                  Data da ocupação:{' '}
                  {getFormattedDate(sorteioProps.data_ocupacao_inicio)} -{' '}
                  {getFormattedDate(sorteioProps.data_ocupacao_fim)}{' '}
                </Typography>
              ) : (
                <Typography
                  style={{ marginLeft: 0, fontSize: 12, marginBottom: 40 }}
                  className={classes.inscriptionNumberBolder}
                ></Typography>
              )}
            </Paper>
          ) : (
            <Paper
              style={{
                border: '1px solid ##D7D7D7',
                padding: 10,
                marginBottom: 20,
                marginTop: 20,
                backgroundColor: '#F8F8F8',
              }}
            >
              <Typography
                style={{ fontSize: 12, color: '#000', fontWeight: 400 }}
              >
                Posição do suplente: {sorteioProps.ordem_suplente}
              </Typography>
              <Typography
                style={{ fontSize: 12, color: '#000', fontWeight: 400 }}
              >
                Data do suplente:{' '}
                {getFormattedDate(sorteioProps.data_suplente_inicio)} -{' '}
                {getFormattedDate(sorteioProps.data_suplente_fim)}
              </Typography>
            </Paper>
          )}

          <Typography
            style={{
              marginLeft: 0,
              fontSize: 12,
              marginBottom: 40,
              padding: '5px',
              paddingBottom: '0',
            }}
            className={classes.inscriptionNumberBolder}
          >
            {status_sorteio === 'Ganhou'
              ? 'Fique atento à data de pagamento da taxa da reserva e a data de ocupação.'
              : 'Fique atento aos anúncios da AVM, você ainda poderá ser chamado caso alguma vaga fique disponível por desistência de um sorteado'}
          </Typography>
          <Paper
            style={{
              backgroundColor: '#DEDEDE',
              marginBottom: 20,
              marginTop: 20,
            }}
          >
            <Box
              flexDirection="row"
              padding={1}
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              {codeIcon}
              <Typography className={classes.inscriptionNumber}>
                Código da inscrição:
              </Typography>
              <Typography className={classes.inscriptionNumberBolder}>
                {sorteioProps.codigo_inscricao}
              </Typography>
            </Box>
          </Paper>
        </Box>
      </Paper>
    </>
  );
}

export default DetalheSorteio;
