import React from 'react';
import './styles.css';
import ImageIcon from '@material-ui/icons/Image';

function ImagemAssociado() {
  return (
    <label className="thumbnail">
      <ImageIcon fontSize="large" color="disabled" />
    </label>
  );
}

export default ImagemAssociado;
