import React, { useState } from 'react';
import { Box, IconButton } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/DeleteOutline';
import EditIcon from '@material-ui/icons/EditOutlined';
import ConfirmModal from 'components/ConfirmModal';

function ActionsTable({
  item,
  onDelete,
  onEdit,
  editIcon = <EditIcon fontSize="small" />,
  showDelete = true,
}) {
  const [idToDelete, setIdToDelete] = useState(null);
  const [loading, setLoading] = useState(false);

  return (
    <Box display="flex" alignItems="center" justifyContent="flex-end">
      {!!onEdit && (
        <IconButton
          aria-label="edit"
          color="primary"
          onClick={event => {
            event.stopPropagation();
            onEdit(item);
          }}
        >
          {editIcon}
        </IconButton>
      )}
      {showDelete && (
        <IconButton
          aria-label="delete"
          color="secondary"
          onClick={event => {
            event.stopPropagation();
            setIdToDelete(item.id);
          }}
        >
          <DeleteIcon fontSize="small" />
        </IconButton>
      )}
      <ConfirmModal
        open={!!idToDelete}
        onClose={() => {
          setIdToDelete(null);
        }}
        loading={loading}
        onAccept={async () => {
          try {
            setLoading(true);
            await onDelete(item);
            setIdToDelete(null);
          } finally {
            setLoading(false);
          }
        }}
      />
    </Box>
  );
}

export const actionsTableOptions = ({
  data,
  onEdit = false,
  editIcon,
  showDelete = true,
  onDelete = () => {},
}) => ({
  filter: false,
  sort: false,
  setCellHeaderProps: () => ({
    style: { textAlign: 'right', paddingRight: 32 },
  }),
  setCellProps: () => ({ style: { textAlign: 'right' } }),
  customBodyRender: (_value, { rowIndex }) => {
    const item = data[rowIndex];
    return (
      <ActionsTable
        item={item}
        onDelete={onDelete}
        onEdit={onEdit}
        editIcon={editIcon}
        showDelete={showDelete}
      />
    );
  },
});
