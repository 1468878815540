import React, { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Form } from 'react-final-form';
import createDecorator from 'final-form-focus';
import Button from '@material-ui/core/Button';

import { validate } from 'utils/validate';
import { schema } from './schema';

import { Box, Link, Grid, InputAdornment, IconButton } from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';
import InputText from 'components/InputText/InputText';
import { MdMailOutline, MdPassword } from 'react-icons/md';
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai';

const useStyles = makeStyles(() => ({
  link: {
    fontWeight: 400,
    fontSize: '10px',
    lineHeight: '150%',
    textAlign: 'right',
    textDecorationLine: 'underline',
    color: '#5C5C5C',
  },
  buttonSuccess: {
    color: '#fff',
    backgroundColor: '#4AAC48',
    textTransform: 'capitalize',
    fontSize: '16px',
    textWeight: 'bold',
    '&:hover': {
      backgroundColor: '#4AAC48',
    },
  },
}));

const focusOnErrors = createDecorator();

function LoginForm({ onSubmit }) {
  const classes = useStyles();
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () =>
    setShowPassword(prevState => !prevState);

  return (
    <Form
      onSubmit={onSubmit}
      decorators={[focusOnErrors]}
      validate={validate(schema)}
      render={({ handleSubmit, submitting }) => (
        <form onSubmit={handleSubmit} noValidate>
          <InputText name="email" label="E-mail" Icon={MdMailOutline} />
          <InputText
            name="password"
            type={showPassword ? 'text' : 'password'}
            label="Password"
            Icon={MdPassword}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                  >
                    {showPassword ? (
                      <AiOutlineEye />
                    ) : (
                      <AiOutlineEyeInvisible />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <Grid container direction="row" justify="flex-end">
            <Link
              align="right"
              component={RouterLink}
              to="/esqueci-minha-senha"
              className={classes.link}
            >
              resetar a senha
            </Link>
          </Grid>

          <Box mt={3}>
            <Button
              disabled={submitting}
              fullWidth
              variant="contained"
              type="submit"
              data-cy="enviar"
              className={classes.buttonSuccess}
            >
              Enviar
            </Button>
          </Box>
        </form>
      )}
    />
  );
}

export default LoginForm;
