import React from 'react';
import { Form } from 'react-final-form';
import createDecorator from 'final-form-focus';
import Button from '@material-ui/core/Button';
import { Box } from '@material-ui/core';

import { format } from 'utils/form';
import InputText from 'components/InputText/InputText';
import { MdOutlineBadge } from 'react-icons/md';

import { TbMailForward } from 'react-icons/tb';
import { Link as RouterLink } from 'react-router-dom';

const focusOnErrors = createDecorator();

const successButton = {
  color: '#fff',
  backgroundColor: '#4AAC48',
  textTransform: 'initial',
  fontSize: '16px',
  textWeight: 'bold',
};

const dangerButton = {
  color: '#E53E3E',
  background: 'transparent',
  textTransform: 'initial',
  fontSize: '16px',
  textWeight: 'bold',
  border: '#E53E3E 1px solid',
};

function LoginPorEmailConfirmarForm({ onSubmit, initialValues }) {
  const CPF_MASK = 'XXX.XXX.XXX-XX';

  return (
    <Form
      onSubmit={onSubmit}
      decorators={[focusOnErrors]}
      initialValues={initialValues}
      render={({ handleSubmit, submitting }) => (
        <form onSubmit={handleSubmit} noValidate>
          <InputText
            name="cpf"
            label="CPF"
            Icon={MdOutlineBadge}
            disabled
            {...format(CPF_MASK)}
          />
          <InputText
            name="email"
            label="O e-mail será enviado para:"
            Icon={TbMailForward}
            blocked
          />

          <Box mt="32px">
            <RouterLink to="/cadastro-por-telefone">
              <Button
                disabled={submitting}
                fullWidth
                variant="outlined"
                style={dangerButton}
                data-cy="nao-e-meu-email"
              >
                Esse não é meu e-mail
              </Button>
            </RouterLink>
          </Box>
          <Box mt="16px">
            <Button
              disabled={submitting}
              fullWidth
              variant="contained"
              type="submit"
              style={successButton}
              data-cy="enviar-email"
            >
              Enviar e-mail
            </Button>
          </Box>
        </form>
      )}
    />
  );
}

export default LoginPorEmailConfirmarForm;
