import React, { useState } from 'react';

import { AppBar, Paper, Box, Tabs, Tab, makeStyles } from '@material-ui/core';
import { addHours, parseISO } from 'date-fns';

import Dependentes from './Dependentes/Dependentes';
import DadosBasicos from './DadosBasicos/DadosBasicos';
import Carteirinhas from './Carteirinhas/Carteirinhas';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.primary.main,
    color: '#fff',
  },
}));

function AssociadoCadastradoTabs({
  associado = {},
  handleSelectDependente = () => {},
  selectedDependente,
  handleUpdateData,
}) {
  const classes = useStyles();
  const [tabIndex, setTabIndex] = useState(0);

  const setTab = (_, index) => {
    setTabIndex(index);
  };

  return (
    <Box>
      <AppBar position="static">
        <Tabs
          value={tabIndex}
          onChange={setTab}
          className={classes.root}
          variant="scrollable"
          scrollButtons="auto"
          style={{ display: selectedDependente && 'none' }}
          TabIndicatorProps={{
            style: {
              backgroundColor: '#3bc3ff',
              height: 5,
            },
          }}
        >
          <Tab data-cy="dados" label="Dados Básicos" />
          <Tab data-cy="dependentes" label="Dependentes" />
          <Tab data-cy="carteirinhas" label="Carteirinhas" />
        </Tabs>
      </AppBar>
      <Paper>
        <Box p={2}>
          {tabIndex === 0 && (
            <DadosBasicos
              handleUpdateData={handleUpdateData}
              data={associado}
            />
          )}
          {tabIndex === 1 && (
            <Dependentes
              handleUpdateData={handleUpdateData}
              associadoId={associado.id}
              handleSelectDependente={handleSelectDependente}
              dependentes={associado.dependentes.map(item => ({
                ...item,
                data_inclusao: item.data_inclusao
                  ? addHours(parseISO(item.data_inclusao), 4).toISOString()
                  : item.data_inclusao,
                data_validade: item.data_validade
                  ? addHours(parseISO(item.data_validade), 4).toISOString()
                  : item.data_validade,
                data_nascimento: item.data_nascimento
                  ? addHours(parseISO(item.data_nascimento), 4).toISOString()
                  : item.data_nascimento,
              }))}
              selectedDependente={selectedDependente}
            />
          )}
          {tabIndex === 2 && (
            <Carteirinhas
              dependentes={[
                {
                  grau_parentesco: 'Associado',
                  id: associado.id,
                  nome: associado.nome,
                  created_at: associado.created_at,
                  cpf: associado.cpf,
                },
                ...associado.dependentes,
              ]}
              associado={associado}
            />
          )}
        </Box>
      </Paper>
    </Box>
  );
}

export default AssociadoCadastradoTabs;
