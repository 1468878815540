import React, { Suspense, useMemo } from 'react';
import { Router, Switch, Route } from 'react-router-dom';

import PrivateRoute from 'components/PrivateRoute';
import { useAuth } from 'contexts/auth';
import LoggedLayout from 'layouts/LoggedLayout';
import config from './config';
import { Box, CircularProgress } from '@material-ui/core';
import { DEFAULT_PROFILE } from 'utils/constants';
import history from 'services/history';

function formatRoute({ json_metadata: metadata }) {
  return {
    path: metadata?.url_front,
    title: metadata?.menu_name,
    component: config.protected.find(
      route => route.title === metadata?.menu_name && !!route.component,
    )?.component,
    icon: config.protected.find(
      route => route.title === metadata?.menu_name && !!route.component,
    )?.icon,
    exact: true,
  };
}

function Routes() {
  const { loading = true, profiles, user } = useAuth();
  const isAssociado = profiles.includes(DEFAULT_PROFILE);

  const protectedRoutes = useMemo(
    () =>
      user?.recursos_disponiveis
        ?.filter(item => !!item.json_metadata)
        ?.map(formatRoute)
        ?.filter(item => !!item.component) || [],
    [user?.recursos_disponiveis],
  );

  const menuRoutes = useMemo(
    () =>
      user?.recursos_disponiveis
        ?.filter(item => item.json_metadata?.is_menu)
        ?.sort((a, b) => a.json_metadata?.ordem - b.json_metadata?.ordem)
        .map(formatRoute),
    [user?.recursos_disponiveis],
  );

  const allRoutes = [
    ...protectedRoutes.filter(item => item.path.includes('/new')),
    ...protectedRoutes.filter(item => !item.path.includes('/new')),
  ];

  if (loading) return null;

  return (
    <Router history={history}>
      <Suspense
        fallback={
          <Box display="flex" justifyContent="center">
            <CircularProgress />
          </Box>
        }
      >
        <Switch>
          {config.public.map(({ component: Component, ...rest }, i) => (
            <Route key={i} {...rest}>
              <Component />
            </Route>
          ))}
          <LoggedLayout routes={menuRoutes} allRoutes={allRoutes}>
            <Switch>
              {!isAssociado
                ? allRoutes?.map(({ component: Component, ...rest }, i) => (
                    <PrivateRoute key={i} {...rest}>
                      <Component />
                    </PrivateRoute>
                  ))
                : allRoutes
                    ?.filter(item => {
                      return (
                        item.title === 'Associado Dashboard' ||
                        item.title === 'Notificações Associado Web' ||
                        item.title === 'Associado Sorteio Web' ||
                        item.title === 'Associado Sorteio'
                      );
                    })
                    ?.map(({ component: Component, ...rest }, i) => (
                      <PrivateRoute key={i} {...rest}>
                        {Component && <Component />}
                      </PrivateRoute>
                    ))}
            </Switch>
          </LoggedLayout>
        </Switch>
      </Suspense>
    </Router>
  );
}

export default Routes;
