import React from 'react';
import DialogForm from 'components/DialogForm';
import AtalhoInscricaoForm from 'forms/AtalhoInscricaoForm';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';

import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  dialogActions: {
    paddingLeft: 0,
    paddingRight: 0,
  },
});

function AtalhoInscricaoDialog({
  onSubmit,
  initialValues,
  onClose = () => {},
  open = false,
  setPeriodosInscritos,
}) {
  const classes = useStyles();

  return (
    <>
      <DialogForm
        title="Inscrição por CPF"
        open={open}
        onClose={onClose}
        disableBackdropClick={true}
      >
        {({ onClose }) => (
          <AtalhoInscricaoForm
            onSubmit={onSubmit}
            initialValues={initialValues}
            setPeriodosInscritos={setPeriodosInscritos}
            footer={({ submitting }) => (
              <DialogActions className={classes.dialogActions}>
                {!submitting && (
                  <Button onClick={onClose} color="primary">
                    Cancelar
                  </Button>
                )}
                <Button
                  variant="contained"
                  disabled={submitting}
                  type="submit"
                  color="primary"
                >
                  Salvar
                </Button>
              </DialogActions>
            )}
          />
        )}
      </DialogForm>
    </>
  );
}

export default AtalhoInscricaoDialog;
