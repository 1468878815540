export function isUserAllowed(user, ...permissions) {
  const hasPermissions = user?.recursos_disponiveis?.find(rsrc =>
    permissions.includes(rsrc.nome),
  );

  if (hasPermissions) {
    return true;
  }
  return false;
}
