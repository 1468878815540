import React from 'react';
import { Form } from 'react-final-form';
import createDecorator from 'final-form-focus';
import Button from '@material-ui/core/Button';
import { validate } from 'utils/validate';
import { schema } from './schema';
import { Box, Divider, Typography } from '@material-ui/core';

import { format } from 'utils/form';
import InputText from 'components/InputText/InputText';
import { MdOutlineBadge, MdOutlinePhone } from 'react-icons/md';

import { TbMailForward } from 'react-icons/tb';
import LinkButton from 'components/LinkButton/LinkButton';

const focusOnErrors = createDecorator();

const successButton = {
  color: '#fff',
  backgroundColor: '#4AAC48',
  textTransform: 'capitalize',
  fontSize: '16px',
  textWeight: 'bold',
};

function LoginPorEmailForm({ onSubmit }) {
  const CPF_MASK = 'XXX.XXX.XXX-XX';

  return (
    <Form
      onSubmit={onSubmit}
      decorators={[focusOnErrors]}
      validate={validate(schema)}
      render={({ handleSubmit, submitting }) => (
        <form onSubmit={handleSubmit} noValidate>
          <InputText
            name="cpf"
            label="CPF"
            data-cy="cpf"
            Icon={MdOutlineBadge}
            {...format(CPF_MASK)}
          />
          <Box mt={2}>
            <Button
              disabled={submitting}
              fullWidth
              variant="contained"
              type="submit"
              style={successButton}
              data-cy="avancar"
            >
              Avançar
            </Button>
          </Box>
          <Box marginY="32px" marginX="auto" maxWidth="242px">
            <Divider />
          </Box>

          <Box mt={1}>
            <Typography align="center" variant="body1">
              Outras formas para se inscrever
            </Typography>

            <Box display="flex" justifyContent="space-between" marginTop="16px">
              <LinkButton
                data-cy="entrar-com-email"
                Icon={TbMailForward}
                label="Entrar com e-mail e senha"
                to="/login"
                marginRight="15px"
              />

              <LinkButton
                data-cy="contat-usando-telefone"
                Icon={MdOutlinePhone}
                label="Contato usando o telefone"
                to="/contato-por-telefone"
              />
            </Box>
          </Box>
        </form>
      )}
    />
  );
}

export default LoginPorEmailForm;
