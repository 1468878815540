import React, { useEffect, useState } from 'react';

import {
  Dialog,
  DialogActions,
  DialogContent,
  Button,
  makeStyles,
  DialogTitle,
  Typography,
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  dialogScrollpaper: {
    overflowY: 'hidden',
  },
  title: {
    fontSize: '1.4rem',
    fontWeight: 500,
    marginBottom: theme.spacing(1),
  },
}));

function ExibeIrregularDialog({ open = false, setStateOpened = () => {} }) {
  const classes = useStyles();
  const [openDialog, setOpenDialog] = useState(open);

  useEffect(() => {
    setOpenDialog(open);
  }, [open]);

  function onClose() {
    setOpenDialog(false);
    setStateOpened(false);
  }

  return (
    <Dialog
      maxWidth="md"
      onClose={onClose}
      open={openDialog}
      classes={{
        paper: classes.dialogScrollpaper,
      }}
    >
      <DialogTitle>
        <Typography className={classes.title}>
          O seu cadastro está irregular
        </Typography>
      </DialogTitle>
      <DialogContent>
        Prezado Associado, entrar em contato com o setor de cadastros da AVM
        para realizar a atualização do seu e-mail.
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Fechar
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ExibeIrregularDialog;
