import React from 'react';
import { Form, Field } from 'react-final-form';
import createDecorator from 'final-form-focus';
import { Checkbox } from 'final-form-material-ui';

import { validate } from 'utils/validate';
import { schema } from './schema';
import {
  FormControlLabel,
  Typography,
  Card,
  Box,
  Grid,
} from '@material-ui/core';
import { AutocompleteField } from '@inovando/react-components';
import { getFormattedDate } from 'utils/transform';

const focusOnErrors = createDecorator();

function ParticiparForm({
  onSubmit,
  footer = () => {},
  initialValues = {},
  children,
  sorteio,
  periodos = [],
}) {
  const getPeriodoLabels = option => {
    const dataInicio = getFormattedDate(option.data_inicio);
    const dataFim = getFormattedDate(option.data_fim);

    return `${dataInicio} até ${dataFim} - ${option.nome} ${
      option.disabled ? '(Inscrito)' : ''
    }`;
  };

  const sortItems = arr => {
    return arr.sort((a, b) => {
      const nomeA = a.nome.toLowerCase();
      const nomeB = b.nome.toLowerCase();

      if (nomeA < nomeB) {
        return -1;
      }
      if (nomeA > nomeB) {
        return 1;
      }
      return 0;
    });
  };

  return (
    <Form
      onSubmit={onSubmit}
      decorators={[focusOnErrors]}
      validate={validate(schema)}
      initialValues={initialValues}
      render={props => {
        const sorteioPermiteDuasOpcoes = sorteio.permite_duas_opcoes;

        const noPeriodosRestantes =
          periodos.filter(periodo => periodo.id !== props.values.periodo_1)
            .length === 0;

        const periodo2FieldDisabled =
          noPeriodosRestantes || !props.values.periodo_1;

        return (
          <form onSubmit={props.handleSubmit} noValidate>
            <Field
              name="sorteio"
              type="text"
              component={AutocompleteField}
              required
              valueAttr="id"
              labelAttr="nome"
              disabled
              label="Sorteio"
              margin="normal"
              fullWidth
              variant="outlined"
            />
            <Box height={15} />
            <Card variant="outlined">
              <Box p={2}>
                <Typography variant="h6">Primeira opção</Typography>
                <Field
                  data-cy="1-opcao"
                  name="periodo_1"
                  type="text"
                  component={AutocompleteField}
                  getOptionLabel={getPeriodoLabels}
                  required
                  valueAttr="id"
                  kind="value"
                  options={sortItems(sorteio.periodos)}
                  getOptionDisabled={option => option.disabled}
                  label="Período"
                  margin="normal"
                  fullWidth
                  variant="outlined"
                />
                <FormControlLabel
                  disabled={!props.values.periodo_1}
                  control={
                    <Field
                      type="checkbox"
                      name="cadeirante_1"
                      component={Checkbox}
                      color="primary"
                    />
                  }
                  label="Desejo concorrer como cadeirante"
                />
              </Box>
            </Card>
            <Box height={10} />
            {sorteioPermiteDuasOpcoes && (
              <Card variant="outlined">
                <Box p={2}>
                  <Grid container spacing={1} alignItems="center">
                    <Grid item>
                      <Typography variant="h6">Segunda opção</Typography>
                    </Grid>
                    <Grid item>
                      <Typography>(opcional)</Typography>
                    </Grid>
                  </Grid>
                  <Field
                    name="periodo_2"
                    type="text"
                    component={AutocompleteField}
                    getOptionLabel={getPeriodoLabels}
                    valueAttr="id"
                    kind="value"
                    disabled={periodo2FieldDisabled}
                    options={[
                      ...sortItems(
                        sorteio.periodos.filter(
                          periodo => periodo.id !== props.values.periodo_1,
                        ),
                      ),
                    ]}
                    getOptionDisabled={option => option.disabled}
                    label="Período"
                    margin="normal"
                    fullWidth
                    variant="outlined"
                  />

                  <FormControlLabel
                    disabled={!props.values.periodo_2}
                    control={
                      <Field
                        type="checkbox"
                        name="cadeirante_2"
                        component={Checkbox}
                        color="primary"
                      />
                    }
                    label="Desejo concorrer como cadeirante"
                  />
                </Box>
              </Card>
            )}
            {children}
            {footer(props)}
          </form>
        );
      }}
    />
  );
}

export default ParticiparForm;
