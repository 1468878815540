import React from 'react';
import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  Container,
  MenuItem,
  Modal,
  Paper,
  Select,
  Typography,
  makeStyles,
} from '@material-ui/core';
import {
  Cached,
  CheckCircleOutline,
  Close,
  HighlightOff,
  PeopleOutline,
  UpdateOutlined,
} from '@material-ui/icons';
import { PersonAddAlt, WarningAmber } from '@mui/icons-material';
import ImageIcon from '@mui/icons-material/Image';

import BreadcrumbsAVM from 'components/BreadcrumbsAVM';
import InputText from 'components/InputText';
import { Field, Form } from 'react-final-form';
import { useState } from 'react';
import { useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import api from 'services/api';
import { Skeleton } from '@mui/material';
import { maskBr } from 'js-brasil';
import { getFormattedDate } from 'utils/transform';
import {
  GRAU_DEPENDENTE,
  STATUS_APROVACAO_APROVADO,
  STATUS_APROVACAO_PENDENTE,
  STATUS_APROVACAO_REPROVADO,
  TIPO_APROVACAO_CADASTRO,
  TIPO_APROVACAO_DEPENDENTE,
} from 'utils/constants';
import { toast } from 'react-toastify';
import { TextField } from 'final-form-material-ui';
import { addHours, parseISO } from 'date-fns';

const useStyles = makeStyles(theme => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalContent: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(4),
    outline: 'none',
    borderRadius: 10,
    maxWidth: 550,
    width: '100%',
    margin: 'auto',
  },
}));

const Row = ({ children, ...props }) => {
  return (
    <Box display="flex" alignItems="center" flexDirection="row" {...props}>
      {children}
    </Box>
  );
};

const RowsInfos = ({ name, value, isSpaced = true, ...props }) => {
  return (
    <Row justifyContent={isSpaced && 'space-between'} {...props}>
      <Typography
        style={{
          fontSize: 16,
          fontWeight: 400,
          color: '#6f6f6f',
          marginRight: !isSpaced ? 10 : 0,
        }}
      >
        {name}
      </Typography>
      <Typography style={{ fontSize: 16, fontWeight: 400, color: '#000' }}>
        {value}
      </Typography>
    </Row>
  );
};

const ColumnInfos = ({ name, value, ...props }) => {
  return (
    <Box {...props}>
      <Typography style={{ fontSize: 16, fontWeight: 400, color: '#6f6f6f' }}>
        {name}
      </Typography>
      <Typography style={{ fontSize: 16, fontWeight: 400, color: '#000' }}>
        {value}
      </Typography>
    </Box>
  );
};

const getDependenteStatus = status => {
  let value = { color: '', label: '' };
  switch (status) {
    case STATUS_APROVACAO_PENDENTE:
      value = { color: '#F4C974', label: 'Pendente' };
      break;
    case STATUS_APROVACAO_REPROVADO:
      value = { color: '#FF2C20', label: 'Reprovado' };
      break;
    default:
      value = { color: '#66D969', label: 'Aprovado' };
      break;
  }

  return value;
};

const DependenteCard = ({ dependente, handleSelectDependente }) => {
  return (
    <Box
      data-cy="dependente-card"
      onClick={() => handleSelectDependente(dependente)}
      style={{
        backgroundColor: '#F8F8F8',
        borderStyle: 'solid',
        borderColor: '#D7D7D7',
        borderWidth: 1,
        borderRadius: 6,
        padding: 10,
        cursor: 'pointer',
      }}
    >
      <Row justifyContent="space-between">
        <Typography style={{ fontWeight: 600, fontSize: 18 }}>
          {dependente?.nome}
        </Typography>

        <Box
          style={{
            padding: '0 12px 0 12px',
            borderRadius: 20,
            backgroundColor: getDependenteStatus(
              dependente?.aprovacao?.status_aprovacao,
            ).color,
            color: '#FFF',
            fontWeight: 600,
            fontSize: 15,
          }}
        >
          {getDependenteStatus(dependente?.aprovacao?.status_aprovacao).label}
        </Box>
      </Row>
      <Box mt={1}>
        <RowsInfos
          isSpaced={false}
          name="CPF"
          value={maskBr.cpf(dependente?.cpf)}
        />
        <RowsInfos
          isSpaced={false}
          name="Data de Nascimento"
          value={getFormattedDate(dependente?.data_nascimento, 'dd/MM/yyyy')}
        />
        <RowsInfos
          isSpaced={false}
          name="Grau de dependência"
          value={
            GRAU_DEPENDENTE.find(
              item => String(item.value) === dependente.grau_parentesco,
            )?.label
          }
        />
      </Box>
    </Box>
  );
};

const InputsLabels = ({ label, isChange = false }) => {
  return (
    <Typography
      style={{
        marginTop: 10,
        fontSize: '16px',
        fontWeight: 700,
        lineHeight: '150%',
        color: isChange ? '#D7A950' : '#000',
      }}
    >
      {label}
    </Typography>
  );
};

function DetalhesAprovacao() {
  const [aprovacaoDetails, setAprovacaoDetails] = useState({});
  const [selectedStatus, setSelectedStatus] = useState(0);
  const [loading, setLoading] = useState(true);
  const [saveLoading, setSaveLoading] = useState(false);
  const [selectedDependente, setSelectedDependente] = useState({});
  const [selectedPhoto, setSelectedPhoto] = useState(null);

  const [dependentesModal, setDependentesModal] = useState(false);
  const classes = useStyles();
  // eslint-disable-next-line no-unused-vars
  const history = useHistory();

  let { id } = useParams();

  const caminho = [
    {
      nome: 'Gerenciar Cadastro',
      path: '/approve-subscription/',
      active: false,
    },
    {
      nome: 'Detalhes Aprovação',
      path: `/approve-subscription/${id}`,
      active: true,
    },
  ];

  const getAprovacaoDetails = async () => {
    try {
      setLoading(true);
      const { data } = await api.get(
        `/aprovacoes/${id}?include=associado.enderecos.unidade`,
      );

      const { data: associadosData } = await api.get(
        `/dependentes?associado_id=${data?.associado?.id}&include=aprovacao`,
      );

      data.associado.dependentes = associadosData.data.map(item => ({
        ...item,
        data_inclusao: item.data_inclusao
          ? addHours(parseISO(item.data_inclusao), 4).toISOString()
          : item.data_inclusao,
        data_validade: item.data_validade
          ? addHours(parseISO(item.data_validade), 4).toISOString()
          : item.data_validade,
        data_nascimento: item.data_nascimento
          ? addHours(parseISO(item.data_nascimento), 4).toISOString()
          : item.data_nascimento,
      }));
      setSelectedStatus(data.status_aprovacao);
      setAprovacaoDetails(data);
    } catch (error) {
      toast.error('Ocorreu um erro ao buscar os dados da aprovação');
      history.push('/approve-subscription');
    } finally {
      setLoading(false);
    }
  };

  const aprovacoesSelect = [
    {
      value: STATUS_APROVACAO_PENDENTE,
      label: 'Pendente',
    },
    {
      value: STATUS_APROVACAO_APROVADO,
      label: 'Aprovado',
    },
    {
      value: STATUS_APROVACAO_REPROVADO,
      label: 'Reprovado',
    },
  ];

  const getLabelsApproveStatus = value => {
    let values;
    switch (value) {
      case TIPO_APROVACAO_DEPENDENTE:
        values = {
          label: `Dependentes (${aprovacaoDetails?.associado?.dependentes
            ?.length ?? 0})`,
          icon: <PeopleOutline style={{ color: '#3785FD' }} />,
        };
        break;
      case TIPO_APROVACAO_CADASTRO:
        values = {
          label: 'Novo cadastro',
          icon: <PersonAddAlt style={{ color: '#3785FD' }} />,
        };
        break;
      default:
        values = {
          label: 'Atualização',
          icon: <UpdateOutlined style={{ color: '#3785FD' }} />,
        };
        break;
    }
    return values;
  };

  const handleSave = async values => {
    try {
      setSaveLoading(true);
      if (values.tipo_aprovacao === TIPO_APROVACAO_DEPENDENTE) {
        if (new Date(values.data_validade) < new Date()) {
          toast.error('A data de validade deve ser maior do que hoje.');
          return;
        }

        if (!selectedDependente.id) {
          toast.error('Selecione o dependente antes de aprovar.');
          return;
        }
        await api.put(`/dependentes/${selectedDependente.id}`, {
          data_validade: new Date(values.data_validade),
        });
      }

      await api.put(`/aprovacoes/${id}`, {
        status_aprovacao: selectedStatus,
        nota: values?.nota,
      });

      toast.success('Status alterado com sucesso !');
      history.push('/approve-subscription');
    } catch (error) {
      toast.error(
        error?.response?.data?.message ||
          'Ocorreu um erro ao realizar a aprovação.',
      );
    } finally {
      setSaveLoading(false);
    }
  };

  const handleSelectDependente = dependente => {
    setLoading(true);
    setSelectedDependente(dependente);
    setDependentesModal(false);
    setTimeout(() => {
      setLoading(false);
    }, 500);
  };

  useEffect(() => {
    getAprovacaoDetails();
  }, []);

  return (
    <Container style={{ position: 'relative' }}>
      <BreadcrumbsAVM caminho={caminho} />
      <Modal
        className={classes.modal}
        open={dependentesModal}
        onClose={() => setDependentesModal(false)}
      >
        <div
          style={{ overflow: 'auto', maxHeight: 600 }}
          className={classes.modalContent}
        >
          <Row justifyContent="space-between" mb={4}>
            <Row>
              <PeopleOutline style={{ fontSize: 50, color: '#3785FD' }} />
              <Typography
                style={{ fontSize: 18, fontWeight: 700, marginLeft: 10 }}
              >
                Dependentes
              </Typography>
            </Row>
            <Button onClick={() => setDependentesModal(false)}>
              <Close />
            </Button>
          </Row>
          {aprovacaoDetails?.associado?.dependentes.length > 0 ? (
            aprovacaoDetails?.associado?.dependentes.map(item => (
              <>
                <div style={{ marginTop: 10 }} />
                <DependenteCard
                  handleSelectDependente={handleSelectDependente}
                  dependente={item}
                  key={item.id}
                />
              </>
            ))
          ) : (
            <div>Nenhum dependente encontado.</div>
          )}
        </div>
      </Modal>
      <Modal
        className={classes.modal}
        open={selectedPhoto}
        onClose={() => setSelectedPhoto(null)}
      >
        <img src={selectedPhoto} style={{ width: 800 }} alt="foto" />
      </Modal>
      <Paper style={{ padding: 20 }}>
        {!loading ? (
          <>
            <Row justifyContent="space-between">
              <Typography style={{ fontSize: 18, fontWeight: 700 }}>
                {selectedDependente?.id
                  ? 'Aprovação de cadastro de dependente'
                  : 'Aprovação de cadastro'}
              </Typography>
              <Box>
                <Button
                  startIcon={<Cached />}
                  style={{ backgroundColor: '#DFDFDF', marginRight: 5 }}
                  onClick={getAprovacaoDetails}
                >
                  Forçar Atualizar
                </Button>
                <Button
                  startIcon={
                    getLabelsApproveStatus(aprovacaoDetails.tipo_aprovacao).icon
                  }
                  style={{ backgroundColor: '#DFDFDF' }}
                >
                  {
                    getLabelsApproveStatus(aprovacaoDetails.tipo_aprovacao)
                      .label
                  }
                </Button>
              </Box>
            </Row>
            <Box style={{ padding: 40, marginTop: 10 }}>
              <Row display="flex" alignItems="center" flexDirection="row">
                <Box
                  style={{
                    justifyContent: 'center',
                    alignItems: 'center',
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  {selectedDependente.id && (
                    <Typography style={{ color: '#20496E', fontWeight: 600 }}>
                      Dependente
                    </Typography>
                  )}
                  <Avatar
                    style={{
                      width: 107,
                      height: 107,
                      borderWidth: 5,
                      borderColor: '#0C3D88',
                      borderStyle: 'solid',
                    }}
                    src={
                      selectedDependente?.avatar ??
                      aprovacaoDetails?.associado?.avatar_id
                    }
                  />
                </Box>
                <Box marginLeft={4}>
                  <Typography style={{ fontSize: 19, fontWeight: 600 }}>
                    {selectedDependente?.nome ??
                      aprovacaoDetails?.associado?.nome}
                  </Typography>
                  {!selectedDependente.id && (
                    <Row>
                      <Button
                        data-cy="ver-dependentes"
                        onClick={() => setDependentesModal(true)}
                        style={{
                          borderStyle: 'solid',
                          borderWidth: 1,
                          borderColor: aprovacaoDetails?.associado
                            ?.dependente_aprovacao_pendente
                            ? '#D7A950'
                            : '#E2E2E2',
                          color: '#525252',
                          fontWeight: 700,
                          fontSize: 12,
                          marginTop: 5,
                          alignItems: 'center',
                          display: 'flex',
                        }}
                      >
                        {aprovacaoDetails?.associado
                          ?.dependente_aprovacao_pendente && (
                          <WarningAmber
                            style={{
                              fontSize: 20,
                              marginBottom: 4,
                              marginRight: 5,
                              color: '#D7A950',
                            }}
                          />
                        )}
                        Ver dependentes
                      </Button>
                      {aprovacaoDetails?.associado
                        ?.dependente_aprovacao_pendente && (
                        <Typography
                          style={{
                            fontWeight: 400,
                            color: '#a8a8a8',
                            fontSize: 10,
                            marginLeft: 5,
                          }}
                        >
                          Alterações pendentes
                        </Typography>
                      )}
                    </Row>
                  )}
                </Box>
              </Row>
              <Row justifyContent="space-between" marginTop={5}>
                <Box marginBottom="auto" width="46%">
                  <RowsInfos
                    name="CPF"
                    value={maskBr.cpf(
                      selectedDependente.id
                        ? selectedDependente.cpf
                        : aprovacaoDetails?.associado?.cpf,
                    )}
                  />
                  <RowsInfos
                    mt={2}
                    name="RG"
                    value={
                      selectedDependente.id
                        ? selectedDependente?.rg
                        : aprovacaoDetails?.associado?.rg ?? '-'
                    }
                  />
                  <RowsInfos
                    mt={2}
                    name="Gênero"
                    value={
                      (selectedDependente.id
                        ? selectedDependente.sexo
                        : aprovacaoDetails?.associado?.sexo) === 'M'
                        ? 'Masculino'
                        : 'Feminino'
                    }
                  />
                  <RowsInfos
                    mt={2}
                    name="Data de nascimento"
                    value={getFormattedDate(
                      selectedDependente.id
                        ? selectedDependente.data_nascimento
                        : aprovacaoDetails?.associado?.data_nascimento,
                      'dd/MM/yyyy',
                    )}
                  />
                  {!selectedDependente.id ? (
                    <>
                      <RowsInfos
                        mt={2}
                        name="Email"
                        value={aprovacaoDetails?.associado?.email}
                      />
                      <RowsInfos
                        mt={2}
                        name="Celular"
                        value={aprovacaoDetails?.associado?.celular ?? '-'}
                      />
                    </>
                  ) : (
                    <RowsInfos
                      mt={2}
                      name="Grau de dependência"
                      value={
                        GRAU_DEPENDENTE.find(
                          item =>
                            String(item.value) ===
                            selectedDependente?.grau_parentesco,
                        )?.label || '-'
                      }
                    />
                  )}
                  {selectedDependente?.comprovante_dependente_url && (
                    <>
                      <Typography
                        style={{ fontSize: 18, fontWeight: 700, marginTop: 30 }}
                      >
                        Documento comprobatório
                      </Typography>
                      <Typography style={{ marginTop: 20 }}>
                        Frente do documento
                      </Typography>
                      <Box
                        maxWidth={150}
                        bgcolor="#F6F9FE"
                        padding={1}
                        borderRadius={12}
                        fontSize={13}
                        alignItems="center"
                        display="flex"
                        style={{ cursor: 'pointer' }}
                        onClick={() =>
                          setSelectedPhoto(
                            selectedDependente?.comprovante_dependente_url,
                          )
                        }
                      >
                        <ImageIcon
                          style={{ color: '#20496E', marginRight: 10 }}
                        />
                        {'  '}Ver imagem
                      </Box>
                    </>
                  )}
                  {!selectedDependente.id && (
                    <>
                      <Typography
                        style={{
                          fontSize: 16,
                          fontWeight: 600,
                          marginTop: 30,
                          marginBottom: 30,
                        }}
                      >
                        Endereço
                      </Typography>
                      {aprovacaoDetails?.associado.enderecos.length > 0 ? (
                        <>
                          <Row justifyContent="space-between">
                            <ColumnInfos
                              name="Rua"
                              value={
                                aprovacaoDetails?.associado.enderecos[0]
                                  .logradouro
                              }
                            />
                            <ColumnInfos
                              name="Número"
                              value={
                                aprovacaoDetails?.associado.enderecos[0].numero
                              }
                            />
                          </Row>
                          <ColumnInfos
                            mt={2}
                            name="Bairro"
                            value={
                              aprovacaoDetails?.associado?.enderecos[0]?.bairro
                            }
                          />
                          <Row mt={2} justifyContent="space-between">
                            <ColumnInfos
                              name="Cidade"
                              value={
                                aprovacaoDetails?.associado?.enderecos[0]
                                  ?.cidade
                              }
                            />
                            <ColumnInfos
                              name="Estado"
                              value={
                                aprovacaoDetails?.associado?.enderecos[0]?.uf
                              }
                            />
                            <ColumnInfos name="País" value={'Brasil'} />
                          </Row>
                          <ColumnInfos
                            mt={2}
                            name="CEP"
                            value={maskBr.cep(
                              aprovacaoDetails?.associado?.enderecos[0]?.cep,
                            )}
                          />
                        </>
                      ) : (
                        <Typography>
                          Associado não possuí endereço cadastrado
                        </Typography>
                      )}
                    </>
                  )}
                  {aprovacaoDetails?.associado.funcional_frente_id &&
                    !selectedDependente.id && (
                      <>
                        <Typography
                          style={{
                            fontSize: 18,
                            fontWeight: 700,
                            marginTop: 30,
                          }}
                        >
                          Funcional
                        </Typography>
                        <Typography style={{ marginTop: 20 }}>
                          Frente do documento
                        </Typography>
                        <Box
                          maxWidth={150}
                          bgcolor="#F6F9FE"
                          padding={1}
                          borderRadius={12}
                          fontSize={13}
                          alignItems="center"
                          display="flex"
                          style={{ cursor: 'pointer' }}
                          onClick={() =>
                            setSelectedPhoto(
                              aprovacaoDetails?.associado.funcional_frente_id,
                            )
                          }
                        >
                          <ImageIcon
                            style={{ color: '#20496E', marginRight: 10 }}
                          />
                          {'  '}Ver imagem
                        </Box>
                        <Typography style={{ marginTop: 20 }}>
                          Verso do documento
                        </Typography>
                        <Box
                          maxWidth={150}
                          bgcolor="#F6F9FE"
                          padding={1}
                          borderRadius={12}
                          fontSize={13}
                          alignItems="center"
                          display="flex"
                          style={{ cursor: 'pointer' }}
                          onClick={() =>
                            setSelectedPhoto(
                              aprovacaoDetails?.associado?.funcional_verso_id,
                            )
                          }
                        >
                          <ImageIcon
                            style={{ color: '#20496E', marginRight: 10 }}
                          />
                          {'  '}Ver imagem
                        </Box>
                      </>
                    )}
                </Box>
                <Box width="46%" marginBottom="auto">
                  <Form
                    onSubmit={handleSave}
                    initialValues={aprovacaoDetails}
                    render={({ handleSubmit }) => (
                      <form onSubmit={handleSubmit}>
                        {!selectedDependente.id && (
                          <>
                            <InputsLabels label="Posto Graduação" />
                            <InputText
                              showBox={false}
                              name="posto_graduacao"
                              defaultValue={
                                aprovacaoDetails?.associado?.postoGraduacao
                                  ?.nome
                              }
                              blocked
                            />
                            <InputsLabels label="Categoria" />
                            <InputText
                              showBox={false}
                              name="category"
                              defaultValue={
                                aprovacaoDetails?.associado?.categoria?.nome
                              }
                              blocked
                            />
                            <InputsLabels
                              label="Unidade"
                              isChange={
                                aprovacaoDetails.status_aprovacao ===
                                  STATUS_APROVACAO_PENDENTE &&
                                aprovacaoDetails?.associado
                                  ?.atualizacao_unidade_pendente_aprovacao
                              }
                            />
                            <InputText
                              showBox={false}
                              name="unidade"
                              defaultValue={
                                aprovacaoDetails?.associado?.unidade
                              }
                              blocked
                            />
                            {aprovacaoDetails.status_aprovacao ===
                              STATUS_APROVACAO_PENDENTE &&
                              aprovacaoDetails?.associado
                                ?.atualizacao_unidade_pendente_aprovacao && (
                                <Row style={{ marginBottom: 8 }}>
                                  <Button
                                    style={{
                                      backgroundColor: '#4AAC48',
                                      color: 'white',
                                      fontSize: 12,
                                      paddingLeft: 20,
                                      paddingRight: 20,
                                    }}
                                    startIcon={<CheckCircleOutline />}
                                    onClick={() =>
                                      setSelectedStatus(
                                        STATUS_APROVACAO_APROVADO,
                                      )
                                    }
                                  >
                                    Aprovar
                                  </Button>
                                  <Button
                                    style={{
                                      backgroundColor: '#D20E03',
                                      color: 'white',
                                      marginLeft: 10,
                                      fontSize: 12,
                                      paddingLeft: 20,
                                      paddingRight: 20,
                                    }}
                                    onClick={() =>
                                      setSelectedStatus(
                                        STATUS_APROVACAO_REPROVADO,
                                      )
                                    }
                                    startIcon={<HighlightOff />}
                                  >
                                    Reprovar
                                  </Button>
                                </Row>
                              )}
                          </>
                        )}

                        {selectedDependente?.id && (
                          <Box
                            style={{
                              padding: 20,
                              backgroundColor: '#F6F9FE',
                              borderRadius: 16,
                            }}
                          >
                            <Row>
                              <Avatar
                                style={{
                                  width: 77,
                                  height: 77,
                                  borderWidth: 3,
                                  borderColor: '#0C3D88',
                                  borderStyle: 'solid',
                                }}
                                src={aprovacaoDetails?.associado?.avatar_id}
                              />
                              <Box ml={2}>
                                <Typography
                                  style={{ color: '#20496E', fontWeight: 600 }}
                                >
                                  Titular
                                </Typography>
                                <Typography style={{ fontWeight: 500 }}>
                                  {aprovacaoDetails?.associado?.nome}
                                </Typography>

                                <Typography
                                  style={{
                                    color: '#20496E',
                                    fontWeight: 400,
                                    marginTop: 10,
                                    cursor: 'pointer',
                                  }}
                                  onClick={() => handleSelectDependente({})}
                                >
                                  Ver perfil do titular
                                </Typography>
                              </Box>
                            </Row>
                          </Box>
                        )}

                        <InputsLabels label="Nota" />
                        <InputText
                          showBox={false}
                          name="nota"
                          multiline
                          rows={6}
                          maxRows={6}
                        />
                        {selectedDependente?.id && (
                          <>
                            <InputsLabels label="Data de validade *" />

                            <Field
                              style={{ marginTop: 0 }}
                              name="data_validade"
                              type="date"
                              required
                              component={TextField}
                              defaultValue={
                                selectedDependente?.data_validade?.split('T')[0]
                              }
                              InputProps={{
                                inputProps: {
                                  min: new Date().toISOString().split('T')[0],
                                  type: 'date',
                                },
                              }}
                              label=""
                              fullWidth
                              variant="filled"
                            />
                          </>
                        )}
                        <InputsLabels
                          label="Status"
                          isChange={
                            aprovacaoDetails.status_aprovacao ===
                              STATUS_APROVACAO_PENDENTE &&
                            aprovacaoDetails?.associado
                              ?.atualizacao_unidade_pendente_aprovacao
                          }
                        />
                        <Select
                          value={selectedStatus}
                          onChange={({ target }) =>
                            setSelectedStatus(target.value)
                          }
                          variant="filled"
                          fullWidth
                        >
                          {aprovacoesSelect.map(item => (
                            <MenuItem key={item.value} value={item.value}>
                              {item.label}
                            </MenuItem>
                          ))}
                        </Select>
                        {aprovacaoDetails.status_aprovacao ===
                          STATUS_APROVACAO_PENDENTE && (
                          <Row justifyContent="space-between" mt={5}>
                            <div />
                            <Button
                              disabled={
                                selectedStatus === STATUS_APROVACAO_PENDENTE ||
                                saveLoading
                              }
                              style={{
                                backgroundColor:
                                  selectedStatus !==
                                    STATUS_APROVACAO_PENDENTE && '#4AAC48',
                                color:
                                  selectedStatus !==
                                    STATUS_APROVACAO_PENDENTE && 'white',
                                width: 150,
                              }}
                              type="submit"
                              data-cy="salvar"
                              startIcon={
                                saveLoading && (
                                  <CircularProgress size={20} color="white" />
                                )
                              }
                            >
                              Salvar
                            </Button>
                          </Row>
                        )}
                      </form>
                    )}
                  />
                </Box>
              </Row>
            </Box>
          </>
        ) : (
          <Box>
            <Skeleton height={150}></Skeleton>
            <Skeleton height={150}></Skeleton>
            <Skeleton height={150}></Skeleton>
            <Skeleton height={150}></Skeleton>
          </Box>
        )}
      </Paper>
    </Container>
  );
}

export default DetalhesAprovacao;
