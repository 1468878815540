import React, { lazy } from 'react';

// Icons
import DashboardIcon from '@material-ui/icons/Dashboard';
import PeopleIcon from '@material-ui/icons/People';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import ApartmentIcon from '@material-ui/icons/Apartment';
import HomeIcon from '@material-ui/icons/Home';
import DateRangeIcon from '@material-ui/icons/DateRange';
import BookIcon from '@mui/icons-material/Book';
// import FeedIcon from '@mui/icons-material/Feed';

import BlurOnIcon from '@material-ui/icons/BlurOn';
import FeaturedPlayListIcon from '@material-ui/icons/FeaturedPlayList';
import LabelImportantIcon from '@material-ui/icons/LabelImportant';

// Pages
import Login from 'pages/Login';
import ForgotPassword from 'pages/ForgotPassword';
import LoginPorEmail from 'pages/LoginPorEmail';
import MagicLogin from 'pages/MagicLogin';
import Resultados from 'pages/Resultados';
import {
  ADMIN_PROFILE,
  CADASTRO_PROFILE,
  DEFAULT_PROFILE,
  REPRESENTANTE_PROFILE,
} from 'utils/constants';
import { AssignmentTurnedIn, Search } from '@material-ui/icons';
import SorteiosDisponiveis from 'pages/Inscricoes';
import ForgotPasswordToken from 'pages/ForgotPasswordToken';
import ConsultarCodigo from 'pages/ConsultarCodigo';
import CadastroPorTelefone from 'pages/RenovaCadastroTelefone';
import ContatoPorTelefone from 'pages/ContatoPorTelefone';
import DetalheSorteio from 'components/DetalheSorteio/DetalheSorteio';
import AssociadoCadastrado from 'modules/AssociadoCadastrado/AssociadoCadastrado';
import DetalhesAprovacao from 'pages/Cadastro/DetalhesAprovacao';

// Logged Pages
const Dashboard = lazy(() => import('pages/Dashboard'));
const PostoGraduacao = lazy(() => import('pages/PostoGraduacao'));
const AssociadoDashboard = lazy(() => import('pages/AssociadoDashboard'));
const RepresentantePage = lazy(() =>
  import('pages/Representante/RepresentantePage'),
);
const Users = lazy(() => import('pages/Users'));
const Profile = lazy(() => import('pages/Profile'));
const Associados = lazy(() => import('pages/Associados'));
const GerenciarCadastro = lazy(() =>
  import('pages/Cadastro/GerenciarCadastro'),
);
const AprovarCadastro = lazy(() => import('pages/Cadastro/AprovarCadastro'));
const Colonias = lazy(() => import('pages/Colonias'));
const Associado = lazy(() => import('modules/Associado'));
const Campanhas = lazy(() => import('pages/Campanhas'));
const Campanha = lazy(() => import('modules/Campanha'));
const Notificacoes = lazy(() => import('pages/Notificacoes'));
const Colonia = lazy(() => import('modules/Colonia'));
const Categoria = lazy(() => import('pages/Categoria'));
const Sorteio = lazy(() => import('pages/Sorteio'));
const SorteioNovo = lazy(() => import('modules/SorteioNovo'));
const AssociadoSorteioTable = lazy(() => import('pages/AssociadoSorteioTable'));
const AssociadoSorteio = lazy(() => import('pages/AssociadoSorteio'));
const PeriodoSorteio = lazy(() => import('pages/PeriodoSorteio'));
const PeriodoSorteioNovo = lazy(() => import('modules/PeriodoSorteioNovo'));
const SubPeriodoSorteioNovo = lazy(() =>
  import('modules/SubPeriodoSorteioNovo'),
);
const ResultadosSorteio = lazy(() => import('pages/ResultadosSorteio'));
const SorteioLive = lazy(() => import('pages/SorteioLive'));
const Logs = lazy(() => import('pages/Logs'));

const config = {
  public: [
    {
      path: '/login',
      component: Login,
      exact: true,
    },
    {
      path: '/esqueci-minha-senha',
      component: ForgotPassword,
      exact: true,
    },
    {
      path: '/esqueci-minha-senha/:token',
      component: ForgotPasswordToken,
      exact: true,
    },
    {
      path: '/nova-senha/:token',
      component: ForgotPasswordToken,
      exact: true,
    },
    {
      path: '/login-por-email',
      component: LoginPorEmail,
      exact: true,
    },
    {
      path: '/cadastro-por-telefone',
      component: CadastroPorTelefone,
      exact: true,
    },
    {
      path: '/contato-por-telefone',
      component: ContatoPorTelefone,
      exact: true,
    },
    {
      path: '/magic-login/:token',
      component: MagicLogin,
      exact: true,
    },
    {
      path: '/consultar-codigo',
      component: ConsultarCodigo,
      exact: true,
    },
    {
      path: '/detalhe-sorteio',
      component: DetalheSorteio,
      exact: true,
    },
  ],
  protected: [
    {
      path: '/',
      component: AssociadoDashboard,
      title: 'Associado Dashboard',
      exact: true,
      icon: <DashboardIcon />,
      profiles: [DEFAULT_PROFILE],
    },
    {
      path: '/notificacoes',
      component: Notificacoes,
      title: 'Notificações Associado Web',
      exact: true,
      profiles: [DEFAULT_PROFILE],
    },
    {
      path: '/representante',
      title: 'Pesquisa',
      icon: <Search />,
      component: RepresentantePage,
      exact: true,
      profiles: [REPRESENTANTE_PROFILE],
      isSubMenu: true,
    },
    {
      path: '/',
      component: Dashboard,
      title: 'Dashboard',
      exact: true,
      icon: <DashboardIcon />,
      profiles: [ADMIN_PROFILE],
      isSubMenu: false,
    },
    {
      path: '/associados',
      component: Associados,
      title: 'Associados',
      exact: true,
      icon: <AccountBoxIcon />,
      profiles: [ADMIN_PROFILE],
      isSubMenu: false,
    },
    {
      path: '/categoria',
      component: Categoria,
      title: 'Categorias',
      exact: true,
      icon: <LabelImportantIcon />,
      profiles: [ADMIN_PROFILE],
      isSubMenu: false,
    },
    {
      path: '/postos',
      component: PostoGraduacao,
      title: 'Postos/Graduação',
      exact: true,
      icon: <ApartmentIcon />,
      profiles: [ADMIN_PROFILE],
      isSubMenu: false,
    },
    {
      path: '/associado/:id',
      component: Associado,
      title: 'Associado',
      exact: true,
      hideFromMenu: true,
    },
    {
      path: '/colonias',
      component: Colonias,
      title: 'Colônias',
      exact: true,
      icon: <HomeIcon />,
      profiles: [ADMIN_PROFILE],
      isSubMenu: false,
    },
    {
      path: '/campanhas',
      component: Campanhas,
      title: 'Campanhas',
      exact: true,
      icon: <DateRangeIcon />,
      profiles: [ADMIN_PROFILE],
      isSubMenu: false,
    },
    {
      path: '/logs',
      component: Logs,
      title: 'Logs',
      exact: true,
      icon: <BookIcon />,
      profiles: [ADMIN_PROFILE, CADASTRO_PROFILE],
      isSubMenu: true,
    },
    {
      path: '/campanha/:id',
      component: Campanha,
      title: 'Campanha',
      exact: true,
      hideFromMenu: true,
    },
    {
      path: '/colonias/:id',
      component: Colonia,
      title: 'Colônia',
      exact: true,
      hideFromMenu: true,
    },

    {
      path: '/sorteios',
      component: Sorteio,
      title: 'Sorteios',
      exact: true,
      icon: <BlurOnIcon />,
      profiles: [ADMIN_PROFILE],
      isSubMenu: false,
    },
    {
      path: '/sorteio',
      component: AssociadoSorteioTable,
      title: 'Associado Sorteio',
      exact: true,
      hideFromMenu: true,
      profiles: [DEFAULT_PROFILE, ADMIN_PROFILE],
    },
    {
      path: '/sorteio/new',
      component: SorteioNovo,
      title: 'Criar sorteio',
      exact: true,
      hideFromMenu: true,
      profiles: [ADMIN_PROFILE],
      isSubMenu: false,
    },
    {
      path: '/sorteio/:id',
      component: SorteioNovo,
      title: 'Sorteio',
      exact: true,
      hideFromMenu: true,
      profiles: [ADMIN_PROFILE],
      isSubMenu: false,
    },
    {
      path: '/sorteio/:id',
      component: AssociadoSorteio,
      title: 'Associado Sorteio Web',
      exact: true,
      hideFromMenu: true,
      profiles: [DEFAULT_PROFILE, ADMIN_PROFILE],
    },
    {
      path: '/periodo-sorteio',
      component: PeriodoSorteio,
      title: 'Periodos Sorteios',
      exact: true,
      hideFromMenu: true,
      icon: <HomeIcon />,
    },
    {
      path: '/periodo-sorteio/new',
      component: PeriodoSorteioNovo,
      title: 'Periodo Sorteio',
      exact: true,
      hideFromMenu: true,
    },
    {
      path: '/periodo-sorteio/:id',
      component: PeriodoSorteioNovo,
      title: 'Periodo Sorteio',
      exact: true,
      hideFromMenu: true,
    },
    {
      path: '/periodo-sorteio/sub-periodo/:id',
      component: SubPeriodoSorteioNovo,
      title: 'Sub Período',
      exact: true,
      hideFromMenu: true,
    },
    {
      path: '/sorteio/:id/resultados',
      component: Resultados,
      title: 'Resultados Sorteio',
      exact: true,
      hideFromMenu: true,
    },
    {
      path: '/sorteio/:id/resultado-live',
      component: SorteioLive,
      title: 'Resultado Live',
      exact: true,
      hideFromMenu: true,
    },
    {
      path: '/inscricoes',
      component: SorteiosDisponiveis,
      title: 'Inscrições',
      icon: <AssignmentTurnedIn />,
      exact: true,
      profiles: [ADMIN_PROFILE],
    },
    {
      path: '/resultados',
      component: ResultadosSorteio,
      title: 'Resultados',
      exact: true,
      icon: <FeaturedPlayListIcon />,
      profiles: [ADMIN_PROFILE],
      isSubMenu: false,
    },
    {
      path: '/users',
      component: Users,
      title: 'Usuários',
      exact: true,
      icon: <PeopleIcon />,
      profiles: [ADMIN_PROFILE],
      isSubMenu: false,
    },
    {
      path: '/manage-subscription',
      component: GerenciarCadastro,
      title: 'Gerenciar Cadastro',
      exact: true,
      icon: <AccountBoxIcon />,
      profiles: [CADASTRO_PROFILE],
      isSubMenu: true,
    },
    {
      path: '/approve-subscription',
      component: AprovarCadastro,
      title: 'Aprovação de cadastro',
      exact: true,
      icon: <AccountBoxIcon />,
      profiles: [CADASTRO_PROFILE],
      isSubMenu: true,
    },
    {
      path: '/approve-subscription/:id',
      component: DetalhesAprovacao,
      title: 'Detalhes Aprovação',
      exact: true,
      profiles: [CADASTRO_PROFILE],
      hideFromMenu: true,
    },
    {
      path: '/associado-cadastrado/:id',
      component: AssociadoCadastrado,
      title: 'Editar Cadastro',
      exact: true,
      icon: <AccountBoxIcon />,
      profiles: [CADASTRO_PROFILE],
      hideFromMenu: true,
    },
    {
      path: '/profile',
      component: Profile,
      title: 'Perfil',
      exact: true,
      icon: <AccountCircleIcon />,
      profiles: [DEFAULT_PROFILE, ADMIN_PROFILE],
    },
    {
      path: '/profile',
      component: Profile,
      title: 'Perfil',
      exact: true,
      icon: <AccountCircleIcon />,
      profiles: [CADASTRO_PROFILE],
      isSubMenu: true,
    },
  ],
};

export default config;
