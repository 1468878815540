import React from 'react';

import AVMLogo from 'images/avm-logo-200.png';
import { Link as RouterLink } from 'react-router-dom';
import Centered from 'components/Centered';
import LoginForm from 'forms/LoginForm';
import { FormContainer } from 'styles/global';
import { useAuth } from 'contexts/auth';
import { Box, Button, Paper, Typography } from '@material-ui/core';
import { useHistory, useLocation } from 'react-router-dom';
import ExibeIrregularDialog from 'dialogs/ExibeIrregularDialog';
import { AiOutlineArrowLeft } from 'react-icons/ai';
import CadastroBloqueadoDialog from 'dialogs/CadastroBloqueado';

function Login() {
  const history = useHistory();
  const location = useLocation();
  const { signIn, isIrregularOrDuplicado, isBlockedAccess } = useAuth();
  const { from } = location.state || { from: { pathname: '/' } };

  const onSubmit = async values => {
    await signIn(values);

    history.replace(from);
  };

  return (
    <>
      <ExibeIrregularDialog open={isIrregularOrDuplicado} />
      <CadastroBloqueadoDialog open={isBlockedAccess} />
      <Paper
        style={{
          maxWidth: 360,
          width: '100%',
          margin: 'auto',
          position: 'relative',
        }}
      >
        <Box position="absolute" top="10px" left="10px">
          <RouterLink to="/login-por-email">
            <Button
              style={{ textTransform: 'lowercase' }}
              startIcon={<AiOutlineArrowLeft />}
            >
              Voltar
            </Button>
          </RouterLink>
        </Box>

        <Centered column>
          <img
            draggable={false}
            style={{ width: 100, marginBottom: 20 }}
            src={AVMLogo}
            alt="AVM Logo"
          />

          <Typography
            variant="body1"
            color="textSecondary"
            mb={2}
            align="center"
            style={{
              fontSize: '12px',
              lineHeight: '18px',
              margin: '24px 0',
            }}
          >
            Preencha com seu <strong>e-mail e senha pré cadastrados</strong> na
            plataforma, caso não lembre ou não possua cadastro, entre em contato
            com a equipe AVM
          </Typography>

          <FormContainer>
            <LoginForm onSubmit={onSubmit} />
          </FormContainer>
        </Centered>
      </Paper>
    </>
  );
}

export default Login;
