import React, { useEffect, useState } from 'react';
import { Grid, Box } from '@material-ui/core';
import Button from '@material-ui/core/Button';

import { validate } from 'utils/validate';
import { schema } from './schema';
import { AutocompleteField } from '@inovando/react-components';
import { Form, Field } from 'react-final-form';
import createDecorator from 'final-form-focus';

import api from 'services/api';

const focusOnErrors = createDecorator();

function SearchAssociadosSorteio({
  onSubmit,
  initialValues = {},
  atResultadosPage,
}) {
  const [categorias, setCategorias] = useState([]);

  const [associados, setAssociados] = useState([]);
  const [unidades, setUnidades] = useState([]);
  const [postosGraduacoes, setPostosGraduacoes] = useState([]);
  const [campanhas, setCampanhas] = useState([]);
  const [sorteios, setSorteios] = useState([]);
  const [periodosSorteio, setPeriodosSorteio] = useState([]);
  const [subPeriodos, setSubPeriodos] = useState([]);

  async function getAssociados(q) {
    const { data } = await api.get('associados', {
      params: {
        q,
        perPage: 'all',
      },
    });

    setAssociados(data.data);
  }

  async function getUnidades(q) {
    const { data } = await api.get(`unidades`, {
      params: {
        nome: q,
        perPage: 'all',
      },
    });

    setUnidades(data.data);
  }

  async function getPostosGraduacoes(q) {
    const { data } = await api.get('postos', { params: { q } });

    setPostosGraduacoes(data.data);
  }

  async function getCategorias(q) {
    const { data } = await api.get('categoria/users', {
      params: { q, all: true },
    });

    setCategorias(data);
  }

  async function getCampanhas(q) {
    const { data } = await api.get(`campanhas`, {
      params: {
        q,
        all: true,
      },
    });

    setCampanhas(data);
  }

  async function getSorteiosByCampanha({ campanha_id, q }) {
    if (!campanha_id) return;
    const { data } = await api.get(`sorteios`, {
      params: {
        campanha_id,
        q,
        all: true,
      },
    });

    setSorteios(data);
  }

  async function getPeriodosBySorteio({ sorteio_id_selected, q }) {
    if (!sorteio_id_selected) return;
    const { data } = await api.get(`sorteios/${sorteio_id_selected}/periodos`, {
      params: { q, all: true },
    });

    setPeriodosSorteio(data.data);
  }

  async function getSubPeriodosByPeriodo({ periodo_id, q }) {
    if (!periodo_id) return;

    const params = { q, periodo_id, all: true };
    const { data } = await api.get(`sub-periodos`, { params });

    setSubPeriodos(data);
  }

  useEffect(() => {
    (async () => {
      getAssociados();
      getUnidades();
      getPostosGraduacoes();
      getCategorias();
      getCampanhas();
      getSorteiosByCampanha({ campanha_id: initialValues.campanha });
      getPeriodosBySorteio({ sorteio_id_selected: initialValues.sorteio });
    })();
  }, []);

  const STATUS_RESULTADO = [
    { id: 1, label: 'Ganhou' },
    { id: 3, label: 'Suplente' },
    { id: 2, label: 'Não contemplado' },
  ];

  return (
    <Box m={1}>
      <Form
        onSubmit={onSubmit}
        decorators={[focusOnErrors]}
        mutators={{
          setFieldValue: ([field, value], state, { changeValue }) => {
            changeValue(state, field, () => value);
          },
        }}
        subscription={{ submitting: true, dirty: true, values: true }}
        validate={validate(schema)}
        initialValues={initialValues}
        render={({ handleSubmit, form, submitting, dirty, values }) => (
          <form onSubmit={handleSubmit} noValidate>
            <Grid container spacing={1}>
              <Grid item xs={6} sm={3}>
                <Field
                  name="campanha"
                  component={AutocompleteField}
                  disabled={!atResultadosPage}
                  options={campanhas}
                  label="Campanha"
                  fullWidth
                  variant="outlined"
                  labelAttr="titulo"
                  valueAttr="id"
                  kind="value"
                  onSearch={getCampanhas}
                  handleChange={value => {
                    if (!value) {
                      form.mutators.setFieldValue('sorteio', null);
                    }
                    getSorteiosByCampanha({ campanha_id: value });
                  }}
                />
              </Grid>

              <Grid item xs={6} sm={3}>
                <Field
                  name="sorteio"
                  component={AutocompleteField}
                  disabled={!atResultadosPage || !values.campanha}
                  options={sorteios}
                  label="Sorteio"
                  fullWidth
                  variant="outlined"
                  labelAttr="nome"
                  valueAttr="id"
                  kind="value"
                  onSearch={q =>
                    getSorteiosByCampanha({ campanha_id: values.campanha, q })
                  }
                  handleChange={value => {
                    console.log(value);
                    getPeriodosBySorteio({ sorteio_id_selected: value });
                  }}
                />
              </Grid>

              <Grid item xs={6} sm={3}>
                <Field
                  name="periodo_sorteio"
                  component={AutocompleteField}
                  disabled={
                    atResultadosPage && (!values.campanha || !values.sorteio)
                  }
                  options={periodosSorteio}
                  label="Período"
                  fullWidth
                  variant="outlined"
                  labelAttr="nome"
                  valueAttr="id"
                  kind="value"
                  onSearch={q =>
                    getPeriodosBySorteio({
                      sorteio_id_selected: values.sorteio,
                      q,
                    })
                  }
                  handleChange={value => {
                    getSubPeriodosByPeriodo({ periodo_id: value });
                  }}
                />
              </Grid>

              <Grid item xs={6} sm={3}>
                <Field
                  name="sub_periodo_sorteio"
                  component={AutocompleteField}
                  disabled={
                    atResultadosPage &&
                    (!values.campanha ||
                      !values.sorteio ||
                      !values.periodo_sorteio)
                  }
                  options={subPeriodos}
                  label="Data(s) de ocupação"
                  fullWidth
                  variant="outlined"
                  labelAttr="nome"
                  valueAttr="id"
                  kind="value"
                  onSearch={q =>
                    getSubPeriodosByPeriodo({
                      periodo_id: values.periodo_sorteio,
                      q,
                    })
                  }
                />
              </Grid>

              <Grid item xs={6} sm={3}>
                <Field
                  name="associado"
                  component={AutocompleteField}
                  disabled={
                    atResultadosPage && (!values.campanha || !values.sorteio)
                  }
                  options={associados}
                  label="Associado"
                  fullWidth
                  variant="outlined"
                  valueAttr="id"
                  labelAttr="nome"
                  kind="value"
                />
              </Grid>

              <Grid item xs={6} sm={3}>
                <Field
                  name="categoria"
                  component={AutocompleteField}
                  disabled={
                    atResultadosPage && (!values.campanha || !values.sorteio)
                  }
                  options={categorias}
                  data-cy="Categoria"
                  label="Categoria"
                  fullWidth
                  variant="outlined"
                  labelAttr="nome"
                  valueAttr="id"
                  kind="value"
                />
              </Grid>

              <Grid item xs={6} sm={3}>
                <Field
                  name="posto"
                  component={AutocompleteField}
                  disabled={
                    atResultadosPage && (!values.campanha || !values.sorteio)
                  }
                  options={postosGraduacoes}
                  label="Posto/Graduação"
                  fullWidth
                  variant="outlined"
                  labelAttr="nome"
                  valueAttr="id"
                  kind="value"
                />
              </Grid>

              <Grid item xs={6} sm={3}>
                <Field
                  name="status_sorteio"
                  component={AutocompleteField}
                  disabled={
                    atResultadosPage && (!values.campanha || !values.sorteio)
                  }
                  options={STATUS_RESULTADO}
                  label="Ganhou"
                  fullWidth
                  variant="outlined"
                  labelAttr="label"
                  valueAttr="id"
                  kind="value"
                />
              </Grid>

              <Grid item xs={6} sm={3}>
                <Field
                  name="unidade"
                  component={AutocompleteField}
                  disabled={
                    atResultadosPage && (!values.campanha || !values.sorteio)
                  }
                  options={unidades}
                  label="Unidade"
                  fullWidth
                  variant="outlined"
                  valueAttr="id"
                  labelAttr="nome"
                  kind="value"
                  onSearch={getUnidades}
                />
              </Grid>
            </Grid>

            <Grid container spacing={1} justify="flex-end">
              <Grid item xs={2} sm={2}>
                <Box mt={2}>
                  <Button
                    disabled={submitting || !dirty}
                    fullWidth
                    variant="outlined"
                    color="secondary"
                    onClick={() => {
                      form.reset();
                      onSubmit();
                    }}
                  >
                    Limpar
                  </Button>
                </Box>
              </Grid>

              <Grid item xs={2} sm={2}>
                <Box mt={2}>
                  <Button
                    disabled={
                      submitting ||
                      !dirty ||
                      !values.sorteio ||
                      !values.campanha
                    }
                    fullWidth
                    variant="contained"
                    color="primary"
                    type="submit"
                  >
                    Filtrar
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </form>
        )}
      />
    </Box>
  );
}

export default SearchAssociadosSorteio;
