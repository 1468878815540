import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  head: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
  },
  boxThumb: {
    paddingLeft: theme.spacing(2),
  },
  card: {
    height: '100px',
  },
  ativo: {
    color: 'green',
  },
  inativo: {
    color: 'red',
  },
  irregular: {
    color: 'orange',
  },
  foto: {
    fontWeight: 'bold',
    fontSize: '16px',
  },
}));
