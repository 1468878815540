import React, { useState } from 'react';

import { Form, Field } from 'react-final-form';
import createDecorator from 'final-form-focus';
import { Checkbox, TextField } from 'final-form-material-ui';

import { validate } from 'utils/validate';
import { getFormattedDate } from 'utils/transform';
import { schema } from './schema';
import {
  FormControlLabel,
  Grid,
  Typography,
  List,
  ListItemText,
} from '@material-ui/core';
import { format } from 'utils/form';
import { FieldArray } from 'react-final-form-arrays';
import arrayMutators from 'final-form-arrays';

const focusOnErrors = createDecorator();

function ConfirmParticipacaoForm({
  onSubmit,
  footer = () => {},
  associado = {},
  initialValues = {},
  telefones,
  periodos = [],
}) {
  const [showConfirmation, setShowConfirmation] = useState();
  const cpfMask = format('XXX.XXX.XXX-XX');

  return (
    <Form
      onSubmit={values => {
        if (showConfirmation) {
          onSubmit(values);

          return;
        }

        setShowConfirmation(true);
      }}
      decorators={[focusOnErrors]}
      mutators={arrayMutators}
      validate={validate(schema)}
      initialValues={{
        ...initialValues,
        associado_email: associado.email,
        associado_telefones: telefones,
      }}
      render={props => (
        <form onSubmit={props.handleSubmit} noValidate>
          {!showConfirmation ? (
            <>
              {periodos.map((periodo, index) => {
                const { subPeriodos } = periodo;

                return (
                  <List key={index}>
                    <ListItemText key={periodo.id}>
                      <Typography style={{ fontSize: '18px' }}>
                        <b>{periodo.nome}: </b>
                      </Typography>
                    </ListItemText>

                    <Grid item xs={12}>
                      {subPeriodos?.map(subperiodo => {
                        const subperiodoDataInicio = getFormattedDate(
                          subperiodo.data_inicio,
                        );
                        const subperiodoDataFinal = getFormattedDate(
                          subperiodo.data_fim,
                        );
                        return (
                          <ListItemText key={subperiodo.id}>
                            - <b>{subperiodo.nome}: </b> {subperiodoDataInicio}{' '}
                            - {subperiodoDataFinal}
                          </ListItemText>
                        );
                      })}
                    </Grid>
                  </List>
                );
              })}
              <FormControlLabel
                control={
                  <Field
                    type="checkbox"
                    name="recibo_impressao"
                    component={Checkbox}
                    color="primary"
                  />
                }
                label="Gerar recibo para impressão"
              />
            </>
          ) : (
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="h6" style={{ fontSize: 16 }}>
                  Confirmar dados do associado:
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Field
                  name="associado_email"
                  component={TextField}
                  label="E-mail"
                  variant="outlined"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <FieldArray name="associado_telefones">
                  {({ fields }) => (
                    <>
                      {fields.map((name, index) => (
                        <Field
                          key={name}
                          name={`${name}.telefone`}
                          label={`Telefone #${index + 1}`}
                          component={TextField}
                          margin="normal"
                          fullWidth
                          variant="outlined"
                        />
                      ))}
                    </>
                  )}
                </FieldArray>
              </Grid>
              <Grid item container xs={12} spacing={2}>
                <Grid item xs={12}>
                  <Typography variant="overline">CPF:</Typography>
                  <Typography>{cpfMask.format(associado.cpf)}</Typography>
                </Grid>
                {''}
                <Grid item xs={12}>
                  <Typography variant="h5" style={{ fontSize: 14 }}>
                    Ao me inscrever no sorteio, concordo com a divulgação do meu
                    nome para fins de publicação do resultado.
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          )}
          {footer(props)}
        </form>
      )}
    />
  );
}

export default ConfirmParticipacaoForm;
