import React, { useState, useRef, useEffect } from 'react';
import { toast } from 'react-toastify';

import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableContainer,
  Box,
  IconButton,
  Button,
  Grid,
  Typography,
  Avatar,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
} from '@material-ui/core';
import { TextField } from 'final-form-material-ui';
import { Field, Form } from 'react-final-form';
import createDecorator from 'final-form-focus';

import DeleteIcon from '@material-ui/icons/DeleteOutline';
import EditIcon from '@material-ui/icons/EditOutlined';

import { getFormattedDate } from 'utils/transform';
import { AutocompleteField, DateField } from '@inovando/react-components';
import ImagemAssociado from 'components/Associado/ImagemAssociado/ImagemAssociado';

import { format } from 'utils/form';
import api from 'services/api';
import { MdOutlineClose } from 'react-icons/md';
import { useStyles } from 'dialogs/RemoverInscricaoConfirmarDialog';
import {
  GRAU_DEPENDENTE,
  PERMISSION_ADICIONAR_DEPENDENTE_ASSOCIADO,
  PERMISSION_EDITAR_DEPENDENTE_ASSOCIADO,
  PERMISSION_EDITAR_FOTO_ASSOCIADO,
  PERMISSION_EDITAR_FOTO_DEPENDENTE,
  PERMISSION_EXCLUIR_DEPENDENTE_ASSOCIADO,
  PERMISSION_REMOVER_FOTO_ASSOCIADO,
  PERMISSION_REMOVER_FOTO_DEPENDENTE,
} from 'utils/constants';
import SelectField from 'components/SelectField';
import PermissionedComponent from 'components/PermissionedComponent';
import { isUserAllowed } from 'utils/permissions';
import { useAuth } from 'contexts/auth';

function Dependentes({
  dependentes = [],
  handleSelectDependente = () => {},
  selectedDependente,
  associadoId,
  handleUpdateData,
}) {
  const { user } = useAuth();

  const [selectedDependenteData, setSelectedDependenteData] = useState({});
  const [selectedDeleteDependente, setSelectedDeleteDependente] = useState({});
  const [selectedImage, setSelectedImage] = useState('');
  const [confirmDialog, setConfirmDialog] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);

  const fileInputRef = useRef();
  const focusOnErrors = createDecorator();

  const classes = useStyles();

  const handleSubmitPhoto = async photo => {
    try {
      const allowedTypes = [
        'image/jpeg',
        'image/jpg',
        'image/png',
        'image/gif',
      ];

      if (photo) {
        if (!allowedTypes.includes(photo.type)) {
          toast.error(
            'Tipo de arquivo inválido. Por favor, selecione uma imagem.',
          );
          return;
        }
      }
      if (selectedDependenteData.id !== 1) {
        if (photo) {
          const formData = new FormData();
          formData.append('file', photo);

          await api.post(
            `dependentes/image/${selectedDependenteData.id}`,
            formData,
          );
        } else {
          await api.post(`dependentes/image/${selectedDependenteData.id}`, {
            file: null,
          });
        }
        toast.success('Foto atualizada com sucesso !');
      }

      handleUpdateData();
      setSelectedImage(photo);
    } catch (error) {
      toast.error('Ocorreu um erro ao atualizar a foto deste associado.');
    }
  };

  const onSubmit = async e => {
    try {
      e.avatar_id = e.avatar;
      delete e.avatar;

      if (e.id !== 1 && e.id !== undefined) {
        await api.put(`/dependentes/${e.id}`, {
          ...e,
        });
      } else {
        delete e.id;
        e.associado_id = associadoId;
        e.data_inclusao = new Date();
        const formData = new FormData();
        formData.append('file', selectedImage);

        const { data: response } = await api.post('/dependentes', { ...e });

        if (selectedImage) {
          await api.post(`dependentes/image/${response.data.id}`, formData);
        }
      }

      toast.success('Dependente atualizado com sucesso !');
      handleSelectDependente();
      setSelectedDependenteData({});
      handleUpdateData();
    } catch (err) {
      toast.error('Ocorreu um erro ao atualizar o dependente.');
    }
  };

  const handleDeleteDependente = async () => {
    try {
      setLoadingDelete(true);
      await api.delete(`/dependentes/${selectedDeleteDependente.id}`);
      handleUpdateData();
      toast.success('Dependente deletado com sucesso !');
    } catch (error) {
      toast.error('Ocorreu um erro ao deletar dependente.');
    } finally {
      setLoadingDelete(false);
      setConfirmDialog(false);
    }
  };

  const selectDependent = () => {
    setSelectedDependenteData({});
    handleSelectDependente();
  };

  const closeDialog = () => {
    setConfirmDialog(false);
  };

  const unselectDependente = () => {
    handleSelectDependente();
    setSelectedDependenteData({ id: 1 });
  };

  const deleteSelectDependente = dependente => {
    setSelectedDeleteDependente(dependente);
    setConfirmDialog(true);
  };

  const selectDepedente = dependente => {
    setSelectedDependenteData({
      ...dependente,
      created_at: new Date(dependente.created_at).toISOString().split('T')[0],
    });
    handleSelectDependente();
  };

  useEffect(() => {
    setSelectedImage('');
  }, [selectedDependente]);

  return (
    <Box mb={4}>
      <Dialog
        fullWidth
        onClose={closeDialog}
        open={confirmDialog}
        classes={{
          paper: classes.dialogScrollpaper,
        }}
      >
        <Box display="flex" justifyContent="space-between">
          <DialogTitle>
            <Typography className={classes.mainText}>Atenção !</Typography>
          </DialogTitle>
          <Button
            onClick={closeDialog}
            color="##000000"
            endIcon={<MdOutlineClose />}
          ></Button>
        </Box>

        <DialogContent className={classes.content}>
          <Typography className={classes.subText}>
            Você tem certeza que deseja excluir este dependente ?
          </Typography>
        </DialogContent>

        <DialogActions className={classes.actions}>
          <Box width="100%" display="flex">
            <Button
              disabled={loadingDelete}
              className={classes.primaryButton}
              onClick={handleDeleteDependente}
            >
              Sim
            </Button>
            <Button
              disabled={loadingDelete}
              className={classes.cancelButton}
              onClick={closeDialog}
            >
              Cancelar
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
      {!selectedDependente ? (
        <TableContainer>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Nome</TableCell>
                <TableCell>Grau Dependência</TableCell>
                <TableCell>Data de Inclusão</TableCell>
                {isUserAllowed(
                  user,
                  PERMISSION_EDITAR_DEPENDENTE_ASSOCIADO,
                  PERMISSION_EXCLUIR_DEPENDENTE_ASSOCIADO,
                ) ? (
                  <TableCell>Ações</TableCell>
                ) : null}
                <TableCell>
                  <Box ml={1}>
                    <PermissionedComponent
                      permission={PERMISSION_ADICIONAR_DEPENDENTE_ASSOCIADO}
                    >
                      <Button
                        onClick={unselectDependente}
                        variant="contained"
                        color="primary"
                        data-cy="adicionar-dependente"
                      >
                        + Adicionar Dependente
                      </Button>
                    </PermissionedComponent>
                  </Box>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {dependentes.map(dependente => (
                <TableRow key={dependente.id}>
                  <TableCell>{dependente.nome}</TableCell>
                  <TableCell>
                    {
                      GRAU_DEPENDENTE.find(
                        item =>
                          String(item.value) === dependente.grau_parentesco,
                      )?.label
                    }
                  </TableCell>
                  <TableCell>
                    {getFormattedDate(dependente.data_inclusao, 'dd/MM/yyyy')}
                  </TableCell>
                  <TableCell>
                    <PermissionedComponent
                      permission={PERMISSION_EDITAR_DEPENDENTE_ASSOCIADO}
                    >
                      <IconButton
                        aria-label="edit"
                        color="primary"
                        onClick={() => selectDepedente(dependente)}
                      >
                        <EditIcon fontSize="small" />
                      </IconButton>
                    </PermissionedComponent>
                    <PermissionedComponent
                      permission={PERMISSION_EXCLUIR_DEPENDENTE_ASSOCIADO}
                    >
                      <IconButton
                        aria-label="delete"
                        color="secondary"
                        onClick={() => deleteSelectDependente(dependente)}
                      >
                        <DeleteIcon fontSize="small" />
                      </IconButton>
                    </PermissionedComponent>
                  </TableCell>
                  <TableCell />
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <Box>
          <Typography
            style={{
              fontSize: 16,
              fontWeight: 'bold',
              marginTop: 20,
              marginBottom: 20,
            }}
          >
            Foto
          </Typography>
          <Box display="flex" flexDirection="row" alignItems="center">
            {(!selectedDependenteData?.avatar && !selectedImage) ||
            typeof selectedImage === 'undefined' ? (
              <ImagemAssociado />
            ) : (
              <Avatar
                style={{
                  width: 160,
                  height: 160,
                  objectFit: 'cover',
                  marginBottom: '2em',
                  borderRadius: '8px',
                }}
                src={
                  selectedImage
                    ? URL.createObjectURL(selectedImage)
                    : selectedDependenteData?.avatar
                }
                alt="User Photo"
              />
            )}
            <Box
              display="flex"
              justifyContent="flex-start"
              style={{ marginTop: 'auto', marginBottom: 50 }}
            >
              {isUserAllowed(
                user,
                PERMISSION_REMOVER_FOTO_ASSOCIADO,
                PERMISSION_REMOVER_FOTO_DEPENDENTE,
              ) && (
                <Button
                  color="primary"
                  startIcon={<DeleteIcon />}
                  onClick={() => handleSubmitPhoto()}
                >
                  Excluir
                </Button>
              )}

              <Box ml={1}>
                {isUserAllowed(
                  user,
                  PERMISSION_EDITAR_FOTO_DEPENDENTE,
                  PERMISSION_EDITAR_FOTO_ASSOCIADO,
                ) && (
                  <Button
                    disabled={dependentes.avatar_id}
                    variant="contained"
                    color="primary"
                    type="submit"
                    onClick={() => fileInputRef.current.click()}
                  >
                    <input
                      type="file"
                      ref={fileInputRef}
                      style={{ display: 'none' }}
                      onChange={event =>
                        handleSubmitPhoto(event.target.files[0])
                      }
                    />
                    + Adicionar Nova
                  </Button>
                )}
              </Box>
            </Box>
          </Box>
          <Form
            onSubmit={onSubmit}
            decorators={[focusOnErrors]}
            initialValues={{ data_nascimento: null, ...selectedDependenteData }}
            render={props => {
              const { handleSubmit } = props;
              return (
                <form onSubmit={handleSubmit} noValidate>
                  <Typography
                    style={{
                      fontSize: 16,
                      fontWeight: 'bold',
                      marginTop: 20,
                      marginBottom: 20,
                    }}
                  >
                    Dados pessoais
                  </Typography>
                  <Grid container spacing={2}>
                    <Grid item xs={4}>
                      <Field
                        name="nome"
                        autoFocus
                        type="text"
                        component={TextField}
                        label="Nome completo"
                        margin="normal"
                        fullWidth
                        variant="outlined"
                        data-cy="nome"
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <Field
                        name="rg"
                        autoFocus
                        type="text"
                        component={TextField}
                        label="RG"
                        margin="normal"
                        fullWidth
                        variant="outlined"
                        data-cy="registro-nacional"
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <Field
                        name="cpf"
                        autoFocus
                        type="text"
                        component={TextField}
                        label="CPF"
                        {...format('XXX.XXX.XXX-XX')}
                        margin="normal"
                        fullWidth
                        variant="outlined"
                        data-cy="cpf"
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item xs={4}>
                      <Field
                        name="data_nascimento"
                        type="text"
                        component={DateField}
                        margin="normal"
                        label="Data de nascimento"
                        // defaultValue=" "
                        fullWidth
                        variant="outlined"
                        data-cy="data_nascimento"
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <Field
                        name="grau_parentesco"
                        autoFocus
                        type="text"
                        component={SelectField}
                        options={GRAU_DEPENDENTE}
                        label="Grau de dependência"
                        margin="normal"
                        fullWidth
                        variant="outlined"
                        data-cy="grau_parentesco"
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <Field
                        style={{ marginTop: 5 }}
                        name="sexo"
                        component={AutocompleteField}
                        options={[
                          {
                            id: 'M',
                            nome: 'Masculino',
                          },
                          {
                            id: 'F',
                            nome: 'Feminino',
                          },
                        ]}
                        label="Gênero"
                        fullWidth
                        variant="outlined"
                        labelAttr="nome"
                        valueAttr="id"
                        kind="value"
                      />
                    </Grid>
                    <Grid container spacing={2} style={{ marginTop: 20 }}>
                      <Button
                        style={{ marginLeft: 8 }}
                        color="primary"
                        type="button"
                        onClick={selectDependent}
                      >
                        Cancelar
                      </Button>
                      <Button
                        variant="contained"
                        color="primary"
                        type="submit"
                        style={{ marginLeft: 20 }}
                        data-cy="salvar-alteracoes"
                      >
                        Salvar alterações
                      </Button>
                    </Grid>
                  </Grid>
                </form>
              );
            }}
          />
        </Box>
      )}
    </Box>
  );
}

export default Dependentes;
