import React from 'react';
import { Box, InputAdornment, withStyles } from '@material-ui/core';
import { TextField } from 'final-form-material-ui';
import { Field } from 'react-final-form';

const CustomStyled = withStyles(() => ({
  root: {
    '& .MuiOutlinedInput-root': {
      border: 0,
      paddingLeft: '8px',
      paddingRight: '8px',

      '&.Mui-error': {
        border: '1px solid red',
      },
    },
    '& .MuiInputBase-formControl': {
      backgroundColor: '#F0F0F0',
      color: '#262626',
    },
    '& .MuiInputLabel-formControl': {
      fontWeight: 700,
      fontSize: '12px',
      lineHeight: '150%',
      color: '#000000',
      marginBottom: '3px',
    },
    border: 'none',
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none',
      borderRadius: '4px',
    },
    '& .MuiInputLabel-marginDense': {
      position: 'relative',
      transform: 'inherit',
    },
  },
}))(TextField);

export default function InputText({
  name,
  Icon,
  disabled,
  blocked,
  InputProps,
  showBox = true,
  ...rest
}) {
  return (
    <Field
      name={name}
      component={CustomStyled}
      disabled={disabled || blocked}
      InputProps={{
        ...InputProps,
        startAdornment: (
          <InputAdornment position="start">
            {showBox && (
              <Box
                color={disabled ? 'gray' : 'red'}
                bgcolor="white"
                width="28px"
                height="28px"
                padding="4px"
                display="flex"
                alignItems="center"
                justifyContent="center"
                borderRadius="5px"
              >
                {Icon && <Icon />}
              </Box>
            )}
          </InputAdornment>
        ),
      }}
      fullWidth
      margin="normal"
      size="small"
      variant="outlined"
      {...rest}
    />
  );
}
