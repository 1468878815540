/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { Form, Field } from 'react-final-form';
import createDecorator from 'final-form-focus';
import { TextField } from 'final-form-material-ui';

import { validate } from 'utils/validate';
import { format } from 'utils/form';
import { AutocompleteField, DateField } from '@inovando/react-components';
import { Box, Button, Grid, Typography, makeStyles } from '@material-ui/core';
import { FieldArray } from 'react-final-form-arrays';
import arrayMutators from 'final-form-arrays';
import { states } from './states';
import api from 'services/api';
import { schema } from './schema';
import { toast } from 'react-toastify';
import { addHours } from 'date-fns';

const focusOnErrors = createDecorator();

const useStyles = makeStyles(() => ({
  title: {
    fontWeight: 'bold',
    fontSize: '16px',
    marginBottom: '20px',
  },
}));

function AssociadoCadastroForm({
  footer = () => {},
  initialValues = {},
  handleUpdateData,
  isDisabled = false,
}) {
  const [trueInitialValues, setTrueInitialValues] = useState({});
  const [postos, setPostos] = useState([]);

  const classes = useStyles();

  const getPostos = async () => {
    const { data } = await api.get(`postos`, { params: { all: true } });
    setPostos(data);
  };

  useEffect(() => {
    if (initialValues?.id) {
      initialValues.data_nascimento = initialValues.data_nascimento_3h;
      getPostos();
      setTrueInitialValues(initialValues);
    }
  }, [initialValues]);

  const handleSubmit = async e => {
    try {
      const enderecos = e.enderecos;
      const {
        bloqueio,
        bloqueio_fim,
        bloqueio_inicio,
        categoria_id,
        cpf,
        created_at,
        celular,
        data_inclusao,
        data_nascimento,
        email,
        email_duplicado,
        id,
        importacao_cadastro_irregular,
        nome,
        posto_graduacao_id,
        rg,
        sexo,
        status,
        status_associado,
        unidade,
        updated_at,
        telefone,
      } = e;

      const newForm = {
        bloqueio,
        bloqueio_fim,
        bloqueio_inicio,
        categoria_id,
        cpf,
        created_at,
        celular,
        data_inclusao,
        data_nascimento,
        email,
        email_duplicado,
        id,
        importacao_cadastro_irregular,
        nome,
        posto_graduacao_id,
        rg,
        sexo,
        status,
        status_associado,
        unidade,
        updated_at,
        telefone,
      };

      await api.put(`/user/${e.id}`, { ...newForm });

      const arrToPut = [];

      enderecos.map(en => {
        const index = initialValues.enderecos.findIndex(item => item === en);
        if (index < 0) {
          arrToPut.push(en);
        }
      });

      for (const en of arrToPut) {
        await api.put(`/associados/relacionamento/endereco/${en.id}`, {
          ...en,
        });
      }
      handleUpdateData();
      toast.success('Associado atualizado com sucesso !');
    } catch (error) {
      toast.error('Ocorreu um erro ao atualizar o associado.');
    }
  };

  return (
    <Form
      onSubmit={handleSubmit}
      decorators={[focusOnErrors]}
      validate={validate(schema)}
      initialValues={trueInitialValues}
      mutators={arrayMutators}
      render={props => {
        return (
          <form onSubmit={props.handleSubmit} style={{ position: 'relative' }}>
            <Box ml="auto" position="absolute" top={-449} right={0}>
              <Button
                variant="contained"
                color="primary"
                onClick={props.handleSubmit}
                data-cy="salvar-alteracoes"
                disabled={isDisabled}
              >
                Salvar alterações
              </Button>
            </Box>
            <Typography className={classes.title}>Dados Pessoais</Typography>

            <Grid container spacing={2}>
              <Grid item xs={4}>
                <Field
                  name="nome"
                  type="text"
                  component={TextField}
                  label="Nome Completo"
                  margin="normal"
                  fullWidth
                  variant="outlined"
                  data-cy="nome-completo"
                  disabled={isDisabled}
                />
              </Grid>
              <Grid item xs={4}>
                <Field
                  name="rg"
                  type="text"
                  component={TextField}
                  label="RG"
                  margin="normal"
                  fullWidth
                  variant="outlined"
                  data-cy="rg"
                  disabled={isDisabled}
                />
              </Grid>
              <Grid item xs={4}>
                <Field
                  name="telefone"
                  type="text"
                  component={TextField}
                  label="Telefone"
                  {...format('(XX) XXXX-XXXXX')}
                  margin="normal"
                  fullWidth
                  variant="outlined"
                  data-cy="telefone"
                  disabled={isDisabled}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <Field
                  name="email"
                  type="text"
                  component={TextField}
                  label="Email"
                  margin="normal"
                  fullWidth
                  variant="outlined"
                  data-cy="email"
                  disabled={isDisabled}
                />
              </Grid>
              <Grid item xs={4}>
                <Field
                  name="cpf"
                  type="text"
                  component={TextField}
                  {...format('XXX.XXX.XXX-XX')}
                  label="CPF do associado"
                  margin="normal"
                  fullWidth
                  variant="outlined"
                  data-cy="importacao-associado-cpf"
                  disabled={isDisabled}
                />
              </Grid>
              <Grid item xs={4}>
                <Field
                  name="celular"
                  type="text"
                  {...format('(XX) XXXXX-XXXX')}
                  component={TextField}
                  label="Celular"
                  margin="normal"
                  fullWidth
                  variant="outlined"
                  data-cy="celular"
                  disabled={isDisabled}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Field
                  style={{ marginTop: 0 }}
                  name="data_nascimento"
                  component={DateField}
                  label="Data de nascimento"
                  margin="normal"
                  fullWidth
                  variant="outlined"
                  data-cy="data_nascimento"
                  disabled={isDisabled}
                />
              </Grid>
              <Grid item xs={6}>
                <Field
                  name="posto_graduacao_id"
                  component={AutocompleteField}
                  options={postos}
                  label="Posto/Graduação"
                  fullWidth
                  variant="outlined"
                  labelAttr="nome"
                  valueAttr="id"
                  kind="value"
                  disabled={isDisabled}
                />
              </Grid>
              {/* <Grid item xs={4}>
                <Field
                  name="unidade"
                  component={AutocompleteField}
                  // disabled={
                  //   atResultadosPage && (!values.campanha || !values.sorteio)
                  // }
                  // options={unidades}
                  label="Unidade"
                  fullWidth
                  variant="outlined"
                  valueAttr="id"
                  labelAttr="nome"
                  kind="value"
                  // onSearch={getUnidades}
                />
              </Grid> */}
            </Grid>
            <FieldArray name="enderecos">
              {({ fields }) =>
                fields.map((name, index) => (
                  <div key={name}>
                    <Typography
                      className={classes.title}
                      style={{ marginBottom: 5, marginTop: 20 }}
                    >
                      Endereço {index + 1}
                    </Typography>
                    <Grid item xs={5}>
                      <Field
                        name={`${name}.cep`}
                        component={TextField}
                        label="CEP"
                        margin="normal"
                        fullWidth
                        variant="outlined"
                        data-cy={`cep-${index}`}
                        disabled={isDisabled}
                      />
                    </Grid>
                    <Grid container spacing={2}>
                      <Grid item xs={8}>
                        <Field
                          name={`${name}.logradouro`}
                          component={TextField}
                          label="Endereço"
                          margin="normal"
                          fullWidth
                          variant="outlined"
                          data-cy={`logradouro-${index}`}
                          disabled={isDisabled}
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <Field
                          name={`${name}.numero`}
                          component={TextField}
                          label="Número"
                          margin="normal"
                          fullWidth
                          variant="outlined"
                          data-cy={`numero-${index}`}
                          disabled={isDisabled}
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                      <Grid item xs={3}>
                        <Field
                          name={`${name}.complemento`}
                          component={TextField}
                          label="Complemento"
                          margin="normal"
                          fullWidth
                          variant="outlined"
                          data-cy={`complemento-${index}`}
                          disabled={isDisabled}
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <Field
                          name={`${name}.bairro`}
                          component={TextField}
                          label="Bairro"
                          margin="normal"
                          fullWidth
                          variant="outlined"
                          data-cy={`bairro-${index}`}
                          disabled={isDisabled}
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <Field
                          name={`${name}.cidade`}
                          component={TextField}
                          label="Cidade"
                          margin="normal"
                          fullWidth
                          variant="outlined"
                          data-cy={`cidade-${index}`}
                          disabled={isDisabled}
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <Field
                          name={`${name}.uf`}
                          component={AutocompleteField}
                          options={states}
                          label="Estado"
                          fullWidth
                          style={{ marginTop: 5 }}
                          variant="outlined"
                          valueAttr="id"
                          labelAttr="label"
                          kind="value"
                          data-cy={`uf-${index}`}
                          disabled={isDisabled}
                        />
                      </Grid>
                    </Grid>
                  </div>
                ))
              }
            </FieldArray>

            {footer(props)}
          </form>
        );
      }}
    />
  );
}

export default AssociadoCadastroForm;
