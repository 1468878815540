import React, { useEffect, useState } from 'react';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import { useAuth } from 'contexts/auth';
import Centered from 'components/Centered';
import { Typography } from '@material-ui/core';
import { toast } from 'react-toastify';
import AVMLogo from 'images/avm-logo-200.png';

const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));

function MagicLogin() {
  const history = useHistory();
  const location = useLocation();
  const { token } = useParams();
  const { signInToken } = useAuth();
  const { from } = location.state || { from: { pathname: '/' } };
  const [error, setError] = useState({ error: false, message: '' });

  async function login() {
    try {
      await sleep(2100);

      await signInToken(token);

      toast.success('Login realizado com sucesso!');

      await sleep(500);

      history.replace(from);
    } catch (err) {
      if (err.response) {
        const { data } = err.response;

        toast.error(data?.msg || 'Ocorreu um erro. Tente novamente!');
        setError({
          error: true,
          message: data?.msg,
        });
      }

      setError({
        error: true,
        message: '',
      });
    }
  }

  useEffect(() => {
    login();
  }, [token]);

  return (
    <Centered column>
      <img
        draggable={false}
        style={{ width: 200, marginBottom: 20 }}
        src={AVMLogo}
        alt="Inovando's Logo"
      />
      <Mensagem error={error.error} errorMessage={error.message} />
    </Centered>
  );
}

function Mensagem({ error = false, errorMessage = '' }) {
  return error ? (
    <>
      <Typography variant="h6" mb={2} align="center">
        Ocorreu um erro. Tente novamente!
      </Typography>
      <Typography variant="h6" mb={2} align="center">
        {errorMessage}
      </Typography>
    </>
  ) : (
    <>
      <Typography variant="h6" mb={2} align="center">
        Estamos realizando o seu login...
      </Typography>
      <Typography variant="h6" mb={2} align="center">
        Em instantes você será redirecionado para a tela principal!
      </Typography>
    </>
  );
}

export default MagicLogin;
