import React, { useState } from 'react';
import DialogForm from 'components/DialogForm';
import ResultadosForm from 'forms/ResultadosForm';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';

import { Box, Grid, makeStyles, Paper, Typography } from '@material-ui/core';
import api from 'services/api';
import { toast } from 'react-toastify';
import { format } from 'utils/form';
import { getFormattedDate } from 'utils/transform';

import DataTable from 'components/DataTable';
import useTable from 'hooks/useTable';

import { STATUS_RESULTADO, STATUS_RESULTADO_INSCRITO } from 'utils/constants';

const useStyles = makeStyles({
  dialogActions: {
    paddingLeft: 0,
  },
  resultadosDialog: {
    '& [aria-labelledby="form-dialog-title"]': {
      overflowY: 'initial',
    },
  },
});

const columns = [
  {
    name: 'sorteio',
    label: 'Campanha',
    options: {
      customBodyRender: sorteio => sorteio.campanha.titulo,
    },
  },
  {
    name: 'sorteio',
    label: 'Sorteio',
    options: {
      customBodyRender: sorteio => sorteio?.nome,
    },
  },
  {
    name: 'sorteio',
    label: 'Prazo de inscrição',
    options: {
      customBodyRender: sorteio => {
        const periodoInicio = getFormattedDate(sorteio.data_inscricao_inicio);
        const periodoFim = getFormattedDate(sorteio?.data_inscricao_fim);
        return `${periodoInicio} até ${periodoFim}`;
      },
    },
  },
  {
    name: 'sorteio',
    label: 'Data do sorteio',
    options: {
      customBodyRender: sorteio => getFormattedDate(sorteio?.data_sorteio),
    },
  },
  {
    name: 'periodo',
    label: 'Período',
    options: {
      customBodyRender: periodo => {
        const dataInicio = getFormattedDate(periodo.data_inicio);
        const dataFinal = getFormattedDate(periodo.data_fim);
        return `${periodo?.nome} - ${dataInicio} até ${dataFinal}`;
      },
    },
  },
  {
    name: 'sorteio',
    label: 'Qtd. de unidades',
    options: {
      customBodyRender: sorteio => sorteio.qtd_unidades,
    },
  },
  {
    name: 'resultado_sorteio',
    label: 'Status inscrição',
    options: {
      customBodyRender: resultado => {
        if (resultado !== null) {
          return STATUS_RESULTADO[resultado];
        }

        return STATUS_RESULTADO[STATUS_RESULTADO_INSCRITO];
      },
    },
  },
];

export default function ResultadosDialog({ onClose = () => {}, open = false }) {
  const classes = useStyles();
  const cpfMask = format('XXX.XXX.XXX-XX');

  const { onRefresh, ...dataTableProps } = useTable(
    'resultados_inscricoes_cpf',
    {
      download: false,
      viewColumns: false,
      search: false,
      print: false,
    },
    false,
  );

  const [associado, setAssociado] = useState({});

  async function getResultadosInscricoes(cpf) {
    const params = { cpf };
    onRefresh(params);
  }

  async function onSubmitAssociadoInfo(values) {
    const params = { cpf: values.associado_cpf };
    const { data } = await api.get('associados', { params });

    if (!data.data[0]?.id) {
      toast.warn('Adicione um CPF de associado válido para continuar.');

      return;
    }

    getResultadosInscricoes(data.data[0]?.cpf);

    setAssociado(data.data[0]);
  }

  return (
    <DialogForm
      title="Resultados Associado"
      open={open}
      onClose={() => {
        onClose();
        setAssociado({});
      }}
      className={classes.resultadosDialog}
      disableBackdropClick={false}
      maxWidth={associado.id ? 'md' : 'sm'}
    >
      {({ onClose }) =>
        !associado.id ? (
          <ResultadosForm
            onSubmit={onSubmitAssociadoInfo}
            footer={({ submitting }) => (
              <DialogActions className={classes.dialogActions}>
                {!submitting && (
                  <Button onClick={onClose} color="primary">
                    Cancelar
                  </Button>
                )}
                <Button
                  variant="contained"
                  disabled={submitting}
                  type="submit"
                  color="primary"
                >
                  Salvar
                </Button>
              </DialogActions>
            )}
          />
        ) : (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Paper variant="outlined">
                <Box
                  p={2}
                  display="flex"
                  flexDirection="row"
                  alignItems="center"
                  justifyContent="space-evenly"
                >
                  <Typography style={{ fontWeight: 'bold' }}>
                    {associado.nome}
                  </Typography>
                  <Typography style={{ fontWeight: 'bold' }}>
                    {cpfMask.format(associado.cpf)}
                  </Typography>
                  <Typography style={{ fontWeight: 'bold' }}>
                    {associado.email}
                  </Typography>
                </Box>
              </Paper>
            </Grid>
            <Grid item xs={12}>
              <DataTable columns={columns} {...dataTableProps} />
            </Grid>
          </Grid>
        )
      }
    </DialogForm>
  );
}
