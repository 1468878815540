import { useAuth } from 'contexts/auth';

function PermissionedComponent({ children, permission }) {
  const { user } = useAuth();
  if (user?.recursos_disponiveis?.find(rsrc => rsrc.nome === permission)) {
    return children;
  }

  return null;
}

export default PermissionedComponent;
