import React from 'react';
import MUIDataTable from 'mui-datatables';
import {
  Box,
  Button,
  IconButton,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { FaFilePdf } from 'react-icons/fa';
import { makeStyles } from '@material-ui/styles';
import PermissionedComponent from 'components/PermissionedComponent';
import {
  PERMISSION_BAIXAR_ASSOCIADO_CONTEMPLADOS,
  PERMISSION_BAIXAR_ASSOCIADO_SUPLENTES,
} from 'utils/constants';

const deviceWidth = window.innerWidth;

const useStyles = makeStyles(() => ({
  pdfButton: {
    position: 'absolute',
    right: deviceWidth < 600 ? 0 : 70,
    top: 10,
    zIndex: 1,
    '&:hover': {
      color: 'red',
    },
  },
  downloadButton: {
    position: 'absolute',
    right: deviceWidth < 600 ? 0 : 120,
    top: 10,
    zIndex: 1,
    '&:hover': {
      color: 'red',
    },
  },
}));

function DataTable({
  loading = false,
  options = {},
  title = 'Listagem',
  downloadPdf = false,
  onDownloadPdf,
  onDownloadContemplados,
  onDownloadSuplentes,
  customButtons,
  ...rest
}) {
  const classes = useStyles();
  const computedOptions = {
    serverSide: true,
    responsive: 'simple',
    filter: false,
    selectableRows: 'none',
    textLabels: {
      body: {
        noMatch: loading ? 'Carregando...' : 'Nenhum registro encontrado.',
      },
      pagination: {
        next: 'Próxima página',
        previous: 'Página anterior',
        rowsPerPage: 'Registros por página:',
        displayRows: 'de',
      },
      toolbar: {
        search: 'Pesquisa',
        download: 'Download .XLSX',
        downloadCsv: 'Download .CSV',
        print: 'Imprimir',
        viewColumns: 'Ver colunas',
        filterTable: 'Filtrar tabela',
      },
      filter: {
        all: 'Todos',
        title: 'Filtros',
        reset: 'Reiniciar',
      },
      viewColumns: {
        title: 'Mostrar colunas',
        titleAria: 'Mostrar/Ocular colunas da tabela',
      },
      selectedRows: {
        text: 'Registro(s) selecionado(s)',
        delete: 'Deletar',
        deleteAria: 'Deletar registros selecionadoss',
      },
    },
    ...options,
    onRowClick(_rowData, rowMeta) {
      if (!options.onRowClick) return;
      options.onRowClick(rest.data[rowMeta.rowIndex]);
    },
  };

  return (
    <div style={{ position: 'relative' }}>
      {downloadPdf && onDownloadPdf && (
        <>
          <Tooltip title="Download .PDF">
            <IconButton className={classes.pdfButton} onClick={onDownloadPdf}>
              <FaFilePdf size="20px" />
            </IconButton>
          </Tooltip>
        </>
      )}
      <>
        {onDownloadContemplados && (
          <PermissionedComponent
            permission={PERMISSION_BAIXAR_ASSOCIADO_CONTEMPLADOS}
          >
            <Tooltip title="Download .pdf dos contemplados">
              <Button
                className={classes.downloadButton}
                onClick={() => onDownloadContemplados()}
                style={{ right: 250, padding: 10 }}
              >
                Baixar contemplados
              </Button>
            </Tooltip>
          </PermissionedComponent>
        )}
        {onDownloadSuplentes && (
          <PermissionedComponent
            permission={PERMISSION_BAIXAR_ASSOCIADO_SUPLENTES}
          >
            <Tooltip title="Download .pdf dos suplentes">
              <Button
                className={classes.downloadButton}
                onClick={() => onDownloadSuplentes(true)}
                style={{ right: 80, padding: 10 }}
              >
                Baixar suplentes
              </Button>
            </Tooltip>
          </PermissionedComponent>
        )}
      </>
      <MUIDataTable
        title={
          customButtons ? (
            <Box marginTop={2}>
              <Typography variant="h6">{title}</Typography>
              {customButtons()}
            </Box>
          ) : (
            title
          )
        }
        options={computedOptions}
        {...rest}
      ></MUIDataTable>
    </div>
  );
}

export default DataTable;
