import React from 'react';

import { Box, Grid, Typography } from '@material-ui/core';

import { format } from 'utils/form';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    marginBottom: '3rem',
  },
  columns: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
  },
  box: {
    marginBottom: theme.spacing(2),
  },
  associado: {
    fontWeight: 'bold',
    fontSize: '16px',
  },
}));

function AssociadoCadastradoInfo({ associado }) {
  const classes = useStyles();
  const cpfMask = format('XXX.XXX.XXX-XX');

  return (
    <Grid container className={classes.root}>
      <Grid item sm={3} className={classes.columns}>
        <Box className={classes.box}>
          <Typography>Nome:</Typography>
          <Typography className={classes.associado}>
            {associado.nome}
          </Typography>
        </Box>
      </Grid>
      <Grid item sm={3} className={classes.columns}>
        <Box className={classes.box}>
          <Typography>CPF:</Typography>
          <Typography className={classes.associado}>
            {cpfMask.format(associado.cpf)}
          </Typography>
        </Box>
      </Grid>
      <Grid item sm={3} className={classes.columns}>
        <Box className={classes.box}>
          <Typography>Posto/Graduação:</Typography>
          <Typography className={classes.associado}>
            {associado?.postoGraduacao?.nome}
          </Typography>
        </Box>
      </Grid>
    </Grid>
  );
}

export default AssociadoCadastradoInfo;
