import React, { useEffect, useState } from 'react';

import DataTable from 'components/DataTable';
import useTable from 'hooks/useTable';
import { useHistory, useParams } from 'react-router-dom';
import Box from '@material-ui/core/Box';
import { Paper, Button } from '@material-ui/core';
import Container from '@material-ui/core/Container';
import { actionsTableOptions } from 'components/ActionsTable';
import api from 'services/api';
import { toast } from 'react-toastify';
import BreadcrumbsAVM from 'components/BreadcrumbsAVM';
import { getFormattedDate } from 'utils/transform';
import {
  PERMISSION_DETALHAMENTO_SORTEIO,
  PERMISSION_EXCLUIR_RESULTADO_SORTEIO_RECEM_SORTEADO,
  PERMISSION_PUBLICAR_RESULTADO_SORTEIO,
  PERMISSION_RESORTERA_SORTEIO,
  PERMISSION_VISUALIZAR_RESULTADO_SORTEIO_RECEM_SORTEADO,
  STATUS_SORTEIO_SELECTOR,
} from 'utils/constants';
import ResultadoAssociadosSorteio from 'components/ResultadoAssociadosSorteio/ResultadoAssociadosSorteio';
import VisibilityIcon from '@material-ui/icons/Visibility';
import ConfirmModal from 'components/ConfirmModal';
import { isUserAllowed } from 'utils/permissions';
import { useAuth } from 'contexts/auth';

function Resultados() {
  const { user } = useAuth();
  let { id } = useParams();
  const history = useHistory();

  const [sorteio, setSorteio] = useState({});
  const [showAssociadosSorteio, setShowAssociadosSorteio] = useState(false);
  const [selectedSorteio, setSelectedSorteio] = useState({});
  const [isPublicado, setIsPublicado] = useState(false);
  const [showConfirmPublicarSorteio, setShowConfirmPublicarSorteio] = useState(
    false,
  );
  const [columns, setColumns] = useState([]);
  const [loading, setLoading] = useState(false);

  const publicaResultado = async resultado => {
    await api.put(`resultado-sorteio/${resultado.id}`, { status_sorteio: 4 });
    toast.success('Sorteio publicado!');
    history.push(`/sorteios`);
    setSelectedSorteio({});
  };

  const { data, onRefresh, ...dataTableProps } = useTable(
    `sorteios/${id}/resultados`,
    {
      onRowClick,
      download: false,
      viewColumns: false,
      print: false,
      params: { status: true },
    },
  );

  const resortear = async row => {
    try {
      setLoading(true);
      await api.post(`/sorteios/processa`, { sorteio_id: row.sorteio_id });
      onRefresh();
      setShowAssociadosSorteio(false);
      selectedSorteio({});
      onRowClick(row);

      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const caminho = [
    {
      nome: 'Sorteios',
      path: '/sorteio',
    },
    {
      nome: sorteio.campanha?.titulo,
      path: `/campanha/${sorteio.campanha?.id}`,
    },
    {
      nome: sorteio?.nome,
      path: `/sorteio/${id}`,
    },
    {
      nome: 'Resultados',
      path: `/sorteio/${id}/resultados`,
    },
  ];

  const defaultColumns = [
    {
      name: 'created_at',
      label: 'Data Realização',
      options: {
        customBodyRender(date) {
          return getFormattedDate(date, 'Pp');
        },
      },
    },
    {
      name: 'sorteio',
      label: 'Campanha',
      options: {
        customBodyRender(sorteio) {
          return sorteio?.campanha ? sorteio?.campanha?.titulo : '';
        },
      },
    },
    {
      name: 'status_sorteio',
      label: 'Status',
      options: {
        customBodyRender(status) {
          return STATUS_SORTEIO_SELECTOR[status];
        },
      },
    },
  ];

  async function onRowClick(row) {
    if (
      !isUserAllowed(
        user,
        PERMISSION_VISUALIZAR_RESULTADO_SORTEIO_RECEM_SORTEADO,
      )
    )
      return;
    setSelectedSorteio(row);
    setShowAssociadosSorteio(true);
  }

  const getSorteio = async () => {
    try {
      const { data: sorteio } = await api.get(`sorteios/${id}`, {
        params: { include: 'campanha' },
      });

      setSorteio(sorteio);
      const isPublicadoSorteio = sorteio.status_sorteio === 3;
      setIsPublicado(isPublicadoSorteio);
    } catch (err) {
      toast.error('Houve um erro ao obter dados do sorteio');
    }
  };

  function getColumns() {
    const columnsByPermission = [
      isUserAllowed(user, PERMISSION_PUBLICAR_RESULTADO_SORTEIO) && {
        name: 'Publicar',
        options: {
          filter: false,
          sort: false,
          empty: true,
          display: !isPublicado,
          customBodyRenderLite: dataIndex => {
            return (
              <Button
                color="primary"
                variant="contained"
                disabled={loading}
                onClick={event => {
                  event.stopPropagation();
                  setSelectedSorteio(data[dataIndex]);
                  setShowConfirmPublicarSorteio(true);
                }}
              >
                Publicar
              </Button>
            );
          },
        },
      },
      isUserAllowed(user, PERMISSION_RESORTERA_SORTEIO) && {
        name: 'Resortear',
        options: {
          filter: false,
          sort: false,
          empty: true,
          display: !isPublicado,
          customBodyRenderLite: dataIndex => {
            return (
              <Button
                color="secondary"
                disabled={loading}
                loading={loading}
                variant="contained"
                onClick={() => {
                  const row = data[dataIndex];
                  resortear(row);
                }}
              >
                Resortear
              </Button>
            );
          },
        },
      },
      isUserAllowed(user, PERMISSION_DETALHAMENTO_SORTEIO) && {
        name: 'ações',
        label: 'Ações',
        options: actionsTableOptions({
          sort: false,
          data,
          onEdit: isUserAllowed(
            user,
            PERMISSION_VISUALIZAR_RESULTADO_SORTEIO_RECEM_SORTEADO,
          )
            ? onRowClick
            : false,
          editIcon: <VisibilityIcon fontSize="small" />,
          customEditIcon: true,
          showDelete: isUserAllowed(
            user,
            PERMISSION_EXCLUIR_RESULTADO_SORTEIO_RECEM_SORTEADO,
          ),
          onDelete: async item => {
            await api.delete(`sorteios/resultados/${item.id}`);
            toast.success('Resultado deletado com sucesso!');
            onRefresh();
            setShowAssociadosSorteio(false);
          },
        }),
      },
    ];

    setColumns([
      ...defaultColumns,
      ...columnsByPermission.filter(item => !!item.name),
    ]);
  }

  useEffect(() => {
    getSorteio();
    getColumns();
  }, []);

  useEffect(() => {
    getColumns();
  }, [data, isPublicado]);

  return (
    <Container>
      <BreadcrumbsAVM caminho={caminho} />

      <Paper>
        <Box mt={4}>
          <DataTable
            columns={columns}
            data={data}
            title={`Resultados do ${sorteio.nome}`}
            {...dataTableProps}
          />
        </Box>
      </Paper>

      {showAssociadosSorteio && (
        <Box mt={4}>
          <ResultadoAssociadosSorteio
            onRefresh={onRefresh}
            sorteio={sorteio}
            resultado_sorteio={selectedSorteio}
          />
        </Box>
      )}

      <ConfirmModal
        title="Deseja publicar o sorteio?"
        open={showConfirmPublicarSorteio}
        onClose={() => setShowConfirmPublicarSorteio(false)}
        onAccept={() => {
          publicaResultado(selectedSorteio);
          setShowConfirmPublicarSorteio(false);
        }}
      />
    </Container>
  );
}

export default Resultados;
