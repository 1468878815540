import React, { useState } from 'react';
import { Form, Field } from 'react-final-form';
import createDecorator from 'final-form-focus';
import { TextField } from 'final-form-material-ui';

import { validate } from 'utils/validate';
import { format } from 'utils/form';
import { schema } from './schema';
import { AutocompleteField } from '@inovando/react-components';

import api from 'services/api';
import { OnChange } from 'react-final-form-listeners';
import { validateBr } from 'js-brasil';
import { toast } from 'react-toastify';

const focusOnErrors = createDecorator();

function AtalhoInscricaoForm({
  onSubmit,
  footer = () => {},
  initialValues = {},
  children,
  setPeriodosInscritos,
}) {
  const [sorteios, setSorteios] = useState([]);

  async function getSorteiosByCPF(cpf) {
    const params = { all: true, ignoreForceUpdate: true };
    const { data } = await api.get(
      `sorteios_disponiveis_cpf?cpf=${cpf}&include=sorteio`,
      {
        params,
      },
    );

    if (!data.sorteios_disponiveis) {
      toast.warn('O associado não possui sorteios disponíveis para inscrição.');
      return;
    }

    setPeriodosInscritos(data.periodo_inscrito);
    setSorteios(data.sorteios_disponiveis);
  }

  return (
    <Form
      onSubmit={onSubmit}
      decorators={[focusOnErrors]}
      validate={validate(schema)}
      initialValues={initialValues}
      render={props => (
        <form onSubmit={props.handleSubmit} noValidate>
          <Field
            name="associado_cpf"
            autoFocus
            type="text"
            component={TextField}
            {...format('XXX.XXX.XXX-XX')}
            label="CPF do associado"
            margin="normal"
            fullWidth
            variant="outlined"
          />
          <OnChange name="associado_cpf">
            {value => {
              if (!value) {
                setSorteios([]);
                setPeriodosInscritos([]);
                return;
              }

              if (!validateBr.cpf(value)) {
                setSorteios([]);
                setPeriodosInscritos([]);
                return;
              }

              getSorteiosByCPF(value);
            }}
          </OnChange>
          <Field
            name="sorteio"
            component={AutocompleteField}
            options={sorteios}
            labelAttr="nome"
            label="Sorteio"
            margin="normal"
            fullWidth
            variant="outlined"
            disabled={!sorteios.length}
          />
          {children}
          {footer(props)}
        </form>
      )}
    />
  );
}

export default AtalhoInscricaoForm;
