import React, { useState } from 'react';
import LoginPorEmailForm from 'forms/LoginPorEmailForm';
import { Typography, Button, Box, Paper } from '@material-ui/core';
import { FormContainer } from 'styles/global';
import Centered from 'components/Centered';
import ExibeIrregularDialog from 'dialogs/ExibeIrregularDialog';
import AVMLogo from 'images/avm-logo-200.png';
import { toast } from 'react-toastify';
import { AiOutlineArrowLeft } from 'react-icons/ai';

import api from 'services/api';
import LoginPorEmailConfirmarForm from 'forms/LoginPorEmailConfirmar/LoginPorEmailConfirmarForm';
import CadastroBloqueadoDialog from 'dialogs/CadastroBloqueado';

const LoginPorEmail = () => {
  const [success, setSuccess] = useState(false);
  const [initialValues, setInitialValues] = useState({});
  const [isIrregularOrDuplicado, setIsIrregularOrDuplicado] = useState(false);
  const [isBlockedAccess, setIsBlockedAccess] = useState(false);

  const onSubmit = async values => {
    try {
      console.log('[onSubmit]', values);

      await api.post('/auth/login/magic', values);

      toast.success('E-mail enviado com sucesso!');

      setSuccess(true);
    } catch (err) {
      if (err.response) {
        const { data } = err.response;

        setIsIrregularOrDuplicado(data.cadastro_irregular);
        setIsBlockedAccess(data.customer_status);
        toast.error(data?.msg || 'Ocorreu um erro. Tente novamente!');
      }
    }
  };

  const verifyMailAccount = async values => {
    const { cpf } = values;

    try {
      const { data } = await api.get(`/busca-associado/${cpf}`);
      setInitialValues(data);
    } catch (err) {
      toast.error(err?.response?.data?.msg || err?.message);
    }
  };

  const isOnVerifyPage = Object.keys(initialValues).length > 0;

  if (success) {
    return (
      <Centered column>
        <FormContainer>
          <Paper
            style={{
              maxWidth: 360,
              width: '100%',
              margin: 'auto',
              position: 'relative',
              padding: '24px',
            }}
          >
            <Box align="center">
              <img
                draggable={false}
                style={{ width: 100, marginBottom: 20 }}
                src={AVMLogo}
                alt="Inovando's Logo"
              />
            </Box>
            <Typography
              variant="h6"
              align="center"
              mb="8px"
              style={{
                fontSize: '16px',
                lineHeight: '24px',
              }}
            >
              E-mail enviado
            </Typography>
            <Typography
              variant="body1"
              align="center"
              style={{
                fontSize: '12px',
                lineHeight: '18px',
              }}
            >
              Vá para seu e-mail e entre pelo link para validar seu login na
              plataforma
            </Typography>
          </Paper>
        </FormContainer>
      </Centered>
    );
  }

  return (
    <>
      <CadastroBloqueadoDialog
        open={isBlockedAccess}
        setStateOpened={setIsBlockedAccess}
      />
      <ExibeIrregularDialog
        open={isIrregularOrDuplicado}
        setStateOpened={setIsIrregularOrDuplicado}
      />
      <Paper
        style={{
          maxWidth: 360,
          width: '100%',
          margin: 'auto',
          position: 'relative',
        }}
      >
        {isOnVerifyPage && (
          <Box position="absolute" top="10px" left="10px">
            <Button
              startIcon={<AiOutlineArrowLeft />}
              style={{ textTransform: 'lowercase' }}
              onClick={() => setInitialValues({})}
            >
              Voltar
            </Button>
          </Box>
        )}
        <Centered column>
          <img
            draggable={false}
            style={{ width: 100, marginBottom: 20 }}
            src={AVMLogo}
            alt="Inovando's Logo"
          />
          <Typography
            variant="body1"
            color="textSecondary"
            mb={2}
            align="center"
            style={{ fontSize: '12px', lineHeight: '18px' }}
          >
            Preencha seu <strong>CPF</strong> e um link de acesso será{' '}
            <strong>enviado para seu e-mail</strong>
          </Typography>
          <FormContainer>
            {isOnVerifyPage ? (
              <LoginPorEmailConfirmarForm
                initialValues={initialValues}
                onSubmit={onSubmit}
              />
            ) : (
              <LoginPorEmailForm onSubmit={verifyMailAccount} />
            )}
          </FormContainer>
        </Centered>
      </Paper>
    </>
  );
};

export default LoginPorEmail;
