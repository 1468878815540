import { setupYup } from 'config/yup';
import { validateBr } from 'js-brasil';

const Yup = setupYup();

export const schema = Yup.object().shape(
  {
    email: Yup.string()
      .email('Insira um e-mail válido')
      .when('cpf', {
        is: cpf => !cpf,
        then: Yup.string()
          .email()
          .required(),
        otherwise: Yup.string(),
      }),
    cpf: Yup.string().when('email', {
      is: email => !email,
      then: Yup.string()
        .required()
        .test('cpf', 'CPF Inválido', function(value) {
          if (!value) return;

          return validateBr.cpf(value);
        }),
      otherwise: Yup.string(),
    }),
  },
  [['email', 'cpf']],
);
