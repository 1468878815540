import React, { useState } from 'react';

import { Typography, Container, Paper, Box, Button } from '@material-ui/core';
import { Cancel } from '@material-ui/icons';
import DataTable from 'components/DataTable';
import useTable from 'hooks/useTable';

import {
  DEFAULT_PROFILE,
  PERMISSION_CANCELAR_INSCRICAO,
  PERMISSION_DOWNLOAD_EXCEL_INSCRICOES,
  STATUS_SORTEIO_EM_DESENVOLVIMENTO,
  STATUS_SORTEIO_INICIADO,
  STATUS_SORTEIO_PUBLICADO,
  STATUS_SORTEIO_SELECTOR,
} from 'utils/constants';
import SearchInscricoes from 'forms/SearchInscricoes';
import { useAuth } from 'contexts/auth';
import { getFormattedDate } from 'utils/transform';
import { toast } from 'react-toastify';
import api from 'services/api';
import BreadcrumbsAVM from 'components/BreadcrumbsAVM';
import PermissionedComponent from 'components/PermissionedComponent';
import { isUserAllowed } from 'utils/permissions';

const Inscricoes = () => {
  const { user, profiles } = useAuth();
  const isAssociado = profiles.includes(DEFAULT_PROFILE);
  const [filters, setFilters] = useState({});

  const { onRefresh, data, ...dataTableProps } = useTable('inscricoes', {
    viewColumns: false,
    print: false,
    download: isUserAllowed(user, PERMISSION_DOWNLOAD_EXCEL_INSCRICOES),
    search: false,
    onDownload: () => {
      downloadReport();
      return false;
    },
  });

  const downloadReport = async () => {
    const params = {
      ...filters,
      xlsx: true,
    };

    const { data: dataXls } = await api.get('inscricoes', {
      params,
      responseType: 'blob',
      headers: {
        'Content-Type':
          'application/application/vnd.openxmlformats-officedocument.spreadsheetml.sheet; charset=UTF-8',
      },
    });

    const file = new Blob([dataXls], {
      type:
        'application/application/vnd.openxmlformats-officedocument.spreadsheetml.sheet; charset=UTF-8',
    });
    const fileURL = URL.createObjectURL(file);
    const link = document.createElement('a');
    link.href = fileURL;
    link.download = `relatorio_inscricoes_${+new Date()}.xlsx`;
    link.click();
    return false;
  };

  const columns = [
    {
      name: 'numero_sorteio',
      label: '#',
      options: {
        customBodyRender: value => (value ? value : '-'),
      },
    },
    {
      name: 'sorteio',
      label: 'Campanha',
      options: {
        customBodyRender: value => {
          return value?.campanha?.titulo;
        },
      },
    },
    {
      name: 'sorteio',
      label: 'Sorteio',
      options: {
        customBodyRender: value => {
          return value?.nome;
        },
      },
    },
    {
      name: 'periodo',
      label: 'Período',
      options: {
        customBodyRender: periodo => {
          const periodoInicio = getFormattedDate(periodo.data_inicio);
          const periodoFim = getFormattedDate(periodo.data_fim);
          const periodoNome = periodo.nome;

          return `${periodoNome} - ${periodoInicio} até ${periodoFim}`;
        },
      },
    },
    {
      name: 'associado',
      label: 'Sócio',
      options: {
        customBodyRender: value => {
          const nome = value?.nome.toUpperCase();
          const categoria = value?.categoria?.nome;

          return `${nome.toUpperCase()}  ${categoria}`;
        },
      },
    },
    {
      name: 'associado',
      label: 'Categoria',
      options: {
        customBodyRender: value => {
          const categoria = value?.categoria?.nome;
          return `${categoria}`;
        },
      },
    },
    {
      name: 'associado',
      label: 'Posto',
      options: {
        customBodyRender: value => {
          const categoria = value?.postoGraduacao?.nome;
          return `${categoria}`;
        },
      },
    },
    {
      name: 'associado',
      label: 'Cpf',
      options: {
        customBodyRender: value => {
          const cpf = value?.cpf ? `${value.cpf}` : '';
          return `${cpf}`;
        },
      },
    },
    {
      name: 'sorteio',
      label: 'Status',
      options: {
        sort: true,
        customBodyRender: sorteio => {
          return STATUS_SORTEIO_SELECTOR[sorteio.status_sorteio];
        },
      },
    },
    {
      name: 'created_at',
      label: 'Inscrição',
      options: {
        sort: true,
        customBodyRender: value => {
          if (value) return getFormattedDate(value, 'Pp');
        },
      },
    },
    {
      name: 'ações',
      label: 'Ações',
      options: {
        customBodyRender: (_value, row) => (
          <PermissionedComponent permission={PERMISSION_CANCELAR_INSCRICAO}>
            <Button
              variant="contained"
              startIcon={<Cancel />}
              onClick={() => cancelInscricao(data[row.rowIndex])}
              disabled={
                ![
                  STATUS_SORTEIO_INICIADO,
                  STATUS_SORTEIO_EM_DESENVOLVIMENTO,
                  STATUS_SORTEIO_PUBLICADO,
                ].includes(data[row.rowIndex].sorteio.status_sorteio)
              }
              color="secondary"
            >
              Cancelar
            </Button>
          </PermissionedComponent>
        ),
      },
    },
  ];

  const caminho = [
    {
      nome: 'Inscrições',
      path: '/inscricoes',
      active: true,
    },
  ];

  const cancelInscricao = async inscricao => {
    await api.delete(`sorteios/remove-associado/${inscricao.id}`);

    toast.success('Inscrição cancelada com sucesso!');
    onRefresh();
  };

  return (
    <Container>
      <BreadcrumbsAVM caminho={caminho} />

      <Paper>
        <Box p={1} mb={1}>
          <Typography variant="h6">Filtros</Typography>
          <SearchInscricoes
            onSubmit={values => {
              setFilters(values);
              onRefresh(values, true);
            }}
          />
        </Box>
      </Paper>

      <DataTable
        columns={
          isAssociado
            ? columns.filter((_column, index) => index !== 2)
            : columns
        }
        data={data}
        {...dataTableProps}
      />
    </Container>
  );
};

export default Inscricoes;
