import React from 'react';
import { Typography, Paper, Button, Box } from '@material-ui/core';
import Centered from 'components/Centered';
import AVMLogo from 'images/avm-logo-200.png';
import { Link as RouterLink } from 'react-router-dom';
import Call from '@material-ui/icons/Call';
import ArrowBack from '@material-ui/icons/ArrowBack';

const numeroDeTelefone1 = 'telefone: (41) 3075-8200';

const styleDiv = {
  boxSizing: 'border-box',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '16px 54px',
  gap: '20px',
  height: '56px',
  background: '#F0F0F0',
  border: '1px solid #D1D1D1',
  color: '#7C7C7C',
  borderRadius: '15px',
  flex: 'none',
  order: 3,
  flexGrow: 0,
  textTransform: 'none',
};

const styleButton = {
  padding: '25px',
  height: '17px',
  fontStyle: 'normal',
  fontWeight: '500',
  fontSize: '13px',
  lineHeight: '17px',
  textTransform: 'none',
  color: '#1A202C',
};

const styleText = {
  padding: '15px',
};

const stylePaper = {
  maxWidth: '440px',
  width: '100%',
  margin: 'auto',
};

const styleImg = {
  width: '100px',
  marginBottom: '20px',
};

const ContatoPorTelefone = () => {
  return (
    <>
      <Paper style={stylePaper}>
        <Button
          variant="text"
          style={styleButton}
          color="primary"
          startIcon={<ArrowBack />}
          component={RouterLink}
          to="/"
        >
          voltar
        </Button>
        <Centered column>
          <img
            draggable={false}
            style={styleImg}
            src={AVMLogo}
            alt="Inovando's Logo"
          />
          <Typography
            variant="body1"
            color="textSecondary"
            mb={2}
            align="center"
            style={styleText}
          >
            Utilize o telefone abaixo para entrar em contato com a equipe AVM:
          </Typography>
          <Box padding="20px">
            <Typography style={styleDiv}>
              <Call />
              {numeroDeTelefone1}
            </Typography>
          </Box>
          <Typography color="textSecondary">Tecle a opção 2</Typography>
        </Centered>
      </Paper>
    </>
  );
};

export default ContatoPorTelefone;
