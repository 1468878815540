import React from 'react';

import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableContainer,
  Box,
  IconButton,
} from '@material-ui/core';
import { Print } from '@material-ui/icons';

import { getFormattedDate } from 'utils/transform';
import api from 'services/api';
import { toast } from 'react-toastify';
import PermissionedComponent from 'components/PermissionedComponent';
import { PERMISSION_IMPRIMIR_CARTEIRINHA } from 'utils/constants';

function Carteirinhas({ dependentes }) {
  const handleDownload = async cpf => {
    try {
      const { data } = await api.get(`gerar-carteirinha/${cpf}`, {
        responseType: 'blob',
      });
      const fileURL = URL.createObjectURL(data);
      const newWindow = window.open(fileURL, '_blank');
      newWindow.onload = () => {
        newWindow.print();
      };
    } catch (error) {
      toast.error(
        'O dependente/associado deve possuir rg e cpf cadastrados para gerar uma carteirinha.',
      );
    }
  };

  return (
    <Box mb={4}>
      <TableContainer>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Tipo</TableCell>
              <TableCell>Nome</TableCell>
              <TableCell>Data de Criação</TableCell>
              <TableCell>Status</TableCell>
              <PermissionedComponent
                permission={PERMISSION_IMPRIMIR_CARTEIRINHA}
              >
                <TableCell>Ações</TableCell>
              </PermissionedComponent>
            </TableRow>
          </TableHead>
          <TableBody>
            {dependentes?.map(carteirinha => (
              <TableRow key={carteirinha.id}>
                <TableCell>
                  {carteirinha.grau_parentesco === 'Associado'
                    ? carteirinha.grau_parentesco
                    : 'Dependente'}
                </TableCell>
                <TableCell>{carteirinha.nome}</TableCell>
                <TableCell>
                  {getFormattedDate(carteirinha?.created_at)}
                </TableCell>
                <TableCell>Ativo</TableCell>
                <TableCell>
                  <PermissionedComponent
                    permission={PERMISSION_IMPRIMIR_CARTEIRINHA}
                  >
                    <IconButton onClick={() => handleDownload(carteirinha.cpf)}>
                      <Print fontSize="small" />
                    </IconButton>
                  </PermissionedComponent>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}

export default Carteirinhas;
