import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import AVMLogo from 'images/avm-logo-200.png';
import Redeem from 'images/redeem.png';
import Block from 'images/block.png';
import Groups from 'images/groups.png';
import Centered from 'components/Centered';
import { FormContainer } from 'styles/global';
import {
  Box,
  Button,
  InputAdornment,
  Paper,
  TextField,
  Typography,
  makeStyles,
} from '@material-ui/core';
import api from 'services/api';
import { getFormattedDate } from 'utils/transform';
import {
  STATUS_RESULTADO_GANHOU,
  STATUS_RESULTADO_NAO_CONTEMPLADO,
  STATUS_RESULTADO_SUPLENTE,
  TIPO_CAMPANHAS,
} from 'utils/constants';

const useStyles = makeStyles(theme => ({
  input: {
    marginTop: 50,
  },
  subtitle: {
    fontWeight: 'bold',
    fontSize: '16px',
    color: '#000000',
    textAlign: 'center',
    marginBottom: 10,
  },
  errorMessage: {
    fontSize: '14px',
    textAlign: 'center',
    marginBottom: '20px',
    marginTop: '20px',
    color: '#6F6F6F',
    fontWeight: 400,
  },
  actionButton: {
    backgroundColor: theme.palette.success.light,
    color: 'white',
    '&:hover': {
      backgroundColor: theme.palette.success.main,
    },
  },
  inscriptionNumber: {
    fontWeight: 400,
    fontSize: 14,
    marginLeft: 20,
  },
  inscriptionNumberBolder: {
    fontWeight: 700,
    fontSize: 14,
    marginLeft: 5,
  },
  codeIcon: {
    color: '#E53E3E',
    fontWeight: 700,
    fontSize: 12,
  },
  label: {
    fontSize: 12,
    fontWeight: 600,
    color: '#6F6F6F',
    marginRight: 5,
  },
}));

function ConsultarCodigo() {
  const [error, setError] = useState(false);
  const [code, setCode] = useState('');
  const [lastCode, setLastCode] = useState('');
  const [raffle, setRaffle] = useState({});
  const [loading, setLoading] = useState(false);
  const classes = useStyles();
  const history = useHistory();

  const numberToCall = '(41) 3075-8200';
  const isMobile = /iPhone|iPad|iPod|Android/i.test(window.navigator.userAgent);

  const onSubmit = async () => {
    try {
      setLoading(true);
      if (code.length < 3) {
        setError(true);
      }

      setLastCode(code);

      setLoading(true);

      const { data } = await api.get(
        `resultado-associado/${code.toUpperCase()}`,
      );

      setRaffle(data);
    } catch (error) {
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  function historyPush() {
    history.push('/');
  }

  const codeIcon = (
    <Box
      style={{
        backgroundColor: '#F2f2f2',
        padding: 2,
        borderRadius: 4,
      }}
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      <Typography className={classes.codeIcon}>123</Typography>
    </Box>
  );

  const reset = () => {
    setRaffle({});
    setCode('');
    setLastCode('');
  };

  return (
    <>
      {raffle?.id && !loading ? (
        <>
          {!isMobile && (
            <Paper
              style={{
                maxWidth: 440,
                width: '100%',
                margin: 'auto auto 0px auto',
              }}
            >
              <Box flexDirection="row" padding={1} display="flex">
                {codeIcon}
                <Typography className={classes.inscriptionNumber}>
                  Código da inscrição:
                </Typography>
                <Typography className={classes.inscriptionNumberBolder}>
                  {code}
                </Typography>
              </Box>
            </Paper>
          )}
          <Paper
            style={{
              maxWidth: 440,
              width: '100%',
              margin: raffle?.id
                ? isMobile
                  ? 'auto'
                  : '20px auto auto auto'
                : 'auto',
            }}
          >
            <Box padding={2}>
              {isMobile && (
                <>
                  <img
                    draggable={false}
                    style={{ width: 50, marginBottom: 20 }}
                    src={AVMLogo}
                    alt="AVM Logo"
                  />
                  <Paper
                    style={{
                      backgroundColor: '#DEDEDE',
                      marginBottom: 20,
                      marginTop: 20,
                    }}
                  >
                    <Box flexDirection="row" padding={1} display="flex">
                      {codeIcon}
                      <Typography className={classes.inscriptionNumber}>
                        Código da inscrição:
                      </Typography>
                      <Typography className={classes.inscriptionNumberBolder}>
                        {code}
                      </Typography>
                    </Box>
                  </Paper>
                </>
              )}
              {raffle?.sorteio?.status_sorteio !== 3 ? (
                <Typography
                  style={{ marginLeft: 0 }}
                  className={classes.inscriptionNumberBolder}
                >
                  Você está inscrito no seguinte sorteio
                </Typography>
              ) : (
                <Box flexDirection="row" display="flex" alignItems="center">
                  {raffle?.resultado?.status_sorteio ===
                    STATUS_RESULTADO_SUPLENTE && (
                    <img src={Groups} alt="Groups" />
                  )}
                  {raffle?.resultado?.status_sorteio ===
                    STATUS_RESULTADO_GANHOU && (
                    <img src={Redeem} alt="Redeem" />
                  )}
                  {raffle?.resultado?.status_sorteio ===
                    STATUS_RESULTADO_NAO_CONTEMPLADO && (
                    <img src={Block} alt="Block" />
                  )}
                  <Box marginLeft={2}>
                    <Typography
                      style={{ marginLeft: 0 }}
                      className={classes.inscriptionNumberBolder}
                    >
                      {raffle?.resultado?.status_sorteio ===
                        STATUS_RESULTADO_SUPLENTE && 'Foi quase!'}
                      {raffle?.resultado?.status_sorteio ===
                        STATUS_RESULTADO_GANHOU && 'Parabéns!'}
                      {raffle?.resultado?.status_sorteio ===
                        STATUS_RESULTADO_NAO_CONTEMPLADO && 'Não foi dessa vez'}
                    </Typography>
                    <Typography
                      className={classes.inscriptionNumber}
                      style={{ marginLeft: 0 }}
                    >
                      {raffle?.resultado?.status_sorteio ===
                        STATUS_RESULTADO_SUPLENTE &&
                        'Você está na lista de suplentes'}
                      {raffle?.resultado?.status_sorteio ===
                        STATUS_RESULTADO_GANHOU && 'Você foi sorteado'}
                      {raffle?.resultado?.status_sorteio ===
                        STATUS_RESULTADO_NAO_CONTEMPLADO &&
                        'Você não foi um dos escolhidos no sorteio'}
                    </Typography>
                  </Box>
                </Box>
              )}
              <Box flexDirection="row" display="flex" marginTop={5}>
                <Typography
                  style={{ marginLeft: 0 }}
                  className={classes.inscriptionNumber}
                >
                  Sorteio:
                </Typography>
                <Typography className={classes.inscriptionNumberBolder}>
                  {raffle?.sorteio?.nome.toUpperCase()}
                </Typography>
              </Box>
              <Box flexDirection="row" display="flex">
                <Typography
                  style={{ marginLeft: 0 }}
                  className={classes.inscriptionNumber}
                >
                  Colônia:
                </Typography>
                <Typography className={classes.inscriptionNumberBolder}>
                  {raffle?.unidade_sorteio.unidade.bloco.colonia.nome}
                </Typography>
              </Box>
              <Box
                style={{
                  backgroundColor: 'black',
                  borderRadius: 8,
                  width: 80,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  marginTop: 10,
                  marginBottom: 10,
                }}
              >
                <Typography
                  style={{ marginLeft: 0, color: 'white' }}
                  className={classes.inscriptionNumber}
                >
                  {TIPO_CAMPANHAS[raffle?.sorteio?.campanha?.tipo]}
                </Typography>
              </Box>
              <Paper
                style={{
                  backgroundColor: '#F8F8F8',
                  border: '1px solid ##D7D7D7',
                  padding: 10,
                  marginBottom: 20,
                  marginTop: 20,
                }}
              >
                {raffle?.status_sorteio === STATUS_RESULTADO_SUPLENTE && (
                  <Box display="flex" flexDirection="row" marginTop={1}>
                    <Typography className={classes.label}>
                      Posição suplente:{' '}
                    </Typography>
                    <Typography style={{ fontSize: 12 }}>
                      {raffle?.resultado?.motivo_sorteio?.ordemSuplente}
                    </Typography>
                  </Box>
                )}

                {raffle?.status_sorteio === STATUS_RESULTADO_GANHOU && (
                  <Box display="flex" flexDirection="row">
                    <Typography className={classes.label}>
                      Unidade contemplada:{' '}
                    </Typography>
                    <Typography style={{ fontSize: 12 }}>
                      {raffle?.unidade_sorteio?.unidade?.nome}
                    </Typography>
                  </Box>
                )}

                {(raffle?.resultado?.status_sorteio ===
                  STATUS_RESULTADO_NAO_CONTEMPLADO ||
                  raffle?.sorteio?.status_sorteio !== 3) && (
                  <Box display="flex" flexDirection="row">
                    <Typography className={classes.label}>Período: </Typography>
                    <Typography style={{ fontSize: 12 }}>
                      {getFormattedDate(raffle?.periodo?.data_inicio)} -
                      {getFormattedDate(raffle?.periodo?.data_fim)}
                    </Typography>
                  </Box>
                )}
                {raffle?.sorteio?.status_sorteio !== 3 && (
                  <Box display="flex" flexDirection="row" marginTop={1}>
                    <Typography className={classes.label}>
                      Intervalo de inscrição:{' '}
                    </Typography>
                    <Typography style={{ fontSize: 12 }}>
                      {getFormattedDate(raffle?.sorteio?.data_inscricao_inicio)}{' '}
                      -{getFormattedDate(raffle?.sorteio?.data_inscricao_fim)}
                    </Typography>
                  </Box>
                )}
                {(raffle?.sorteio?.status_sorteio !== 3 ||
                  raffle?.resultado?.motivo_sorteio.ordemSuplente) && (
                  <Box display="flex" flexDirection="row" marginTop={1}>
                    <Typography className={classes.label}>
                      Data suplentes:{' '}
                    </Typography>
                    <Typography style={{ fontSize: 12 }}>
                      {getFormattedDate(
                        raffle?.sorteio?.data_suplementes_inicio,
                      )}{' '}
                      -{getFormattedDate(raffle?.sorteio?.data_suplementes_fim)}
                    </Typography>
                  </Box>
                )}

                {(!raffle?.resultado ||
                  (raffle?.resultado &&
                    raffle.status_sorteio === STATUS_RESULTADO_GANHOU)) && (
                  <Box display="flex" flexDirection="row" marginTop={1}>
                    <Typography className={classes.label}>
                      Data de pagamento:{' '}
                    </Typography>
                    <Typography style={{ fontSize: 12 }}>
                      {getFormattedDate(raffle?.sorteio?.data_pagamento_inicio)}{' '}
                      -{getFormattedDate(raffle?.sorteio?.data_pagamento_fim)}
                    </Typography>
                  </Box>
                )}
                {raffle?.resultado?.status_sorteio ===
                  STATUS_RESULTADO_GANHOU && (
                  <Box display="flex" flexDirection="row" marginTop={1}>
                    <Typography
                      className={classes.label}
                      style={{ color: '#000', fontWeight: 600 }}
                    >
                      Data da ocupação:{' '}
                    </Typography>
                    <Typography
                      style={{ fontSize: 12, color: '#000', fontWeight: 700 }}
                    >
                      {getFormattedDate(
                        raffle?.resultado?.subPeriodos?.data_inicio,
                      )}{' '}
                      -
                      {getFormattedDate(
                        raffle?.resultado?.subPeriodos?.data_fim,
                      )}
                    </Typography>
                  </Box>
                )}

                {(raffle.status_sorteio === STATUS_RESULTADO_NAO_CONTEMPLADO ||
                  raffle?.sorteio?.status_sorteio !== 3) && (
                  <Box display="flex" flexDirection="row" marginTop={1}>
                    <Typography
                      className={classes.label}
                      style={{ color: '#000', fontWeight: 600 }}
                    >
                      Dia do sorteio:{' '}
                    </Typography>
                    <Typography
                      style={{ fontSize: 12, color: '#000', fontWeight: 700 }}
                    >
                      {getFormattedDate(raffle?.sorteio?.data_sorteio)}
                    </Typography>
                  </Box>
                )}
              </Paper>
              {raffle.status_sorteio === 0 && (
                <>
                  <Box display="flex" flexDirection="row" marginTop={1}>
                    <Typography className={classes.label}>
                      Estadias disponíveis:
                    </Typography>
                    <Typography style={{ fontSize: 12, color: '#05A002' }}>
                      {raffle?.quantidade_unidades}
                    </Typography>
                    <Typography
                      className={classes.label}
                      style={{ marginLeft: 'auto' }}
                    >
                      Inscrição realizada em:
                    </Typography>
                    <Typography style={{ fontSize: 12 }}>
                      {getFormattedDate(raffle?.created_at)}
                    </Typography>
                  </Box>
                  <Box display="flex" flexDirection="row" marginBottom={3}>
                    <Typography className={classes.label}>
                      Inscritos:
                    </Typography>
                    <Typography style={{ fontSize: 12, color: '#05A002' }}>
                      {raffle?.quantidade_inscritos}
                    </Typography>
                  </Box>
                </>
              )}
              {raffle?.resultado?.status_sorteio ===
                STATUS_RESULTADO_SUPLENTE && (
                <Typography
                  style={{ marginLeft: 0, fontSize: 12, marginBottom: 40 }}
                  className={classes.inscriptionNumberBolder}
                >
                  Fique atento aos anúncios da AVM, você ainda poderá ser
                  chamado caso alguma vaga fique disponível por desistência de
                  um sorteado
                </Typography>
              )}
              {raffle?.resultado?.status_sorteio ===
                STATUS_RESULTADO_GANHOU && (
                <Typography
                  style={{ marginLeft: 0, fontSize: 12, marginBottom: 40 }}
                  className={classes.inscriptionNumberBolder}
                >
                  Fique atento à data de pagamento da taxa da reserva e a data
                  de ocupação
                </Typography>
              )}

              <Box
                flexDirection="row"
                display="flex"
                alignContent="center"
                justifyContent="space-between"
              >
                <Button
                  variant="contained"
                  color="default"
                  style={{
                    minWidth: 150,
                    height: 40,
                    padding: '0px 10px',
                  }}
                  onClick={historyPush}
                >
                  Sair
                </Button>
                <Button
                  variant="contained"
                  className={classes.actionButton}
                  style={{
                    minWidth: 150,
                    height: 40,
                    padding: '0px 10px',
                  }}
                  onClick={reset}
                >
                  fazer outra consulta
                </Button>
              </Box>
            </Box>
          </Paper>
        </>
      ) : (
        <Paper
          style={{
            maxWidth: 440,
            width: '100%',
            margin: 'auto',
          }}
        >
          <Centered column>
            <img
              draggable={false}
              style={{ width: 200, marginBottom: 20 }}
              src={AVMLogo}
              alt="AVM Logo"
            />
            <FormContainer>
              <Typography color="primary" className={classes.subtitle}>
                Preencha seu código e confira o status dos sorteios
              </Typography>

              <TextField
                helperText={error && 'Código inválido'}
                label="Digite o código"
                className={classes.input}
                name="code"
                margin="normal"
                onChange={e => {
                  setError(false);
                  setCode(e.target.value);
                }}
                fullWidth
                error={error}
                variant="filled"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">{codeIcon}</InputAdornment>
                  ),
                }}
              />

              {error && (
                <Typography color="primary" className={classes.errorMessage}>
                  Caso não consiga utilizar o código entre em contato pelo
                  telefone {numberToCall}
                </Typography>
              )}
              <Box mt={3}>
                <Button
                  disabled={loading || lastCode === code}
                  fullWidth
                  variant="contained"
                  type="submit"
                  data-cy="avançar"
                  onClick={onSubmit}
                  className={classes.actionButton}
                >
                  Avançar
                </Button>
              </Box>
            </FormContainer>
          </Centered>
        </Paper>
      )}
    </>
  );
}

export default ConsultarCodigo;
