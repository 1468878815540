import React, { useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';

import { useParams, useHistory } from 'react-router-dom';
import {
  Container,
  Grid,
  Paper,
  Typography,
  Box,
  Button,
  Avatar,
  CircularProgress,
  ButtonGroup,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/DeleteOutline';

import BreadcrumbsAVM from 'components/BreadcrumbsAVM';

import api from 'services/api';

import { useStyles } from './styles';
import ImagemAssociado from 'components/Associado/ImagemAssociado';
import AssociadoCadastradoTabs from 'components/AssociadoCadastro/AssociadoCadastradoTabs/AssociadoCadastradoTabs';
import AssociadoCadastradoInfo from 'components/AssociadoCadastro/AssociadoCadastroInfo';
import { WhatsApp } from '@material-ui/icons';
import PermissionedComponent from 'components/PermissionedComponent';
import {
  PERMISSION_CONVERSAR_CADASTRO,
  PERMISSION_EDITAR_FOTO_ASSOCIADO,
  PERMISSION_FORCAR_ATUALIZACAO_CADASTRO,
  PERMISSION_FORCAR_RESET_PASSWORD_CADASTRO,
  PERMISSION_REMOVER_FOTO_ASSOCIADO,
} from 'utils/constants';

function AssociadoCadastrado() {
  const classes = useStyles();
  const { id } = useParams();
  const history = useHistory();
  const [associado, setAssociado] = useState({});
  const [selectedImage, setSelectedImage] = useState('');
  const [caminho, setCaminho] = useState([
    {
      nome: 'Gerenciar Cadastro',
      path: '/manage-subscription',
    },
  ]);
  const [selectedDependente, setSelectedDependente] = useState(false);
  const [updateData, setUpdateData] = useState(0);
  const [forcingUpdate, setForcingUpdate] = useState(false);
  const [forcingReset, setForcingReset] = useState(false);

  const fileInputRef = useRef();
  const statusLabel = () => {
    if (associado.customer_status !== '0')
      return (
        <Typography variant="p" className={classes.inativo}>
          IRREGULAR
        </Typography>
      );
    if (associado.customer_status === '0')
      return (
        <Typography variant="p" className={classes.ativo}>
          REGULAR
        </Typography>
      );
  };

  async function getAssociado() {
    try {
      const { data } = await api.get(`associados/${id}`, {
        params: {
          include: 'dependentes,telefones,enderecos,postoGraduacao',
        },
      });

      data.dependentes = data.dependentes
        .filter(item => item.status)
        .sort((a, b) => {
          const nomeA = a.nome?.toLowerCase();
          const nomeB = b.nome?.toLowerCase();
          if (nomeA < nomeB) return -1;
          if (nomeA > nomeB) return 1;
          return 0;
        });

      setAssociado(data);
    } catch (err) {
      history.push('/associados');
    }
  }

  function onClickVoltar() {
    history.push('/manage-subscription');
  }

  const handleSubmitPhoto = async photo => {
    try {
      const allowedTypes = [
        'image/jpeg',
        'image/jpg',
        'image/png',
        'image/gif',
      ];

      if (photo) {
        if (!allowedTypes.includes(photo.type)) {
          toast.error(
            'Tipo de arquivo inválido. Por favor, selecione uma imagem.',
          );
          return;
        }
        const formData = new FormData();
        formData.append('file', photo);

        const { data: response } = await api.post(`/files_s3`, formData);
        await api.put(`associados/atualizar-foto/${associado.id}`, {
          file_id: response.data.id,
        });
      } else {
        await api.put(`associados/atualizar-foto/${associado.id}`, {
          file_id: null,
        });
      }

      setSelectedImage(photo);
      setAssociado({ ...associado, avatar_id: null });

      toast.success('Foto atualizada com sucesso !');
    } catch (error) {
      toast.error('Ocorreu um erro ao atualizar a foto deste associado.');
    }
  };

  const onForceUpdate = async () => {
    try {
      setForcingUpdate(true);
      await api.post(`associado/${associado?.id}/force-update`);

      await getAssociado();

      toast.success('Atualização cadastral requisitada do associado!');
    } catch (err) {
      toast.error(
        err.response?.data?.message ||
          'Houve um erro ao forçar a atualização do associado, tente novamente.',
      );
    } finally {
      setForcingUpdate(false);
    }
  };

  const onForcePasswordReset = async () => {
    try {
      setForcingReset(true);
      await api.post('auth/reset-password', { email: associado?.email });

      toast.success('E-mail para reset de senha enviado para o associado!');
    } catch (err) {
      toast.error(
        err.response?.data?.message ||
          'Houve um erro ao forçar o reset de senha do associado, tente novamente.',
      );
    } finally {
      setForcingReset(false);
    }
  };

  const onOpenChat = () => {
    window.open(`https://wa.me/55${associado.celular}`, '_blank');
  };

  useEffect(() => {
    if (associado.id) {
      const index = caminho.findIndex(item => item.id === associado?.id);
      if (index < 0) {
        setCaminho([
          ...caminho,
          {
            id: associado.id,
            nome: associado?.nome,
            path: `/associado-cadastrado/${associado?.id}`,
            active: true,
            handleCustomClick: () => setSelectedDependente(false),
          },
        ]);
      } else {
        setCaminho(prevCaminho => {
          const novoCaminho = [...prevCaminho];
          novoCaminho[index] = { ...prevCaminho[index], nome: associado.nome };
          return novoCaminho;
        });
      }
    }
    const index = caminho.findIndex(item => item.nome === 'Dependente');

    if (selectedDependente && index < 0) {
      setCaminho([
        ...caminho,
        {
          nome: 'Dependente',
          path: `/associado-cadastrado/${associado?.id}`,
          active: true,
        },
      ]);
    } else {
      if (index >= 0) {
        caminho.splice(index, 1);
        setCaminho([...caminho]);
      }
    }
  }, [associado, selectedDependente]);

  useEffect(() => {
    getAssociado();
  }, [id, updateData]);

  return (
    <Container>
      <BreadcrumbsAVM
        caminho={caminho}
        exibeBtnVoltar
        onClickVoltar={onClickVoltar}
      />
      {!selectedDependente && (
        <Paper
          elevation={0}
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            padding: '24px',
            marginBottom: '20px',
            gap: '24px',
          }}
        >
          <Grid container spacing={2} alignItems="center">
            <Grid item>
              <ButtonGroup>
                <PermissionedComponent
                  permission={PERMISSION_FORCAR_ATUALIZACAO_CADASTRO}
                >
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={onForceUpdate}
                    disabled={forcingUpdate}
                    endIcon={
                      forcingUpdate ? (
                        <CircularProgress size={20} color="primary" />
                      ) : null
                    }
                  >
                    Forçar atualização
                  </Button>
                </PermissionedComponent>
                <PermissionedComponent
                  permission={PERMISSION_FORCAR_RESET_PASSWORD_CADASTRO}
                >
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={onForcePasswordReset}
                    disabled={forcingReset}
                    endIcon={
                      forcingReset ? (
                        <CircularProgress size={20} color="primary" />
                      ) : null
                    }
                  >
                    Forçar reset de senha
                  </Button>
                </PermissionedComponent>
                <PermissionedComponent
                  permission={PERMISSION_CONVERSAR_CADASTRO}
                >
                  <Button
                    variant="outlined"
                    color="primary"
                    disabled={forcingReset}
                    onClick={onOpenChat}
                    startIcon={<WhatsApp size={20} color="primary" />}
                    endIcon={
                      forcingReset ? (
                        <CircularProgress size={20} color="primary" />
                      ) : null
                    }
                  >
                    Conversar
                  </Button>
                </PermissionedComponent>
              </ButtonGroup>
            </Grid>
          </Grid>
        </Paper>
      )}

      <Box>
        <Paper>
          <Box p={2}>
            <Grid container spacing={4}>
              <Grid item>
                <Typography variant="h5">
                  <strong>{associado.nome}</strong>
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <Box
                  sx={{
                    maxWidth: '140px',
                    borderRadius: '8px',
                    backgroundColor: '#EDEDED',
                    padding: '8px',
                    textAlign: 'center',
                  }}
                >
                  <Typography variant="p">
                    Status: {!associado.status ? 'Carregando' : statusLabel()}
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Box>
          {!selectedDependente && (
            <>
              <Box p={2}>
                <Typography className={classes.foto}>Foto</Typography>
              </Box>
              <Grid container>
                <Grid item sm={2} md={2}>
                  <Box className={classes.boxThumb}>
                    {(!associado?.avatar_url && !selectedImage) ||
                    typeof selectedImage === 'undefined' ? (
                      <ImagemAssociado />
                    ) : (
                      <Avatar
                        style={{
                          width: 160,
                          height: 160,
                          objectFit: 'cover',
                          marginBottom: '2em',
                          borderRadius: '8px',
                        }}
                        src={
                          selectedImage
                            ? URL.createObjectURL(selectedImage)
                            : associado?.avatar_url
                        }
                        alt="User Photo"
                      />
                    )}
                  </Box>
                </Grid>
                <Grid item sm={10} md={10}>
                  <AssociadoCadastradoInfo associado={associado} />
                  <Box display="flex" justifyContent="flex-start">
                    <PermissionedComponent
                      permission={PERMISSION_REMOVER_FOTO_ASSOCIADO}
                    >
                      <Button
                        color="primary"
                        startIcon={<DeleteIcon />}
                        onClick={() => handleSubmitPhoto(null)}
                      >
                        Excluir
                      </Button>
                    </PermissionedComponent>
                    <Box ml={1}>
                      <PermissionedComponent
                        permission={PERMISSION_EDITAR_FOTO_ASSOCIADO}
                      >
                        <Button
                          variant="contained"
                          color="primary"
                          type="button"
                          onClick={() => fileInputRef.current.click()}
                        >
                          <input
                            type="file"
                            ref={fileInputRef}
                            style={{ display: 'none' }}
                            onChange={event =>
                              handleSubmitPhoto(event.target.files[0])
                            }
                          />
                          + Adicionar Nova
                        </Button>
                      </PermissionedComponent>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </>
          )}
          <Grid item xs={12} sm={12} md={12}>
            <AssociadoCadastradoTabs
              handleSelectDependente={() =>
                setSelectedDependente(!selectedDependente)
              }
              handleUpdateData={() => setUpdateData(updateData + 1)}
              selectedDependente={selectedDependente}
              associado={associado}
            />
          </Grid>
        </Paper>
      </Box>
    </Container>
  );
}

export default AssociadoCadastrado;
