import React, { useState } from 'react';
import { Form, Field } from 'react-final-form';
import createDecorator from 'final-form-focus';
import { TextField } from 'final-form-material-ui';
import { Link as RouterLink } from 'react-router-dom';
import Button from '@material-ui/core/Button';

import { validate } from 'utils/validate';
import { schema } from './schema';
import {
  Box,
  Grid,
  IconButton,
  InputAdornment,
  useMediaQuery,
} from '@material-ui/core';
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai';

const focusOnErrors = createDecorator();

function ForgotPasswordTokenForm({ initialValues = {}, onSubmit }) {
  const isDesktop = useMediaQuery(theme => theme.breakpoints.up('sm'));
  const [show, setShow] = useState({
    password: false,
    newPassword: false,
  });

  const handleClickShowPassword = () =>
    setShow(prevState => ({ ...prevState, password: !prevState.password }));

  const handleClickShowNewPassword = () =>
    setShow(prevState => ({
      ...prevState,
      newPassword: !prevState.newPassword,
    }));

  return (
    <Form
      onSubmit={onSubmit}
      decorators={[focusOnErrors]}
      validate={validate(schema)}
      initialValues={initialValues}
      render={({ handleSubmit, submitting }) => (
        <form onSubmit={handleSubmit} noValidate>
          <Field
            name="password"
            type={show.password ? 'text' : 'password'}
            component={TextField}
            label="Nova senha"
            margin="normal"
            fullWidth
            variant="outlined"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                  >
                    {show.password ? (
                      <AiOutlineEye />
                    ) : (
                      <AiOutlineEyeInvisible />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <Field
            name="newPassword"
            type={show.newPassword ? 'text' : 'password'}
            component={TextField}
            label="Confirmar nova senha"
            margin="normal"
            fullWidth
            variant="outlined"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowNewPassword}
                  >
                    {show.newPassword ? (
                      <AiOutlineEye />
                    ) : (
                      <AiOutlineEyeInvisible />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <Box mt={2}>
            <Grid
              container
              direction={isDesktop ? 'row' : 'column'}
              spacing={isDesktop ? 3 : 1}
            >
              <Grid item xs={12} sm={6}>
                <Button
                  disabled={submitting}
                  fullWidth
                  component={RouterLink}
                  to="/login"
                  variant="outlined"
                  color="primary"
                >
                  Voltar para login
                </Button>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Button
                  disabled={submitting}
                  fullWidth
                  variant="contained"
                  color="primary"
                  type="submit"
                >
                  Enviar
                </Button>
              </Grid>
            </Grid>
          </Box>
        </form>
      )}
    />
  );
}

export default ForgotPasswordTokenForm;
