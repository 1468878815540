import React, { useEffect, useState } from 'react';
import { Grid, Box } from '@material-ui/core';
import Button from '@material-ui/core/Button';

import { validate } from 'utils/validate';
import { schema } from './schema';
import { AutocompleteField } from '@inovando/react-components';
import { Form, Field } from 'react-final-form';
import createDecorator from 'final-form-focus';
import {
  AREA_INSCRICAO,
  DEFAULT_PROFILE,
  STATUS_SORTEIO,
} from 'utils/constants';
import { TextField } from 'final-form-material-ui';
import { format } from 'utils/form';
import api from 'services/api';
import { useAuth } from 'contexts/auth';

const focusOnErrors = createDecorator();

function SearchInscricoes({ onSubmit, initialValues = {} }) {
  const params = { all: true };
  const { profiles } = useAuth();
  const isAssociado = profiles.includes(DEFAULT_PROFILE);

  const [postosGraduacoes, setPostosGraduacoes] = useState([]);
  const [categorias, setCategorias] = useState([]);
  const [sorteios, setSorteios] = useState([]);
  const [loading, setLoading] = useState(true);

  const getPostosGraduacoes = async () => {
    const { data } = await api.get('postos', { params });

    setPostosGraduacoes(data);
  };

  const getCategorias = async () => {
    const { data } = await api.get('categoria/users', { params });

    setCategorias(data);
  };

  const getSorteios = async () => {
    if (isAssociado) {
      const { data } = await api.get('combo-sorteio-associado');

      setSorteios(data.map(item => item.sorteio));
      return;
    }

    const { data } = await api.get('sorteios', { params });

    setSorteios(data);
  };

  useEffect(() => {
    (async () => {
      await getPostosGraduacoes();
      await getCategorias();
      await getSorteios();

      setLoading(false);
    })();
  }, []);

  return (
    <Box m={1}>
      <Form
        onSubmit={onSubmit}
        decorators={[focusOnErrors]}
        subscription={{ submitting: true, dirty: true }}
        validate={validate(schema)}
        initialValues={initialValues}
        render={({ handleSubmit, form, submitting, dirty }) => (
          <form onSubmit={handleSubmit} noValidate>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={3}>
                <Field
                  name="status_sorteio"
                  component={AutocompleteField}
                  options={STATUS_SORTEIO}
                  label="Status sorteio"
                  fullWidth
                  variant="outlined"
                  valueAttr="id"
                  kind="value"
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <Field
                  name="sorteio_id"
                  component={AutocompleteField}
                  options={sorteios}
                  label="Sorteio"
                  fullWidth
                  variant="outlined"
                  labelAttr="nome"
                  valueAttr="id"
                  kind="value"
                />
              </Grid>
              {!isAssociado && (
                <>
                  <Grid item xs={6} sm={3}>
                    <Field
                      name="associado_nome"
                      component={TextField}
                      label="Nome do associado"
                      fullWidth
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={6} sm={3}>
                    <Field
                      name="posto_id"
                      component={AutocompleteField}
                      loading={loading}
                      options={postosGraduacoes}
                      label="Posto/Graduação"
                      fullWidth
                      variant="outlined"
                      labelAttr="nome"
                      valueAttr="id"
                      kind="value"
                    />
                  </Grid>
                  <Grid item xs={6} sm={3}>
                    <Field
                      name="categoria_id"
                      component={AutocompleteField}
                      loading={loading}
                      options={categorias}
                      label="Categoria"
                      fullWidth
                      variant="outlined"
                      labelAttr="nome"
                      valueAttr="id"
                      kind="value"
                    />
                  </Grid>
                  <Grid item xs={6} sm={3}>
                    <Field
                      name="associado_cpf"
                      component={TextField}
                      label="CPF"
                      fullWidth
                      variant="outlined"
                      {...format('XXX.XXX.XXX-XX')}
                    />
                  </Grid>
                  <Grid item xs={6} sm={3}>
                    <Field
                      name="associado_rg"
                      component={TextField}
                      label="RG"
                      fullWidth
                      variant="outlined"
                    />
                  </Grid>
                </>
              )}
              <Grid item xs={6} sm={3}>
                <Field
                  name="origem_inscricao"
                  component={AutocompleteField}
                  label="Área de inscrição"
                  options={AREA_INSCRICAO}
                  kind="value"
                  fullWidth
                  variant="outlined"
                />
              </Grid>
            </Grid>

            <Grid container spacing={2} justify="flex-end">
              <Grid item xs={12} sm={2}>
                <Box mt={2}>
                  <Button
                    disabled={submitting || !dirty}
                    fullWidth
                    variant="outlined"
                    color="secondary"
                    onClick={() => {
                      form.reset();
                      onSubmit({});
                    }}
                  >
                    Limpar
                  </Button>
                </Box>
              </Grid>

              <Grid item xs={12} sm={2}>
                <Box mt={2}>
                  <Button
                    disabled={submitting || !dirty}
                    fullWidth
                    variant="contained"
                    color="primary"
                    type="submit"
                  >
                    Filtrar
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </form>
        )}
      />
    </Box>
  );
}

export default SearchInscricoes;
