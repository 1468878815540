import React from 'react';

import {
  Dialog,
  DialogActions,
  DialogContent,
  Button,
  makeStyles,
  DialogTitle,
  Typography,
  Box,
} from '@material-ui/core';
import { MdOutlineClose } from 'react-icons/md';

export const useStyles = makeStyles(() => ({
  dialogScrollpaper: {
    overflowY: 'hidden',
    padding: '24px',
    maxWidth: 500,

    '& .MuiDialogTitle-root': {
      padding: 0,
    },
  },

  content: {
    padding: '24px 0 40px 0',
  },

  mainText: {
    fontWeight: 600,
    fontSize: '15px',
    lineHeight: '120%',

    '& .emphasis': {
      color: '#E53E3E',
    },

    '& + &': {
      marginTop: 7,
    },
  },

  subText: {
    fontWeight: 600,
    fontSize: '15px',
    lineHeight: '120%',
    color: '#6F6F6F',

    '& + &': {
      marginTop: '10px',
    },
  },

  actions: {
    padding: 0,
  },

  primaryButton: {
    background: '#E53E3E',
    borderRadius: '6px',
    color: 'white',
    fontSize: '16px',
    lineHeight: '120%',
    padding: '8px 16px',
    textTransform: 'lowercase',

    '&:hover': {
      background: '#E53E3E',
    },
  },

  cancelButton: {
    background: 'transparent',
    borderRadius: '6px',
    color: 'black',
    fontSize: '16px',
    lineHeight: '120%',
    padding: '8px 16px',
    border: '1px solid #C2C2C2',
    textTransform: 'lowercase',
    marginLeft: '24px',
  },
}));

function RemoverInscricaoConfirmarDialog({
  onClose = () => {},
  open = false,
  inscricao = {},
  onSubmit,
}) {
  const classes = useStyles();

  return (
    <Dialog
      fullWidth
      onClose={onClose}
      open={open}
      classes={{
        paper: classes.dialogScrollpaper,
      }}
    >
      <Box display="flex" justifyContent="space-between">
        <DialogTitle>
          <Typography className={classes.mainText}>Desinscrever</Typography>
        </DialogTitle>
        <Button
          onClick={onClose}
          color="##000000"
          endIcon={<MdOutlineClose />}
        ></Button>
      </Box>

      <DialogContent className={classes.content}>
        <Typography className={classes.subText}>
          Você tem certeza que deseja anular sua inscrição no sorteio:{' '}
          {inscricao?.sorteio?.nome} ?
        </Typography>
      </DialogContent>

      <DialogActions className={classes.actions}>
        <Box width="100%" display="flex">
          <Button className={classes.primaryButton} onClick={onSubmit}>
            desinscrever
          </Button>
          <Button className={classes.cancelButton} onClick={onClose}>
            cancelar
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
}

export default RemoverInscricaoConfirmarDialog;
