import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  CircularProgress,
} from '@material-ui/core';

function ConfirmModal({
  open = false,
  loading = false,
  onAccept = () => {},
  onClose = () => {},
  title = 'Deseja confirmar essa ação?',
  description = 'Essa operação não poderá ser desfeita.',
  closeLabel = 'Não',
  acceptLabel = 'Sim',
  hideAcceptLabel = false,
  style = {},
}) {
  return (
    <Dialog
      open={open}
      onClick={event => {
        event.stopPropagation();
      }}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      PaperProps={{ style }}
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{description}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>{closeLabel}</Button>
        {!hideAcceptLabel && (
          <Button
            onClick={onAccept}
            variant="contained"
            color="secondary"
            data-cy="modal-button-accept"
            disabled={loading}
            autoFocus
          >
            {loading ? (
              <CircularProgress color="inherit" size={20} />
            ) : (
              acceptLabel
            )}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}

export default ConfirmModal;
