import React, { useEffect, useState } from 'react';

import DialogForm from 'components/DialogForm';
import ParticiparForm from 'forms/ParticiparForm';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';

import { makeStyles } from '@material-ui/core/styles';
import ConfirmParticipacaoForm from 'forms/ConfirmParticipacaoForm';
import {
  DEFAULT_PROFILE,
  ORIGEM_INSCRICAO_ADMINISTRATIVO,
  ORIGEM_INSCRICAO_ASSOCIADO,
} from 'utils/constants';
import api from 'services/api';
import { useAuth } from 'contexts/auth';
import { toast } from 'react-toastify';
import { CircularProgress } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  dialogActions: {
    paddingLeft: 0,
    paddingRight: 0,
  },
}));

function ParticiparDialog({
  initialValues = {},
  onClose = () => {},
  open = false,
  children,
  title = '',
  sorteio: sorteioGiven,
  associado = {},
  onInscreverAssociado = () => {},
  periodosInscritos = [],
}) {
  const { profiles } = useAuth();
  const isAssociado = profiles.includes(DEFAULT_PROFILE);

  const periodosSorteioInscritos = periodosInscritos
    .filter(periodo => periodo.periodo.sorteio_id === sorteioGiven.id)
    .map(periodo => periodo.periodo);

  const classes = useStyles();
  const [showConfirmParticipacao, setShowConfirmParticipacao] = useState(false);
  const [inscricao, setInscricao] = useState({});
  const [telefones, setTelefones] = useState([]);
  const [sorteio, setSorteio] = useState([]);
  const [periodos, setPeriodos] = useState([]);
  const [loading, setLoading] = useState(false);

  const [titleSetted, setTitleSetted] = useState('');

  const onDownloadRecibo = async () => {
    const { data: reciboData } = await api.get(`recibo/${associado.id}`, {
      responseType: 'blob',
      headers: {
        'Content-Type': 'application/pdf',
      },
    });

    const file = new Blob([reciboData], { type: 'application/pdf' });
    const fileURL = URL.createObjectURL(file);
    const link = document.createElement('a');
    link.href = fileURL;
    link.download = `Recibo ${associado.nome} - ${sorteio.nome}.pdf`;
    link.click();
  };

  async function getAssociado() {
    if (!associado.id) return;

    const params = { include: 'telefones' };
    const { data } = await api.get(`associados/${associado.id}`, { params });

    setTelefones(data.telefones);
  }

  async function getSorteio() {
    if (!sorteioGiven.id) return;

    const { data } = await api.get(`sorteios/${sorteioGiven.id}`);
    setSorteio(data);

    setPeriodos(
      data.periodos.map(periodo => ({
        ...periodo,
        disabled: periodosSorteioInscritos.some(item => item.id === periodo.id),
      })),
    );
  }

  const onSubmitParticipacao = async values => {
    const url = `sorteios/${sorteioGiven.id}/associados`;

    try {
      setLoading(true);

      const data = {
        associado_id: associado.id,
        periodo_sorteio_id: values.periodo_1,
        cadeirante: !!values.cadeirante_1,
        baixar_recibo: values.recibo_impressao,
        origem_inscricao: isAssociado
          ? ORIGEM_INSCRICAO_ASSOCIADO
          : ORIGEM_INSCRICAO_ADMINISTRATIVO,
      };

      const { data: periodo1Response } = await api.post(url, data);
      const params = {
        associado_sorteio_id: periodo1Response.periodosIncritos.id,
      };

      await api.get(`/test-email-ticket/`, { params });

      if (values.periodo_2) {
        const { data: periodo2Response } = await api.post(url, {
          ...data,
          periodo_sorteio_id: values.periodo_2,
          cadeirante: !!values.cadeirante_2,
        });
        const params = {
          associado_sorteio_id: periodo2Response.periodosIncritos.id,
        };

        await api.get(`/test-email-ticket/`, { params });
      }

      const associadoData = {
        email: values.associado_email,
      };

      await api.put(`associados/${associado.id}`, associadoData);

      for (const tel of values.associado_telefones) {
        const telIndex = telefones.indexOf(tel);

        if (telIndex !== -1) continue;

        await api.put(`associados/${associado.id}/telefones/${tel.id}`, {
          telefone: tel.telefone,
        });
      }

      toast.success('Dados do associado atualizados com sucesso!');

      if (values.recibo_impressao) {
        onDownloadRecibo();
      }

      toast.success('O comprovante da inscrição foi enviado por email!');
      setShowConfirmParticipacao(false);
      getAssociado();
      onInscreverAssociado({});
      onClose();
    } catch (err) {
      toast.warn(err.response?.data?.msg);
      toast.warn(err.response?.data?.error?.message);
      toast.warn(err.response?.data?.message);
      toast.warn(err.response?.data?.error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getSorteio();
  }, [sorteioGiven]);

  useEffect(() => {
    getAssociado();
  }, [associado]);

  return (
    <>
      <DialogForm
        title={
          !showConfirmParticipacao
            ? title
            : titleSetted || 'Data(s) de Ocupação'
        }
        open={open}
        onClose={() => {
          setInscricao({});
          setShowConfirmParticipacao(false);
          onClose();
        }}
      >
        {({ onClose }) => (
          <>
            {!showConfirmParticipacao ? (
              <ParticiparForm
                onSubmit={values => {
                  setInscricao(values);
                  setShowConfirmParticipacao(true);
                }}
                periodos={periodos}
                initialValues={{ ...initialValues, ...inscricao }}
                sorteio={sorteioGiven}
                footer={() => (
                  <DialogActions className={classes.dialogActions}>
                    <Button onClick={onClose} color="primary">
                      Cancelar
                    </Button>

                    <Button
                      data-cy="continuar"
                      variant="contained"
                      type="submit"
                      color="primary"
                    >
                      Continuar
                    </Button>
                  </DialogActions>
                )}
              >
                {children}
              </ParticiparForm>
            ) : (
              sorteioGiven && (
                <ConfirmParticipacaoForm
                  onSubmit={values => {
                    onSubmitParticipacao({ ...values, ...inscricao });
                    setInscricao({});
                  }}
                  initialValues={{ recibo_impressao: false }}
                  telefones={telefones}
                  onChangeDialogTitle={() => setTitleSetted('Participar')}
                  periodos={periodos}
                  associado={associado}
                  footer={() => (
                    <DialogActions className={classes.dialogActions}>
                      <Button
                        onClick={() => {
                          setShowConfirmParticipacao(false);
                          setTitleSetted('');
                        }}
                        color="primary"
                        disabled={loading}
                      >
                        Voltar
                      </Button>

                      <Button
                        data-cy="participar"
                        variant="contained"
                        type="submit"
                        color="primary"
                        onClick={() => setTitleSetted('Participar')}
                        endIcon={
                          loading ? (
                            <CircularProgress size={20} color="primary" />
                          ) : null
                        }
                        disabled={loading}
                      >
                        Participar
                      </Button>
                    </DialogActions>
                  )}
                />
              )
            )}
          </>
        )}
      </DialogForm>
    </>
  );
}

export default ParticiparDialog;
