import { Box, Button, withStyles } from '@material-ui/core';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

const StyledButton = withStyles(() => ({
  root: {
    '&.MuiButtonBase-root': {
      background: '#F0F0F0',
    },
    '& .MuiButton-label': {
      maxWidth: '148px',

      display: 'flex',
      flexDirection: 'column',

      padding: '5px 10px',
      textTransform: 'initial',
      lineHeight: '18px',
      fontSize: '12px',
    },
  },
}))(Button);

export default function LinkButton({
  to,
  label,
  Icon,
  disabled,
  marginRight,
  marginLeft,
  ...rest
}) {
  return (
    <Box marginLeft={marginLeft} marginRight={marginRight}>
      <RouterLink to={to}>
        <StyledButton disabled={disabled} {...rest}>
          <Box
            color={disabled ? 'gray' : 'red'}
            bgcolor="white"
            width="28px"
            height="28px"
            padding="4px"
            display="flex"
            alignItems="center"
            justifyContent="center"
            borderRadius="5px"
            fontSize="20px"
            marginBottom="5px"
          >
            {Icon && <Icon />}
          </Box>
          {label}
        </StyledButton>
      </RouterLink>
    </Box>
  );
}
