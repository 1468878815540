import React from 'react';

import { Box, Typography, Breadcrumbs, Link, Button } from '@material-ui/core';
import { useHistory } from 'react-router';
import { makeStyles } from '@material-ui/core/styles';

import ArrowBackIcon from '@material-ui/icons/ArrowBack';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  etapa: {
    fontSize: theme.spacing(2.4),
  },
  rotaAtual: {
    fontWeight: 500,
    color: theme.palette.primary.main,
  },
  link: {
    display: 'flex',
    alignItems: 'center',
  },
}));

function BreadcrumbsAVM({
  caminho = [],
  exibeBtnVoltar = false,
  onClickVoltar = () => {},
}) {
  const classes = useStyles();
  const history = useHistory();

  function handleClick(etapa) {
    if (etapa.handleCustomClick) {
      etapa.handleCustomClick();
    } else {
      history.push(etapa.path);
    }
  }

  return (
    <Box mb={2} className={classes.root}>
      <Breadcrumbs aria-label="breadcrumb" separator="›">
        {caminho.map(etapa => (
          <Link
            key={etapa.path}
            color="inherit"
            onClick={() => handleClick(etapa)}
            className={classes.link}
          >
            {etapa.icone}
            <Typography className={classes.etapa} color="textPrimary">
              {etapa.nome}
            </Typography>
          </Link>
        ))}
      </Breadcrumbs>

      {exibeBtnVoltar && (
        <Button
          color="primary"
          startIcon={<ArrowBackIcon />}
          onClick={onClickVoltar}
        >
          Voltar
        </Button>
      )}
    </Box>
  );
}

export default BreadcrumbsAVM;
