import React from 'react';
import { Typography, Paper, Button, Box } from '@material-ui/core';
import Centered from 'components/Centered';
import AVMLogo from 'images/avm-logo-200.png';
import { Link as RouterLink } from 'react-router-dom';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { Call } from '@material-ui/icons';

const numeroTelefone = 'telefone: (41) 3075-8200';

const styleDiv = {
  boxSizing: 'border-box',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  gap: '20px',
  padding: '16px 54px',
  height: '56px',
  border: '1px solid #D1D1D1',
  background: '#F0F0F0',
  color: '#7C7C7C',
  borderRadius: '15px',
  order: 3,
  flex: 'none',
  textTransform: 'none',
  flexGrow: 0,
};

const styleButton = {
  height: '17px',
  padding: '25px',
  fontStyle: 'normal',
  fontSize: '13px',
  fontWeight: '500',
  lineHeight: '17px',
  color: '#1A202C',
  textTransform: 'none',
};

const styleText = {
  padding: '20px',
};

const CadastroPorTelefone = () => {
  return (
    <>
      <Paper style={{ maxWidth: 440, width: '100%', margin: 'auto' }}>
        <Button
          variant="text"
          style={styleButton}
          color="primary"
          startIcon={<ArrowBackIcon />}
          component={RouterLink}
          to="/"
        >
          voltar
        </Button>
        <Centered column>
          <img
            draggable={false}
            style={{ width: 100, marginBottom: 20 }}
            src={AVMLogo}
            alt="Inovando's Logo"
          />
          <Typography
            variant="body1"
            color="textSecondary"
            mb={2}
            align="center"
            style={styleText}
          >
            Caso o e-mail da etapa anterior não esteja correto você deve{' '}
            <strong>renovar seu cadastro</strong> através de alguma das opções
            abaixo:
          </Typography>
          <Box padding="20px">
            <Typography style={styleDiv}>
              <Call />
              {numeroTelefone}
            </Typography>
          </Box>
          <Typography color="textSecondary">Tecle a opção 5</Typography>
        </Centered>
      </Paper>
    </>
  );
};

export default CadastroPorTelefone;
