import { useCallback, useState, useEffect } from 'react';
import debounce from 'lodash.debounce';
import api from 'services/api';

function useTable(route, options, requestOnInit = true) {
  const [data, setData] = useState([]);
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(1);
  const [endpoint, setEndpoint] = useState(route);
  const [rowsPerPage, setRowsPerPage] = useState(options?.rowsPerPage || 10);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState('');
  const [sort, setSort] = useState('');
  const [order, setOrder] = useState('');
  const [externalParams, setExternalParams] = useState(options?.params);
  const [allowRequest, setAllowRequest] = useState(requestOnInit);
  const [customData, setCustomData] = useState({});

  const loadData = useCallback(async () => {
    if (!endpoint) {
      setData([]);
      setCount(0);
      setLoading(false);

      return;
    }

    const showLoading = setTimeout(() => {
      setData([]);
      setLoading(true);
    }, 1000);

    const common = { perPage: rowsPerPage, page: page, q: search };
    const params = sort ? { ...common, sort: sort, order: order } : common;

    try {
      const response = await api.get(endpoint, {
        params: { ...params, ...externalParams },
      });
      setData(response?.data?.data);
      setCustomData(response?.data);
      setCount(response.data.pagination.total);
    } finally {
      clearTimeout(showLoading);
      setLoading(false);
    }
  }, [page, rowsPerPage, endpoint, sort, order, search, externalParams]);

  useEffect(() => {
    if (allowRequest) {
      loadData();
    }
    setAllowRequest(true);
  }, [loadData]);

  function onChangePage(pageIndex) {
    const nextPage = pageIndex + 1;
    setPage(nextPage);
  }

  function onChangeRowsPerPage(rows) {
    setRowsPerPage(rows);
    setPage(1);
  }

  function onColumnSortChange(changedColumn, direction) {
    setSort(changedColumn);
    setOrder(direction);
  }

  function onRefresh(extraParams, refreshParams, newEndpoint) {
    if (newEndpoint) {
      setEndpoint(newEndpoint);
    }

    if (refreshParams) {
      setExternalParams(extraParams);
      return;
    }

    setExternalParams(prevState =>
      extraParams ? { ...prevState, ...extraParams } : {},
    );
  }

  function onRefreshEndpoint(newEndpoint = route) {
    loadData(newEndpoint);
  }

  const onSearchDebounced = debounce(setSearch, 250);

  return {
    options: {
      count,
      page: page - 1,
      rowsPerPage,
      onChangePage,
      onChangeRowsPerPage,
      onColumnSortChange,
      onSearchChange: onSearchDebounced,
      ...options,
    },
    data,
    externalParams,
    loading,
    onRefresh,
    onRefreshEndpoint,
    customData,
    setLoading,
  };
}

export default useTable;
