import { useAuth } from 'contexts/auth';
import AssociadoCadastroForm from 'forms/AssociadoCadastroForm/AssociadoCadastroForm';
import React from 'react';
import { PERMISSION_EDITAR_CADASTRO } from 'utils/constants';
import { isUserAllowed } from 'utils/permissions';

function DadosBasicos({ data, handleUpdateData }) {
  const { user } = useAuth();

  return (
    <AssociadoCadastroForm
      initialValues={data}
      handleUpdateData={handleUpdateData}
      isDisabled={!isUserAllowed(user, PERMISSION_EDITAR_CADASTRO)}
    />
  );
}

export default DadosBasicos;
